<template>
  <section class="ads-total-card">
    <div class="chart">
      <canvas id="chart"></canvas>
    </div>

    <!-- 合計 -->
    <div class="total-table">
      <table>
        <tr>
          <td colspan="2" class="has-background-link-light">
            <b-tooltip label="ページビュー数の略で、ページが閲覧された数" class="tooltip is-inline-block" type="is-dark" position="is-bottom" multilined animated>
              閲覧数<small>（PV数）</small>
            </b-tooltip>
          </td>
          <td colspan="2" class="has-background-link-light">
            <b-tooltip label="コンバージョン数の略で、電話のボタンクリック、メールの送信、LINEのボタンクリック、外部のサイトに誘導しているリンクURLがクリックされた数" class="tooltip is-inline-block" type="is-dark" position="is-bottom" multilined animated>
              反応数<small>（CV数）</small>
            </b-tooltip>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="title is-4">{{ reportData['totalData']['sessions'] }}</td>
          <td colspan="2" class="title is-4">{{ reportData['totalData']['goalCompletionsAll'] }}</td>
        </tr>
        <tr>
          <td>
            <b-tooltip label="閲覧数（PV数）のうち、ネット広告を経由した数" class="tooltip" type="is-dark" position="is-bottom" multilined animated>
              ネット広告
            </b-tooltip>
          </td>
          <td>{{ reportData['totalData']['advertising'] }}</td>
          <td>
            <b-tooltip label="反応数（CV数）のうち、メールが送信された数" class="tooltip" type="is-dark" position="is-top" multilined animated>
              WEB CV
            </b-tooltip>
          </td>
          <td>{{ reportData['totalData']['goal1Completions'] }}</td>
        </tr>
        <tr>
          <td>
            <b-tooltip label="閲覧数（PV数）のうち、Google、Yahoo!などの検索を経由した数（ネット広告を除く）" class="tooltip" type="is-dark" position="is-bottom" multilined animated>
              自然検索
            </b-tooltip>
          </td>
          <td>{{ reportData['totalData']['organic_search'] }}</td>
          <td>
            <b-tooltip label="反応数（CV数）のうち、電話のボタンがクリックされた数" class="tooltip" type="is-dark" position="is-top" multilined animated>
              TEL CV
            </b-tooltip>
          </td>
          <td>{{ reportData['totalData']['goal2Completions'] }}</td>
        </tr>
        <tr>
          <td>
            <b-tooltip label="閲覧数（PV数）のうち、URLの直接入力、メールのURL、チラシのQRコードなどを経由した数" class="tooltip" type="is-dark" position="is-bottom" multilined animated>
              メール、チラシ等
            </b-tooltip>
          </td>
          <td>{{ reportData['totalData']['direct_or_other'] }}</td>
          <td>
            <b-tooltip label="反応数（CV数）のうち、LINEのボタンがクリックされた数" class="tooltip" type="is-dark" position="is-top" multilined animated>
              LINE CV
            </b-tooltip>
          </td>
          <td>{{ reportData['totalData']['goal3Completions'] }}</td>
        </tr>
        <tr>
          <td>
            <b-tooltip label="閲覧数（PV数）のうち、Facebook、X、Instagram、ブログなどのリンクを経由した数" class="tooltip" type="is-dark" position="is-bottom" multilined animated>
              ウェブサイト
            </b-tooltip>
          </td>
          <td>{{ reportData['totalData']['from_another_site'] }}</td>
          <td>
            <b-tooltip label="反応数（CV数）のうち、外部のサイトに誘導しているリンクURLがクリックされた数" class="tooltip" type="is-dark" position="is-top" multilined animated>
              LINK CV
            </b-tooltip>
          </td>
          <td>{{ reportData['totalData']['goal4Completions'] }}</td>
        </tr>
      </table>
    </div>

    <div class="total-table">
      <table>
        <tr>
          <td colspan="2" class="has-background-link-light">
            <b-tooltip label="コンバージョン率の略で、閲覧がコンバージョンにつながった割合です。反応数（CV数）÷閲覧数（PV数）で計算できます。" class="tooltip is-inline-block" type="is-dark" position="is-bottom" multilined animated>
              反応率<small>（CV率）</small>
            </b-tooltip>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="title is-4">{{ reportData['totalData']['goalConversionRateAll'] }}</td>
        </tr>
      </table>
    </div>

    <div class="mt-5 has-text-centered">
      <a href="#"
        class="external-link"
        @click="$router.push('/report-previous').catch(() => {})"
      >
        2024年10月以前のレポートを見る
        <span class="icon-external-link"></span>
      </a>
    </div>

    <!-- 広告 -->
    <div v-if="reportDatas['google']['total']['cost'] > 0" class="table-container">
      <table class="table is-bordered is-narrow is-hoverable">
        <thead>
          <tr>
            <th class="no-border"></th>
            <th class="has-text-centered has-background-grey-lighter">Google広告</th>
          </tr>
        </thead>

        <tbody>
          <tr v-if="isThisMonth()">
            <th class="no-border"></th>
            <td class="has-text-centered" colspan="2">
              <div class="has-text-weight-bold" :class="reportDatas['google']['status'] === '運用中' ? 'has-text-info' : 'has-text-danger'">{{ reportDatas['google']['status'] }}</div>
              <div v-if="reportDatas['google']['status_detail']">{{ reportDatas['google']['status_detail'] }}</div>
            </td>
          </tr>

          <tr v-if="isThisMonth()">
            <td>
              <b-tooltip label="現在、契約中の広告予算" class="tooltip" type="is-dark" position="is-right" multilined animated>
                広告予算
              </b-tooltip>
            </td>
            <td class="has-text-right" colspan="2">{{ reportDatas['google']['total']['adBudget'] | yenFormatter }}</td>
          </tr>
          <tr v-if="isThisMonth()">
            <td>
              <b-tooltip label="当月、使用した広告費" class="tooltip" type="is-dark" position="is-right" multilined animated>
                使用済の広告費
              </b-tooltip>
            </td>
            <td class="has-text-right" colspan="2">{{ reportDatas['google']['total']['cost'] | yenFormatter }}</td>
          </tr>
          <tr v-if="isThisMonth()">
            <td>
              <b-tooltip label="まだ使用していない残りの広告費（決済済みの広告費 ー すでに使用した広告費）" class="tooltip" type="is-dark" position="is-right" multilined animated>
                未使用の広告費
              </b-tooltip>
            </td>
            <td class="has-text-right" colspan="2">{{ reportDatas['google']['total']['unusedAdExpenses'] | yenFormatter }}</td>
          </tr>

          <tr v-if="isThisMonth()">
            <td class="no-border" colspan="5"></td>
          </tr>

          <tr>
            <td>
              <b-tooltip label="広告が表示された回数" class="tooltip" type="is-dark" position="is-right" multilined animated>
                表示回数
              </b-tooltip>
            </td>
            <td class="has-text-right" colspan="2">{{ reportDatas['google']['total']['impressions'] | numberFormatter }}</td>
          </tr>
          <tr>
            <td>
              <b-tooltip label="広告がクリックされた数" class="tooltip" type="is-dark" position="is-right" multilined animated>
                クリック数
              </b-tooltip>
            </td>
            <td class="has-text-right" colspan="2">{{ reportDatas['google']['total']['clicks'] | numberFormatter }}</td>
          </tr>
          <tr>
            <td>
              <b-tooltip label="クリック数÷表示回数" class="tooltip" type="is-dark" position="is-right" multilined animated>
                クリック率
              </b-tooltip>
            </td>
            <td class="has-text-right" colspan="2">{{ reportDatas['google']['total']['ctr'] }}</td>
          </tr>
          <tr>
            <td>
              <b-tooltip label="広告が１クリックされるのにかかった平均の広告費" class="tooltip" type="is-dark" position="is-right" multilined animated>
                クリック単価
              </b-tooltip>
            </td>
            <td class="has-text-right" colspan="2">{{ reportDatas['google']['total']['average_cpc'] | yenFormatter }}</td>
          </tr>
          <tr>
            <td>
              <b-tooltip label="コンバージョン数の略で、電話のボタンクリック、メールの送信、LINEのボタンクリック、外部のサイトに誘導しているリンクURLがクリックされた数" class="tooltip" type="is-dark" position="is-right" multilined animated>
                反応数（CV数）
              </b-tooltip>
            </td>
            <td class="has-text-right" colspan="2">{{ reportDatas['google']['total']['conversions'] | numberFormatter }}</td>
          </tr>
          <tr>
            <td>
              <b-tooltip label="CV数 ÷ 広告のクリック数" class="tooltip" type="is-dark" position="is-right" multilined animated>
                反応率（CV率）
              </b-tooltip>
            </td>
            <td class="has-text-right" :class="parseFloat(reportDatas['google']['total']['conversion_rate']) >= 0.5 ? 'has-text-info' : 'has-text-danger'" colspan="2">{{ reportDatas['google']['total']['conversion_rate'] }}</td>
          </tr>

          <tr v-if="isThisMonth()">
            <td class="no-border" colspan="5"></td>
          </tr>

          <tr v-if="isThisMonth()">
            <td>
              <b-tooltip label="次回、決済される広告予算" class="tooltip" type="is-dark" position="is-right" multilined animated>
                次回の広告予算
              </b-tooltip>
            </td>
            <td class="has-text-right" colspan="2">{{ reportDatas['google']['total']['nextAdBudget'] | yenFormatter }}</td>
          </tr>
          <tr v-if="isThisMonth()">
            <td>
              <b-tooltip label="次回、広告予算が決済される日" class="tooltip" type="is-dark" position="is-right" multilined animated>
                次回の決済日
              </b-tooltip>
            </td>
            <td class="has-text-right" colspan="2">{{ reportDatas['google']['total']['payment_date'] | dayFormatter }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 日別 -->
    <div class="table-container js-scrollable">
      <table class="table is-bordered is-narrow is-hoverable is-striped">
        <thead>
          <tr>
            <th class="no-border"></th>
            <th>PV数</th>
            <th>CV数</th>
            <th colspan="2">広告費</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in reportData['dairyDatas']" :key="key">
            <td class="has-text-centered">{{ item['date'] }}</td>
            <td class="has-text-right">{{ item['sessions'] }}</td>
            <td class="has-text-right">{{ item['goalCompletionsAll'] }}</td>
            <td class="has-text-right">{{ item['googleCost'] }}</td>
          </tr>
          <tr>
            <td class="has-text-centered">合計</td>
            <td class="has-text-right">{{ reportData['totalData']['sessions'] }}</td>
            <td class="has-text-right">{{ reportData['totalData']['goalCompletionsAll'] }}</td>
            <td class="has-text-right">{{ reportData['totalData']['googleCost'] }}</td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th class="no-border"></th>
            <th>PV数</th>
            <th>CV数</th>
            <th colspan="2">広告費</th>
          </tr>
        </thead>
      </table>
    </div>

    <!-- コンポーネント Modal -->
    <Modal
      class="change-plan qrcode-download"
      v-if="modal"
      @close="modalClose"
      @checkout="changeModal('checkout')"
      :pagedata="pageData"
      :adsdata="reportDatas['ads_data']"
      :chargesdata="reportDatas['charges_data']"
      :type="type" />
  </section>
</template>

<script>
import Util from '../lib/util'
import Modal from '../../Modal.vue'

export default {
  props: {
    pageData: Object,
    reportDatas: Object
  },
  components: {
    Modal,
  },
  data() {
    return {
      reportData: this.getReportData(),
      modal: false,
    }
  },
  filters: {
    yenFormatter: function (price) {
      return Util.yenFormatter(price)
    },
    numberFormatter: function (price) {
      return Util.numberFormatter(price)
    },
    dayFormatter: function (day) {
      return Util.dayFormatter(day)
    }
  },
  mounted: function() {
    let labels = []
    let datas = { cv: [], pv: [] }
    for (const data of this.reportData.dairyDatas) {
      labels.push(data.date.slice(3, 8).replace(/^0+/, ''))
      datas.cv.push(data.goalCompletionsAll)
      datas.pv.push(data.sessions)
    }
    new window.ScrollHint('.js-scrollable', {
      suggestiveShadow: true,
      i18n: {
        scrollable: '左右にスクロールできます'
      }
    })

    const ctx = document.getElementById('chart')
    new window.Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            type: 'line',
            label: '閲覧数（PV数）',
            data: datas.pv,
            borderWidth: 1,
            borderColor : "rgba(54,164,235,0.5)",
            backgroundColor : "rgba(54,164,235,0.5)",
            yAxisID: "y",
          },
          {
            type: 'bar',
            label: '反応数（CV数）',
            data: datas.cv,
            backgroundColor: ['rgba(254, 97, 132, 0.5)'],
            borderColor: ['rgba(254, 97, 1325, 0.5)'],
            yAxisID: "y1",
          },
        ]
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            position: 'left',
            suggestedMax: 10,
            ticks: {
              callback: function(label) {
                if (Math.floor(label) === label) {
                  return label
                }
              }
            }
          },
          y1: {
            beginAtZero: true,
            position: 'right',
            suggestedMax: 10,
            grid: {
              display: false,
            },
            ticks: {
              callback: function(label) {
                if (Math.floor(label) === label) {
                  return label
                }
              }
            }
          },
        }
      }
    })
  },
  methods: {
    getReportData() {
      let dairyDatas = []
      let totalData = {}

      let dairySessions = 0

      let totalSessions = 0
      let totalAdvertising = 0

      let totalGoogleCost = 0

      let totalGoal1Completions = 0
      let totalGoal2Completions = 0
      let totalGoal3Completions = 0
      let totalGoal4Completions = 0
      let totalGoalCompletionsAll = 0

      // 広告のデータを取得する
      for (let gaDailyData of this.reportDatas['ga']['daily']) {
        let googleClicks = 0
        let googleCost = 0
        let googleWebCv = 0
        let googleTelCv = 0
        let googleLineCv = 0
        let googleLinkCv = 0
        const google = this.reportDatas['google']['daily'].find(data => Util.dateFormatter(new Date(data['date']), 'MM/DD(DAY)') === gaDailyData['date'])
        if (typeof google !== 'undefined') {
          googleClicks = google['clicks']
          googleCost = google['cost']
          googleWebCv = google['webCv']
          googleTelCv = google['telCv']
          googleLineCv = google['lineCv']
          googleLinkCv = google['linkCv']
        }

        if (googleClicks === 0) {
          dairySessions = gaDailyData['sessions']
        } else {
          dairySessions = (
            gaDailyData['sessions_organic'] + 
            gaDailyData['sessions_other_advertising'] +
            gaDailyData['sessions_affiliate'] +
            gaDailyData['sessions_referral'] +
            gaDailyData['sessions_social'] +
            gaDailyData['sessions_direct'] +
            gaDailyData['sessions_email'] +
            gaDailyData['sessions_null'] +
            gaDailyData['other'] +
            googleClicks
          )
        }

        // コンバージョンを集計する
        // gaのコンバージョンからgaから集計されたadsのコンバージョンを取り除き、改めてadsから取得したコンバージョンを加算する（gaのadsのコンバージョンが不正確のため）
        let goal1Completions = (gaDailyData['goal1Completions'] - gaDailyData['goal1CompletionsGs'] - gaDailyData['goal1CompletionsGd']) + googleWebCv
        goal1Completions = goal1Completions > 0 ? goal1Completions : 0
        let goal2Completions = (gaDailyData['goal2Completions'] - gaDailyData['goal2CompletionsGs'] - gaDailyData['goal2CompletionsGd']) + googleTelCv
        goal2Completions = goal2Completions > 0 ? goal2Completions : 0
        let goal3Completions = (gaDailyData['goal3Completions'] - gaDailyData['goal3CompletionsGs'] - gaDailyData['goal3CompletionsGd']) + googleLineCv
        goal3Completions = goal3Completions > 0 ? goal3Completions : 0
        let goal4Completions = (gaDailyData['goal4Completions'] - gaDailyData['goal4CompletionsGs'] - gaDailyData['goal4CompletionsGd']) + googleLinkCv
        goal4Completions = goal4Completions > 0 ? goal4Completions : 0

        const goalCompletionsAll = goal1Completions + goal2Completions + goal3Completions + goal4Completions

        dairyDatas.push({
          'date': gaDailyData['date'],
          'sessions': Util.numberFormatter(dairySessions),
          'goalCompletionsAll': Util.numberFormatter(goalCompletionsAll),
          'goal1Completions': Util.numberFormatter(goal1Completions),
          'goal2Completions': Util.numberFormatter(goal2Completions),
          'goal3Completions': Util.numberFormatter(goal3Completions),
          'goal4Completions': Util.numberFormatter(goal4Completions),
          'googleClicks' : Util.numberFormatter(googleClicks),
          'googleCost' : Util.yenFormatter(googleCost),
        })

        totalSessions += dairySessions
        totalAdvertising += (gaDailyData['sessions_other_advertising'] + googleClicks)

        totalGoogleCost += googleCost

        totalGoal1Completions += goal1Completions
        totalGoal2Completions += goal2Completions
        totalGoal3Completions += goal3Completions
        totalGoal4Completions += goal4Completions
        totalGoalCompletionsAll += goalCompletionsAll
      }

      if (!this.reportDatas['google']['daily'].length) {
        totalAdvertising = this.reportDatas['ga']['channel']['paid_advertising']
      }

      let goalConversionRateAll = ''
      if (totalGoalCompletionsAll >= 1 && totalSessions == 0) {
        goalConversionRateAll = '-'
      } else if (totalGoalCompletionsAll == 0 && totalSessions == 0) {
        goalConversionRateAll = '0%'
      } else {
        goalConversionRateAll = (Math.round((totalGoalCompletionsAll / totalSessions) * 10 * 100) / 10) + '%' 
      }

      // 全広告の合計 --------------------------------------------------
      let cost = 0              // 広告費
      let conversions = 0       // コンバージョン数
      let clicks = 0            // クリック数
      let unusedAdExpenses = 0  // 未使用の金額
      for (let ad of ['google']) {
        cost += this.reportDatas[ad]['total']['cost'] ? this.reportDatas[ad]['total']['cost'] : 0
        conversions += this.reportDatas[ad]['total']['conversions'] ? this.reportDatas[ad]['total']['conversions'] : 0
        clicks += this.reportDatas[ad]['total']['clicks'] ? this.reportDatas[ad]['total']['clicks'] : 0
        unusedAdExpenses += this.reportDatas[ad]['total']['unusedAdExpenses'] ? this.reportDatas[ad]['total']['unusedAdExpenses'] : 0
      }

      const cpa = conversions > 0 ? Math.round(cost / conversions) : 0
      const conversion_rate = (Math.round((clicks > 0 ? conversions / clicks : 0) * 100 * 100) / 100) + '%'

      totalData = {
        // Analytics
        sessions: Util.numberFormatter(totalSessions),
        advertising: Util.numberFormatter(totalAdvertising),
        organic_search: Util.numberFormatter(this.reportDatas['ga']['channel']['organic_search']),
        direct_or_other: Util.numberFormatter(this.reportDatas['ga']['channel']['direct_or_other']),
        from_another_site: Util.numberFormatter(this.reportDatas['ga']['channel']['from_another_site']),
        goalConversionRateAll,
        goalCompletionsAll: Util.numberFormatter(totalGoalCompletionsAll),
        goal1Completions: Util.numberFormatter(totalGoal1Completions),
        goal2Completions: Util.numberFormatter(totalGoal2Completions),
        goal3Completions: Util.numberFormatter(totalGoal3Completions),
        goal4Completions: Util.numberFormatter(totalGoal4Completions),
        // Ads
        googleCost: Util.yenFormatter(totalGoogleCost),
        cpa: Util.yenFormatter(cpa),
        conversions: Util.numberFormatter(conversions),
        conversion_rate,
        cost: Util.yenFormatter(cost),
        unusedAdExpenses: Util.yenFormatter(unusedAdExpenses),
        clicks: Util.numberFormatter(clicks),
      }

      return { dairyDatas, totalData }
    },
    isThisMonth() {
      return Util.dateFormatter(new Date(), 'YYYY-MM') === Util.dateFormatter(this.reportDatas['targetDate'], 'YYYY-MM')
    },
    getValue(obj, key) {
      return Util.getValue(obj, key)
    },
    openModal(type) {
      // 自分で作るパターン
      this.modal = true
      this.type = type
    },
    modalClose() {
      console.log('AdsReportBoard.vue modalClose()')
      this.modal = false
      this.$emit('close')
    },
    changeModal(type) {
      // いったんモーダル閉じる
      this.modal = true
      this.type = type
    },
  }
}
</script>

<style scoped>
.ads-total-card .no-border {
  border: 0;
}

.table-container {
  margin-top: 30px;
}

.table-container table {
  width: 450px;
}

.table-container table th {
  text-align: center;
  vertical-align: middle;
}

.total-table table {
  border-collapse: separate;
  border-spacing: 0;
  width: 450px;
  margin: 20px auto;
}

.total-table td {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
  vertical-align: middle;
}

.total-table td:first-child {
  border-left: 1px solid #ccc;
}
.total-table tr:first-child>* {
  border-top: 1px solid #ccc;
}

.total-table td[colspan="2"] {
  text-align: center;
  font-weight: bold;
}

.total-table tr:nth-child(n+3) td:nth-child(odd) {
  text-align: left;
  width: 330px;
}

.total-table tr:nth-child(n+3) td:nth-child(even) {
  text-align: right;
  width: 120px;
}

.total-table tr:nth-child(1) td {
  height: 30px;
}

.total-table tr:nth-child(2) td {
  height: 50px;
}

.total-table tr:nth-child(n+3) td {
  height: 20px;
}
  
/* 角丸効果を追加 */
.total-table tr:first-child td:first-child {
  border-top-left-radius: 5px;
}

.total-table tr:first-child td:last-child {
  border-top-right-radius: 5px;
}

.total-table tr:last-child td:first-child{
  border-bottom-left-radius: 5px;
}

.total-table tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}

@media screen and (max-width: 768px) {
  .total-table table {
    width: 340px;
  }

  .total-table tr:nth-child(n+3) td:nth-child(odd) {
    width: 240px;
  }

  .total-table tr:nth-child(n+3) td:nth-child(even) {
    width: 100px;
  }
}

/* グラフ
  縮小には対応しているが、拡大してもグラフのサイズが変更されない
  対応として、サイズは固定しておく
*/
.chart {
  width: 700px;
  margin: 10px auto;
}

@media screen and (max-width: 1024px) {
  .chart {
    width: 500px;
  }
}

@media screen and (max-width: 768px) {
  .chart {
    width: 320px;
  }
}
</style>
