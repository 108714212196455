<template>
  <form @submit.prevent="validateForm">
    <section class="row payment-form">
      <h5>
        配信対象の変更
      </h5>
      <p class="has-text-centered service-title">広告の着地先URL</p>
      <p class="has-text-centered service-title">
        {{ pagedata['business_name'] }} {{ pagedata['branch_name'] }}<br>
        <a :href="'https://jp.ilb.net/' + pagedata['page_id'] + '/'" target="_blank">
          https://jp.ilb.net/{{ pagedata['page_id'] }}/
        </a>
      </p>

      <p class="mb-3">広告を配信する対象者の性別、年齢層を選択してください。</p>

      <!-- 性別のチェックボックス 女性・男性・不明 female,male,unknown -->
      <p><b>性別</b></p>
      <div class="mb-3" style="display: flex; gap: 10px;">
        <div v-for="(demographics_gender, i) in demographics_genders" :key="i">
          <b-checkbox
            :id="'demographics_gender' + i"
            type="checkbox"
            v-model="checkedSortDemographicsGenders"
            :native-value="demographics_gender">
            {{ demographics_gender.name }}
          </b-checkbox>
        </div>
      </div>

      <!-- 年齢層のプルダウン   -->
      <!-- [ 18 ▼]  〜 [ 65才以上 ▼] □ 不明 -->
      <!-- 18,25,35,45,55,65才以上 -->
      <p><b>年齢層</b></p>
      <b-field class="mb-5" grouped>
        <b-select
          v-model="adsdata[serviceName].demographics_age.lower"
        >
          <option
            v-for="(age, i) in ageLowerList"
            :value="age"
            :key="i"
          >
            {{ age }}
          </option>
        </b-select>
        〜
        <!-- 24,34,44,54,64,65才以上 -->
        <b-select
          v-model="adsdata[serviceName].demographics_age.upper"
        >
          <option
            v-for="(age, i) in ageUpperList"
            :value="age"
            :key="i"
          >
            {{ age }}
          </option>
        </b-select>
        <b-checkbox
          v-model="adsdata[serviceName].demographics_age.unknown"
          native
          true
          type="is-info"
        >
          不明
        </b-checkbox>
      </b-field>
      <div class="col s12 place-order-button-block">
        <b-button v-if='success === false' class="btn col s12 #e91e63 pink" type="is-info" @click="validateForm" :disabled="errors.any() || !isFormCompleted">
          変更内容を確認する
        </b-button>
        <b-button v-if="success === 'loading'" loading>Loading</b-button>
        <b-button v-if='success === true' class="btn col s12 #e91e63 pink" @click="modalClose">閉じる</b-button>
        <b-loading :is-full-page="isFullPage" :active.sync="isLoading"></b-loading>
      </div>
    </section>
  </form>
</template>

<script>
import Config from '../../config.js'
import moment from 'moment'

export default {
  props: ['pagedata', 'adsdata', 'chargesdata'],
  computed: {
    ageLowerList() {
      return ['18', '25', '35', '45', '55', '65才以上']
    },
    ageUpperList() {
      // 選択された年齢層の下限値によって上限値を変更
      switch (this.adsdata[this.serviceName].demographics_age.lower.replace(/\D*/g, '')) {
        case '18':
          return ['24', '34', '44', '54', '64', '65才以上']
        case '25':
          return ['34', '44', '54', '64', '65才以上']
        case '35':
          return ['44', '54', '64', '65才以上']
        case '45':
          return ['54', '64', '65才以上']
        case '55':
          return ['64', '65才以上']
        case '65':
          return ['65才以上']
        default:
          return ['24', '34', '44', '54', '64', '65才以上']
      }
    },
    isFormCompleted: function() {
      // 年齢層の下限値、上限値、性別が選択されているか
      if (
        !this.adsdata[this.serviceName].demographics_age.lower ||
        !this.adsdata[this.serviceName].demographics_age.upper ||
        !this.checkedSortDemographicsGenders.length
      ) {
        return false
      }
      return true
    },
  },
  data() {
      return {
          serviceName: 'google',
          success:false,
          isLoading: false,
          isFullPage: true,
          demographics_genders: [
          { code:'female', name:'女性' },
          { code:'male', name:'男性' },
          { code:'unknown', name:'不明' },
        ],
        checkedSortDemographicsGenders: [],
      }
  },
  methods: {
    getDemographicsGenders() {
      const array = []
      if(this.demographics_genders) {
        this.demographics_genders.map(demographics_gender => {
          if(this.adsdata && this.adsdata[this.serviceName].demographics_gender.indexOf(demographics_gender.code) !== -1 ) {
            array.push(demographics_gender);
          }
        })
      }
      return array
    },
    // 変更するボタン押下時
    async buttonPressed() {
      // loading
      this.success = 'loading'
      this.isLoading = true
      try {
        // 変更内容の確認モーダル表示
        const confirm = await this.$swal({
          title: '変更内容',
          html: this.$store.state.admin_data.service_name[this.serviceName].name
            + '<br><br>'
            + '下記の対象者に広告を配信するよう変更します'
            + '<br><br>'
            + '配信性別：' + this.checkedSortDemographicsGenders.map((checkedSortDemographicsGender) => checkedSortDemographicsGender.name).join('、')
            + '<br>'
            + '配信年齢層：' + this.adsdata[this.serviceName].demographics_age.lower + '〜' + this.adsdata[this.serviceName].demographics_age.upper + (this.adsdata[this.serviceName].demographics_age.upper.replace(/\D*/g, '') !== '65' ? '才' : '')
            + (this.adsdata[this.serviceName].demographics_age.unknown ? '、不明' : ''),
          icon: 'info',
          confirmButtonText: '変更する',
          showCancelButton: true,
          cancelButtonText: 'キャンセル',
        })

        if (!confirm.isConfirmed) {
          // 変更処理キャンセル
          this.success = false
          this.isLoading = false
          return
        }

        // 変更処理
        await Config.ADS_COLLECTION.doc(this.$store.state.page_id)
          .set({
            [this.serviceName]: {
              demographics_gender: this.checkedSortDemographicsGenders.map((checkedSortDemographicsGender) => checkedSortDemographicsGender.code),
              demographics_age: this.adsdata[this.serviceName].demographics_age
            }
          }, { merge: true })

        // メール送信
        await this.adsTargetChangeMail();

        // 変更完了画面
        await this.$swal({
          title: '変更承りました',
          html: '変更には１時間程度かかります。'
            + '<br><br>'
            + this.$store.state.admin_data.service_name[this.serviceName].name
            + '<br>'
            + '配信性別：' + this.checkedSortDemographicsGenders.map((checkedSortDemographicsGender) => checkedSortDemographicsGender.name).join('、')
            + '<br>'
            + '配信年齢層：' + this.adsdata[this.serviceName].demographics_age.lower + '〜' + this.adsdata[this.serviceName].demographics_age.upper + (this.adsdata[this.serviceName].demographics_age.upper.replace(/\D*/g, '') !== '65' ? '才' : '')
            + (this.adsdata[this.serviceName].demographics_age.unknown ? '、不明' : ''),
          icon: 'info',
          confirmButtonText: '管理画面に戻る',
        })

        await this.$emit('modalclose')
      } catch (e){
        await this.$swal({
            title:'変更が失敗しました。',
            text:'管理者に連絡してください:' + e.message,
            confirmButtonText: '管理画面に戻る',
            icon:'error',
        })
        await this.$emit('modalclose')
      }
      this.success = true
      this.isLoading = false
    },
    modalClose() {
        this.$emit('modalclose')
    },
    validateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.buttonPressed();
          return;
        }
      });
    },
    async adsTargetChangeMail() {
      let data = {
        template_id : 'ADS_TARGET_CHANGE_SUCCESS',
        email_auth : this.pagedata.email || this.$store.state.user.email,
        service_name : this.$store.state.admin_data.service_name[this.serviceName].name,
        business_name: this.pagedata.business_name,
        branch_name: this.pagedata.branch_name || '',
        category: this.pagedata.category || '',
        page_keyword:this.pagedata.page_keyword || '',
        demographics_gender: this.checkedSortDemographicsGenders.map((checkedSortDemographicsGender) => checkedSortDemographicsGender.name).join('、'),
        demographics_age: this.adsdata[this.serviceName].demographics_age.lower + '〜' + this.adsdata[this.serviceName].demographics_age.upper + (this.adsdata[this.serviceName].demographics_age.upper.replace(/\D*/g, '') !== '65' ? '才' : '')
          + (this.adsdata[this.serviceName].demographics_age.unknown ? '、不明' : ''),
        url :Config.DESTINATION_URL + '/' + this.$store.state.page_id + '/',
        status: 'sending',
        date: moment().format("YYYY-MM-DD HH:MM:SS"),
        admin_url :Config.MANAGE_URL,
        page_id: this.$store.state.page_id,
        name: this.$store.state.admin_data.service_name[this.serviceName].name,
        short_name: this.$store.state.admin_data.service_name[this.serviceName].short_name,
        system_name: this.$store.state.admin_data.service_name[this.serviceName].system_name,
        country_code: this.$store.state.admin_data.service_name.url.country_code,
        tld: this.$store.state.admin_data.service_name.url.tld,
      }
      await Config.SENDMAIL_TARGET_COLLECTION.add(data);
    }
  },
  watch: {
    // ソート処理用の動的変数
    checkedSortDemographicsGenders : function(oldVal, newVal) {
      if (oldVal.length !== newVal.length) {
        // codeでソート処理
        this.checkedSortDemographicsGenders.sort((a, b) => a.code - b.code)
        // 配列作成しなおしてads_dateに入れる。
        this.adsdata.demographics_gender = this.checkedSortDemographicsGenders.map((checkedSortDemographicsGender) => checkedSortDemographicsGender.name)
      }
    },
  },
  mounted () {
    this.checkedSortDemographicsGenders = this.getDemographicsGenders();
  }
}
</script>

<style scoped>
.service-title{
  margin-bottom:6px;
}
.service-type {
  margin-bottom:8px;
}
.service-price {
  margin-top:8px;
}
.price-container {
  margin-top: 10px;
}
.price-box {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.payment-form .comment {
  font-size:0.8rem;
  margin-bottom:10px;

}
.payment-form .error {
   color:red;

}
.payment-form {
    max-width: 400px;
    margin: 20px auto;
    border: 2px solid #ececec;
    padding: 12px;
    margin-bottom: 8px;
}
.payment-form h5 {
    margin: 5px;
    padding: 0px;
    text-align:center;
    font-size: 1.2rem;
}
.payment-form h6 {
    margin-bottom: 5px;
    padding: 0px;
    text-align:center;
    font-size: 1.5rem;
}
.card-element {
    margin-top: 10px;
}

.place-order-button-block {
    margin: 5px 0 20px 0;
    text-align:center;
}

button {
  background-color: #23D160;
  color: white;
  border-radius: 4px;
  font-size: 20px;
  /* font-weight: 600; */
  border: 0;
  box-shadow: none;
  padding: 8px 24px;
}
</style>
