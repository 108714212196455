<template>
  <section class="section container">
    <div class="columns">
      <div class="column is-3">
        <Menu @getdoc="getPageId" />
      </div>

      <div class="column">
        <h2 class="is-size-4 has-text-weight-bold py-5">
          ページの代理入力
        </h2>
        <p v-if="notification" class="is-size-6 has-text-weight-medium">
          下記ページで代理入力を依頼しますか？
        </p>
        <p v-else class="is-size-6 has-text-weight-medium">
          以下のページで代理入力を依頼しますか？
        </p>
        <br>
        <div class="mb-3">
          <p class="mb-1 is-size-3">{{ doc['business_name'] }} {{ doc['branch_name'] }}</p>
          <p>{{ destination_url}}/{{ doc['page_id'] }} </p>
        </div>
        <div v-if="notification">
          <p class="pb=1">
            代理入力は、インターネット上で確認できる御社の公式サイト、SNS、ブログなどの情報を元に、当社で作業するので手間が一切かかりません。
          </p>
          <p class="help">
            管理画面で入力済みの情報についても変更を行う場合があります。
          </p>
        </div>
        <div v-else>
          <p v-if="doc['business_name']">事業所名：{{ doc['business_name'] }}</p>
          <p v-else>事業所名：<span class="has-text-danger">未入力</span></p>
          <p v-if="doc['phone']">電話番号：{{ doc['phone'] }}</p>
          <p v-else>電話番号：<span class="has-text-danger">未入力</span></p>
        </div>
        <br>
        <div>
          <b-field
            :type="errors.has('url') ? 'is-danger': ''"
            :message="errors.has('url') ? '必須入力です。' : ''">
            <template slot="label">
              <span class="tag is-danger">必須</span>&nbsp;<b>代理入力する店舗・企業URL</b>
            </template>
            <div class="control">
              <p>店舗・企業の情報が掲載されているウェブサイトのURLをご入力ください。</p>
              <p>URLがない場合「なし」とご入力ください。入力内容の確認のため、当社よりご連絡差し上げます。</p>
              <b-input
                id="url"
                name="url"
                v-model="url"
                type="text"
                placeholder="URLを入力してください"
                v-validate="'required'"
              >
              </b-input>
            </div>
          </b-field>
          <br>
          <b>代理入力の希望内容、連絡事項など</b>
          <p>・特定のサービスを宣伝するページを作成したい場合</p>
          <p>・追加で掲載したい情報がある場合</p>
          <p>などの要望がありましたら、詳細をご入力ください。</p>
          <b-input
            v-model="agent_request"
            type="text"
            placeholder="希望内容、連絡事項などを入力してください"
          />
        </div>

        <br>
        <p v-if="notification" class="has-text-danger">代理入力はページごとに１回限り依頼が可能です。</p>
        <p v-else class="has-text-danger">代理入力に必要な項目が未入力のため依頼できません。管理画面から入力してください。</p>
        <br>
        <div class="col s12 place-order-button-block">
          <div v-if="notification">
            <b-button 
              v-if="success === false"
              class="btn col s12 #e91e63 pink"
              type="is-info"
              :disabled="errors.any() || !isFormCompleted"
              @click="agentRequest(true)"
              >
              代理入力を依頼する（無料）
            </b-button>
            <b-loading :is-full-page="isFullPage" :active.sync="isLoading"></b-loading>
          </div>
          <div v-else>
            <b-button class="btn col s12 #e91e63 pink" type="is-info" @click="moveRequiredTab()">項目を入力する</b-button>
          </div>
          <div v-if="notification">
            <hr>
            <p class="is-size-7">
              代理入力を希望しない場合は、<br>
              下記のボタンをクリックしてください。<br>
              今後、代理入力の案内メールも<br>
              配信されなくなります。<br>
            </p>
            <!--
          <a href="#" @click="agentRequest(false)">代理入力を依頼しない</a><br>
          -->
            <b-button class="btn col s12 #e91e63 pink mt-2" type="is-light" @click="agentRequest(false)">代理入力を依頼しない</b-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Config from '../config.js'
import moment from 'moment'
import Menu from '@/components/Menu.vue'

export default {
  mixins: [Menu],
  components: {
    Menu,
  },
  computed: {
    // 代理入力依頼ができるかの分岐用
    notification () {
      if(this.doc
        && this.doc.business_name
        && this.doc.phone) {
        return true;
      }
      return false;
    },
    destination_url () {
      return Config.DESTINATION_URL;
    },
    isFormCompleted () {
      return this.url ? true : false;
    },
  },
  data () {
    return {
      success:false,
      isLoading: false,
      isFullPage: true,
      requiredTabNo: 0,
      url: '',
      agent_request: '',
    }
  },
  methods: {
    /**
     * Menu（子コンポーネント）から選択したdocを受け取る
     */
    getPageId(value) {
      console.log('value', value);
      this.doc = value;
    },
    // url形式かチェック
    urlFormatCheck(str) {
      if (!str) {
        return false;
      }
      return str.match(/https?:\/\/[-_.!~*()a-zA-Z0-9;/?:&=+$,%#]+/);
    },
    /**
     * 代理入力依頼
     */
    async agentRequest (val) {
      this.isLoading = true

      try {
        await Config.SALES_COLLECTION
          .doc(this.doc["page_id"])
          .set(
            {
              date : moment(moment().unix(),'X').format(),
              page_revision : val,
              uid: this.$store.state.user.uid,
              business_name: this.doc['business_name'],
              branch_name: this.doc['branch_name'] || null,
              email: this.doc['email'] || this.$store.state.user.email,
              phone: this.doc['phone'],
              phone_contact: this.doc['phone_contact'] || null,
              url: this.url || null,
              agent_request: this.agent_request || null,
            }, { merge: true }
          )
      } catch(e) {
        this.$swal('error', '代理入力依頼のエラーです：' + e, 'error')
        return
      }

      this.isLoading = await false

      // 確認用のswal
      const swalParam = this.getSwalParam(val)
      const result = await this.$swal(swalParam);
      if (result.isConfirmed) {
        this.$router.push('/').catch(() => {})
      }
    },
    /**
     * swal表示内容
     * @param boolean operation
     */
    getSwalParam(operation) {
      let swalParam
      switch (operation) {
        case true:
          swalParam = {
            title : '代理入力の依頼完了',
            html : '代理入力が完了し次第、メールにてご報告します。',
            icon : 'success',
            confirmButtonText : '管理画面に戻る'
          }
          break;
        case false:
          swalParam = {
            title : '代理入力不要の受付完了',
            html : '今後、代理入力の案内メールも配信停止されます。',
            icon : 'success',
            confirmButtonText: '管理画面に戻る'
          }
          break;
      }
      return swalParam
    },
    /**
     * 必須入力タブへ移動
     */
    moveRequiredTab () {
      this.$router.push('/?active_tab=' + this.requiredTabNo).catch(() => {})
    }
  },
}
</script>

<style scoped>
.service-title{
  margin-bottom:6px;
}
.form {
    max-width: 400px;
    margin: 20px auto;
    border: 2px solid #ececec;
    padding: 12px;
    margin-bottom: 8px;
}
.form h5 {
    margin: 5px;
    padding: 0px;
    text-align:center;
    font-size: 1.2rem;
}
.form h6 {
    margin-bottom: 5px;
    padding: 0px;
    text-align:center;
    font-size: 1.5rem;
}

.place-order-button-block {
    margin: 5px 0;
    text-align:center;
}

button {
  background-color: #23D160;
  color: white;
  border-radius: 4px;
  font-size: 20px;
  width:100%;
  /* font-weight: 600; */
  border: 0;
  box-shadow: none;
  padding: 8px 24px;
}
.redColor{
  color:red;
  font-weight: bold;
}
</style>
