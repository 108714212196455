<template>
<section class="section container">

  <div class="columns">
    <div class="column is-3">
      <Menu @getdoc='getPageId' />
    </div>

    <div class="column">
      <h1 class="is-size-4 has-text-weight-bold">問合せ内容の一覧</h1>
      <p class="mb-3">ページのメールフォームから送信された問合せ内容の一覧です。問合せ日時での並べ替えや問合せ内容などでの検索ができます。</p>

      <!-- 参考：https://xaksis.github.io/vue-good-table/ -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: true,
          skipDiacritics: true,
          placeholder: '名前、電話番号、メールアドレス、お問合せ内容で検索ができます',
        }"
        :pagination-options="{
          enabled: true,
          setCurrentPage: 1,
          mode: 'records',
          perPage: 20,
          dropdownAllowAll: false,
          jumpFirstOrLast : true,
          firstLabel : '最初のページ',
          lastLabel : '最後のページ',
          nextLabel: '次へ',
          prevLabel: '前へ',
          rowsPerPageLabel: '1ページあたりの行数',
          ofLabel: 'of',
          pageLabel: 'page', // for 'pages' mode
          allLabel: 'All',
        }"
        :sort-options="{
          enabled: true,
          initialSortBy: {field: 'send_time', type: 'desc'},
        }"
        compactMode
        >
        <div slot="emptystate">
          データがありません。
        </div>
      </vue-good-table>
    </div>
  </div>
</section>
</template>

<script>
import Menu from '@/components/Menu.vue'
import Config from '@/config.js'

export default {
  name: 'ContactList',
  mixins: [Menu],
  components: {
    Menu,
  },
  computed: {
    pageData: {
      get() {
        return this.doc;
      },
      set(data) {
        this.$emit('doc', data);
      }
    },
  },
  data(){
    return {
      columns: [
        {
          label: '日時',
          field: 'send_time',
        },
        {
          label: '名前',
          field: 'form_name',
          sortable: false,
        },
        {
          label: '電話番号',
          field: this.formTel,
          html: true,
          sortable: false,
        },
        {
          label: 'メール',
          field: this.formEmail,
          html: true,
          sortable: false,
        },
        {
          label: '予約希望日時',
          field: this.reservationDate,
          sortable: false,
          html: true,
        },
        {
          label: '問合せ内容',
          field: 'form_content_html',
          html: true,
          sortable: false,
        },
      ],
      rows: [],
      doc: {},
    };
  },
  methods: {
    getPageId (value) {
      this.doc = value
    },
    async listenMailHistory() {
      let rows = []
      const historyData = await Config.MAIL_HISTORY_COLLECTION.doc(this.doc['page_id']).collection('send').get()
      historyData.forEach((doc) => {
        const data = doc.data()
        rows.push({
          form_name: data.form_name,
          form_tel: data.form_tel,
          form_email: data.form_email,
          form_content_text: data.form_content_text,
          form_content_html: data.form_content_html,
          send_time: data.send_time,
          form_reservation1_date: data.form_reservation1_date,
          form_reservation1_hour: data.form_reservation1_hour,
          form_reservation2_date: data.form_reservation2_date,
          form_reservation2_hour: data.form_reservation2_hour,
        })
      })
      this.rows = rows
    },
    reservationDate(row) {
      let message = '';
      if (row.form_reservation1_date) {
        message = '1. ' + this.toHalfWidth(row.form_reservation1_date) + ' ' + this.toHalfWidth(row.form_reservation1_hour) + "<BR>"
      } else {
        if (row.form_reservation2_date) {
          message = '1. ' + this.toHalfWidth(row.form_reservation2_date) + ' ' + this.toHalfWidth(row.form_reservation2_hour)
          return message
        }
      }
      if (row.form_reservation2_date) {
        message = message + '2. ' + this.toHalfWidth(row.form_reservation2_date) + ' ' + this.toHalfWidth(row.form_reservation2_hour)
      }
      return message
    },
    formTel(row) {
      return row.form_tel ? '<a href="tel:' + row.form_tel + '" target="_BLANK">' + row.form_tel + '</a>' : ''
    },
    formEmail(row) {
      return row.form_email ? '<a href="mailto:' + row.form_email + '" target="_BLANK">' + row.form_email + '</a>' : ''
    },
    toHalfWidth(str) {
      // 全角英数字を半角に変換
      if (!str) return ''
      str = str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
      });
      return str;
    }
  },
  watch: {
    doc: {
      handler: function () {
        this.rows = []
        this.listenMailHistory()
      },
      deep: true
    }
  },
}
</script>

<style>

</style>
