<template>
  <div>
    <b-notification aria-close-label="Close notification">
      御社で提供しているサービスを１０件まで掲載できます。<br>
      <br>
      ※タイトルの右側にある「︙」をクリックすると、順番の変更が可能です。
    </b-notification>

    <img class="pb-3" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/service.png">

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="service_title">{{ title.service_title }}</label>
      <p class="pb-1">
        提供サービスを掲載する項目の見出しを入力してください。<br>
        例１）主なメニュー<br>
        例２）主なサービス<br>
        例３）診療内容一覧<br>
        <span class="has-text-danger">※未入力の場合、提供サービスは表示されません</span>
      </p>
      <b-input id="service_title" v-model="pageData['service_title']" maxlength="10" @blur="trim('service_title')"></b-input>
    </div>

    <hr>
    <!--ここから-->
    <div v-if="updateFlg">
      <div class="content" v-for="key of 10" :key="key">
        <div class="move-panel">
          <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
          <template #trigger>
            <b-icon icon="dots-vertical"></b-icon>
          </template>
            <b-dropdown-item aria-role="listitem" @click="menuMove('service', (key -1),'up', true)" :disabled="(key-1) === 0">一番上に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMove('service',(key-1),'up')" :disabled="(key-1) === 0">一つ上に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMove('service',(key-1),'down')" :disabled="(key-1) === (pageData['service'].length - 1)">一つ下に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMove('service',(key-1),'down', true)" :disabled="(key-1) === (pageData['service'].length - 1)">一番下に移動</b-dropdown-item>
          </b-dropdown>
        </div>

        <b-collapse
          aria-id="contentIdForA11y2"
          class=""
          animation="slide"
          v-model="isOpen">
          <template #trigger>
            <div
              class="panel-heading mb-3"
              role="button"
              aria-controls="contentIdForA11y2">
              <strong v-if="pageData['service'][key-1]['title']">{{ pageData['service'][key-1]['title']}}</strong>
              <strong v-else>サービス{{ key }}のタイトルを入力してください</strong>
            </div>
          </template>

          <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'service_title_' + key">提供サービス{{ key }}のタイトル</label>
            <p class="pb-1" v-if="(key-1) === 0">
              例１）寝てるだけで楽ヤセダイエット（30分）<br>
              例２）慢性腰痛解消コース（40分）<br>
              例３）マウスピース型矯正
            </p>
            <b-input
              :id="'service_title_' + key"
              v-model="pageData['service'][key-1]['title']"
              maxlength="30"
              @blur="trim('service', key-1, 'title')"
              >
              </b-input>
          </div>

          <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'service_tag_' + key">提供サービス{{ key }}のタグ </label>
            <p class="pb-1" v-if="(key-1) === 0">
              提供サービスのタイトルの上に表示する「タグ」を入力してください。<br>
              例１）人気No.1<br>
              例２）店長のオススメ<br>
              例３）期間限定
            </p>
            <b-input
              :id="'service_tag_' + key"
              v-model="pageData['service'][key-1]['tag']"
              maxlength="15"
              @blur="trim('service', key-1, 'tag')"
              >
              </b-input>
          </div>

          <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'service_price_' + key">提供サービス{{ key }}の価格など</label>
            <p class="pb-1" v-if="(key-1) === 0">
              例１）2,980円（税込）<br>
              例２）850,000円（検査・診断料別）<br>
              例３）通常価格 4,500円 → 初回特別価格 1,950円（税込）<br>
              ※価格の割引がある場合、通常価格と割引後の価格を両方記載してください。
            </p>
            <b-input
              :id="'service_price_' + key"
              v-model="pageData['service'][key-1]['price']"
              maxlength="30"
              @blur="trim('service', key-1, 'price')"
              >
              </b-input>
          </div>

          <div class="field pb-3">
            <label class="label has-text-weight-bold">提供サービス{{ key }}の画像</label>
            <p class="pb-1">
              どのようなサービスなのかが分かりやすい画像を登録してください。<br>
              ※スマホからでも見やすいように横長のサイズ（横幅4：縦幅3 程度の比率）で文字が入っていない画像がオススメです。
            </p>
            <div class="columns">
              <div v-if="!imageFile[key-1].isDeleted" class="column is-one-quarter">
                <img :src="pageData['service'][key-1]['image']" width="100px">
              </div>
              <div class="column">
                <photo-resize  :image-title="'service_image'" :array-num="key-1" :doc-field-title="'service'" :src="pageData['service'][key-1]['image']" @serviceimage="imgUpdate" ref="serviceimage"/>
              </div>
            </div>
          </div>

          <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'service_detail_' + key">提供サービス{{ key }}の詳細</label>
            <p class="pb-1" v-if="(key-1) === 0">
              「サービスを簡単にまとめた説明」と「どういう方にオススメのサービスなのか？」などを入力してください。<br>
              <br>
              例）ドライヘッドスパは、オイルや水を使用せず指先と手のひらで頭をほぐす施術です。凝り固まった頭皮を揉みほぐしていくため、睡眠不足、眼精疲労、頭痛や肩こりでお悩みの方におすすめです。
            </p>
            <b-input
              :id="'service_detail_' + key"
              v-model="pageData['service'][key-1]['detail']"
              maxlength="100"
              type="textarea"
              @blur="trim('service', key-1, 'detail')"
              >
              </b-input>
          </div>

          <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'service_url_' + key">提供サービス{{ key }}の詳細URL</label>
            <p class="pb-1" v-if="(key-1) === 0">
              サービスについての詳細が分かるURL（ランディングページ、予約サイトのメニューページなど）を入力してください。
            </p>
            <b-input
              :id="'service_url_' + key"
              v-model="pageData['service'][key-1]['url']"
              @blur="trim('service', key-1, 'url')"
              >
              </b-input>
          </div>
        </b-collapse>
      </div>
    </div>
    <!-- ここまで  -->

    <hr>

    <div class="field">
      <label class="label has-text-weight-bold" for="service_viewmore">{{ title.service_viewmore }}</label>
      <p class="pb-1">
        サービスの一覧ページにリンクさせたい場合は、一覧ページのURLを入力してください。<br>
      </p>
      <b-input id="service_viewmore" v-model="pageData['service_viewmore']" @blur="trim('service_viewmore')"></b-input>
    </div>
  </div>
</template>

<script>
import 'bulma/css/bulma.css'
import PhotoResize from '@/components/PhotoResize.vue'

export default {
  props: ['doc','title'],
  computed: {
    pageData: {
      get() {
        return this.doc;
      },
      set(data) {
        this.$emit('doc', data);
      }
    },
  },
  components: {
    'PhotoResize': PhotoResize
  },
  methods: {
    // photoResize→親（Main.vue）へ渡す画像用配列：imageFileData
    imgUpdate: function (imageFileData) {
      this.imageFile[imageFileData["arrayNum"]] = imageFileData
      // 子コンポーネント画面再描画用
      this.updateFlg = Math.random()
      this.$emit('imgUpdate', imageFileData);
    },
    // 親（Main.vue）→photoResize.reset(画面上の画像表示削除させる)
    reset : function(){
      Object.keys(this.$refs.serviceimage).map(async key => {
        this.$refs.serviceimage[key].reset()
      })
    }
  },
  data () {
    return {
      updateFlg: true, // 再描画用
      isOpen: true,
      regex : /^([^/&?=<>"'#!@{};:\\|~¥*])+$/,
      imageFile: Array.from(
        Array(10)
        ).map(
          () => {
            return {
              isDeleted: false
            }
          }
        ),
    }
  }
}
</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
.move-panel {
  position:relative;
  top:12px;
  left: -1%;
}
</style>
