<template>
  <form @submit.prevent="validateForm">
    <section class="row payment-form">
        <h5>
            広告費・決済日の変更
        </h5>
        <p class="has-text-centered service-title">広告の着地先URL</p>
        <p class="has-text-centered service-title">
          {{ pagedata['business_name'] }} {{ pagedata['branch_name'] }}<br>
          <a :href="'https://jp.ilb.net/' + pagedata['page_id'] + '/'" target="_blank">
              https://jp.ilb.net/{{ pagedata['page_id'] }}/
          </a>
        </p>

        <p><b>現在の広告費：<span style="color:#ff0000;">{{ price }}万円</span></b></p>
        <p><b>希望の広告費</b></p>

        <b-field grouped>
          <b-select placeholder="変更なし" v-model="budgetNum">
            <option
                v-for="(budget, i) in budgetList"
                :value="budget.value"
                :key="i">
                {{ budget.text }}
            </option>
          </b-select>
          <p class="service-price">
            万円
          </p>
        </b-field>
        <span class="help is-danger">広告費は約1ヶ月間で使用します。</span>
        <br>
        <span class="help is-danger" v-show="errors.has('希望の広告費')">{{ errors.first('希望の広告費') }}</span>
        <br>
        <p><b>現在の決済日：<span style="color:#ff0000;">{{ payment_date }}日</span></b></p>
        <b>希望の決済日</b>

        <b-field grouped>
          <b-select placeholder="変更なし" v-model="paymentDate">
            <option
                v-for="(date, i) in demandDateList"
                :value="date.value"
                :key="i">
                {{ date.text }}
            </option>
          </b-select>
          <p class="service-price">
            日
          </p>
        </b-field>
        <span class="help is-danger" v-show="errors.has('希望の決済日')">{{ errors.first('希望の決済日') }}</span>

        <p class="is-size-7">※本日決済を希望される場合、希望の決済日は本日の日付を選択してください。</p>

        <div v-if="checkout" class="price-container">
          <p>
            <b><u class="has-text-danger"><span class="is-size-5 has-text-danger">決済金額：{{amount.toLocaleString()}}円（税込）</span></u></b>
          </p>
          <div class="box price-box">
            <b>内訳</b><br>
            広告費：{{budget.toLocaleString()}}円<br>
            運用代行費：{{fee.toLocaleString()}}円（広告費の20%）<br>
            10%対象(税抜)：{{ (budget + fee).toLocaleString() }}円<br>
            10％対象消費税：{{tax.toLocaleString()}}円<br>
          </div>
          <div v-if="checkoutToday">
            <p><span class="has-text-danger">希望の決済日が本日の日付のため、「変更する」ボタンをクリックすると、即時決済されます</span></p>
          </div>
        </div>



        <div class="col s12 place-order-button-block">
            <b-button
              v-if='success === false'
              class="btn col s12 #e91e63 pink"
              type="is-info"
              @click="validateForm"
              :disabled="errors.any() || !isFormCompleted"
              >
                変更する
            </b-button>
            <b-button v-if="success === 'loading'" loading>Loading</b-button>
            <b-button v-if='success === true' class="btn col s12 #e91e63 pink" @click="modalClose">閉じる</b-button>
            <b-loading :is-full-page="isFullPage" :active.sync="isLoading"></b-loading>
        </div>
        <div v-if="checkoutToday" class="service-title">
          <p class="has-text-centered">
            決済に使用するカード情報<br>
            ●●●● {{ chargesdata.last4 }}（{{ chargesdata.brand }}）
          </p>
          <p class="has-text-centered">
            <a @click="changeModal('changeModalCardchange')">カード情報を変更する</a>
          </p>
        </div>
    </section>
  </form>
</template>

<script>
import Config from '../../config.js'
import moment from 'moment'

export default {
  props: ['pagedata', 'adsdata', 'chargesdata'],
  computed: {
    price () {
      if(this.serviceName) {
        return this.chargesdata[this.serviceName].price / ((1 + Config.TAX_RATE) * 10) / ((1 + Config.FEE_RATE) * 10) * 100 / 10000;
      }
      return null
    },
    payment_date () {
      if(this.serviceName) {
        return this.chargesdata[this.serviceName].payment_date
          ? parseInt(this.chargesdata[this.serviceName].payment_date, 10) : null;
      }
      return null
    },
    budget () {
      const regex = new RegExp(/\d+?$/);
      // 判定
      if (regex.test(this.budgetNum)) {
          return this.budgetNum * 10000
      } else {
        return this.price * 10000
      }
    },
    fee () {
      return this.budget * Config.FEE_RATE
    },
    tax() {
      return (this.budget + this.fee) * Config.TAX_RATE
    },
    amount() {
      return this.budget + this.fee + this.tax
    },
    isFormCompleted: function() {
      if (
        !this.serviceName ||
        (this.serviceName && !this.budgetNum && !this.paymentDate)
      ) {
        return false
      }
      return true
    },
    checkout () {
      // budgetに入力あるか、payment_dateが本日の場合のみ表示させる
      if (this.budgetNum || ('00' + this.paymentDate).slice(-2) === moment().format("DD")) {
        return true
      }
      return false
    },
    checkoutToday () {
      if (('00' + this.paymentDate).slice(-2) === moment().format("DD")) {
        return true
      }
      return false
    }
  },
  data() {
      return {
          budgetNum: null,
          serviceName: 'google',
          paymentDate:null,
          success:false,
          isLoading: false,
          isFullPage: true,
          budgetList : [...Array(99)].map((_, i) => {
            if(i === 0 ) {
              return {
                text : '変更なし',
                value:''
              }
            }
            return {
              text : i + 2,
              value : i + 2
            }
          }),
          demandDateList : [...Array(32)].map((_, i) => {
            if(i === 0) {
              return {
                text : '変更なし',
                value : ''
              }
            }
            return {
              text : i,
              value : ('00' + i).slice( -2 )
            }
          })
      }
  },
  async mounted() {
    // vee-validateのカスタマイズ
    const dict = {
      custom: {
        '希望の決済日': {
          'numeric': '決済日は半角数字のみ使用できます',
          'max_value':'1〜31の半角数字を入力してください'
        },
      }
    };
    this.$validator.localize('ja', dict);
    this.$validator.validateAll()
  },
  methods: {
    // 決済するボタン
    async buttonPressed() {
      // loading
      this.success = 'loading'
      this.isLoading = true
      // 変更された日が本日なら即時決済
      if(('00' + this.paymentDate).slice(-2) === moment().format("DD")
      ) {
        // 即時決済（＆決済処理によりcharge_dateのpayment_dateも変更される）
        console.debug('即時決済', this.serviceName)
        await this.chargeStripe(this.serviceName)
      } else {
        console.debug('決済データ変更')
        try {
          // charges_dataのpayment_dateを変更する
          await Config.CHARGES_COLLECTION.doc(this.$store.state.page_id)
            .set({
              [this.serviceName] : {
                price : this.budgetNum ? this.amount : this.chargesdata[this.serviceName].price,
                payment_date : this.paymentDate ? this.paymentDate : this.payment_date
              }
            }, { merge: true })

          // ３）メール送信
          await this.adBudgetChangeMail(
            this.budgetNum ? this.amount.toLocaleString() : this.chargesdata[this.serviceName].price.toLocaleString(),
            this.paymentDate ? this.paymentDate : this.payment_date
          )

          await this.$swal({
              title:'変更が完了しました',
              html:this.$store.state.admin_data.service_name[this.serviceName].name
                + '<br><br>決済金額：'
                + (this.budgetNum ? this.amount.toLocaleString() : this.chargesdata[this.serviceName].price.toLocaleString())
                + '円（税込）<br>決済日：'
                + Number(this.paymentDate ? this.paymentDate : this.payment_date)
                + '日',
              icon:'success',
              confirmButtonText: '管理画面に戻る'
          })

          await this.$emit('modalclose')
        } catch (e){
          await this.$swal({
              title:'変更が失敗しました。',
              text:'管理者に連絡してください:' + e.message,
              confirmButtonText: '管理画面に戻る',
              icon:'error',
          })
          await this.$emit('modalclose')
        }
        this.success = true
        this.isLoading = false
      }
    },
    // stripe決済処理
    async chargeStripe(serviceName) {
        const checkOut = Config.FIREBASE_FUNCTIONS.httpsCallable('instantPaymentForAd')

        let checkout_data = {}
        checkout_data['page_data'] = this.pagedata
        checkout_data['service_name'] = serviceName
        checkout_data['budget'] = this.budget
        checkout_data['amount'] = this.amount
        checkout_data['instant'] = true  // 即時決済フラグ

        try {
          let result = await checkOut(checkout_data)
          // 決済成功
          if (result.data.status) {
            // ３）メール送信
            await this.adBudgetChangeMail(
              this.amount.toLocaleString(),
              this.paymentDate ? this.paymentDate : this.payment_date
            )

            await this.$swal({
                title:'変更および決済が完了しました',
                html:this.$store.state.admin_data.service_name[this.serviceName].name + '<br><br>決済金額：' + this.amount.toLocaleString() + '円（税込）<br>決済日：' + Number(this.paymentDate ? this.paymentDate : this.payment_date) + '日',
                confirmButtonText: '管理画面に戻る',
                icon:'success',
            })
            await this.$emit('modalclose')
          } else {
            // 決済失敗
            await this.$swal({
                title:'決済がエラーとなりました',
                html: result.data.error.code
                      + '<br>'
                      + result.data.error.message,
                confirmButtonText: '管理画面に戻る',
                icon:'error',
            })
          }
        } catch (e){
          await this.$swal({
              title:'決済がエラーとなりました',
              text:'管理者に連絡してください:' + e.message,
              confirmButtonText: '管理画面に戻る',
              icon:'error',
          })
          await this.$emit('modalclose')
        }
        await this.$emit('modalclose')
        this.success = true
        this.isLoading = false
    },
    modalClose() {
        this.$emit('modalclose')
    },
    changeModal(type){
      this.$emit(type);
    },
    validateForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.buttonPressed();
          return;
        }
      });
    },
    async adBudgetChangeMail(price, payment_date) {
      let data = {
        template_id : 'AD_BUDGET_CHANGE_SUCCESS',
        email_auth : this.pagedata.email || this.$store.state.user.email,
        service_name : this.$store.state.admin_data.service_name[this.serviceName].name,
        budget : this.budget.toLocaleString(),
        business_name: this.pagedata.business_name,
        branch_name: this.pagedata.branch_name || '',
        category: this.pagedata.category || '',
        page_keyword:this.pagedata.page_keyword || '',
        url :Config.DESTINATION_URL + '/' + this.$store.state.page_id + '/',
        price: price,
        tax: this.tax.toLocaleString(),
        tax_excluded_price: (this.budget + this.fee).toLocaleString(),
        payment_date: payment_date,
        status: 'sending',
        date: moment().format("YYYY-MM-DD HH:MM:SS"),
        admin_url :Config.MANAGE_URL,
        page_id: this.$store.state.page_id,
        name: this.$store.state.admin_data.service_name[this.serviceName].name,
        short_name: this.$store.state.admin_data.service_name[this.serviceName].short_name,
        system_name: this.$store.state.admin_data.service_name[this.serviceName].system_name,
        country_code: this.$store.state.admin_data.service_name.url.country_code,
        tld: this.$store.state.admin_data.service_name.url.tld,
      }
      await Config.SENDMAIL_TARGET_COLLECTION.add(data);
    }
  }
}
</script>

<style scoped>
.service-title{
  margin-bottom:6px;
}
.service-type {
  margin-bottom:8px;
}
.service-price {
  margin-top:8px;
}
.price-container {
  margin-top: 10px;
}
.price-box {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.payment-form .comment {
  font-size:0.8rem;
  margin-bottom:10px;

}
.payment-form .error {
   color:red;

}
.payment-form {
    max-width: 400px;
    margin: 20px auto;
    border: 2px solid #ececec;
    padding: 12px;
    margin-bottom: 8px;
}
.payment-form h5 {
    margin: 5px;
    padding: 0px;
    text-align:center;
    font-size: 1.2rem;
}
.payment-form h6 {
    margin-bottom: 5px;
    padding: 0px;
    text-align:center;
    font-size: 1.5rem;
}
.card-element {
    margin-top: 10px;
}

.place-order-button-block {
    margin: 5px 0 20px 0;
    text-align:center;
}

button {
  background-color: #23D160;
  color: white;
  border-radius: 4px;
  font-size: 20px;
  /* font-weight: 600; */
  border: 0;
  box-shadow: none;
  padding: 8px 24px;
}
</style>
