<template>
  <section class="row payment-form">
    <h5>
        Google広告を申込む
    </h5>
    <p class="has-text-centered service-title">
      広告の着地先URL<br>
      {{ pagedata['business_name'] }} {{ pagedata['branch_name'] }}<br>
      <a :href="'https://jp.ilb.net/' + pagedata['page_id'] + '/'" target="_blank">
          https://jp.ilb.net/{{ pagedata['page_id'] }}/
      </a>
    </p>

    <div class="service-title">
      <p class="has-text-centered">現在、ページが非公開となっているため</p>
      <p class="has-text-centered">Google広告の申込はできません</p>
    </div>
    <div class="service-title">
      <p class="has-text-centered">月額利用料の決済を完了し、あらためてGoogle広告をお申込ください</p>
    </div>

    <p class="has-text-centered">
      <b-button
        type="is-success has-text-weight-bold"
        class="btn col s12 #e91e63 pink"
        @click="$router.push('/payment').catch(() => {})"
      >
      決済画面に移動する
      </b-button>
    </p>
  </section>
</template>

<script>
export default {
    props: ['pagedata'],
    methods: {
      modalClose() {
          this.$emit('modalclose')
      },
      changeModal(type){
        this.$emit(type);
      }
    }
}
</script>

<style scoped>
.service-title{
  margin-bottom:20px;
}
.payment-form .comment {
  font-size:0.8rem;
  margin-bottom:10px;

}
.payment-form .error {
   color:red;

}
.payment-form {
    max-width: 400px;
    margin: 20px auto;
    border: 2px solid #ececec;
    padding: 12px;
    margin-bottom: 8px;
}
.payment-form h5 {
    margin: 5px;
    padding: 0px;
    text-align:center;
    font-size: 1.2rem;
}
.payment-form h6 {
    margin-bottom: 5px;
    padding: 0px;
    text-align:center;
    font-size: 1.5rem;
}

button {
  background-color: #23D160;
  color: white;
  border-radius: 4px;
  font-size: 20px;
  /* font-weight: 600; */
  border: 0;
  box-shadow: none;
  padding: 8px 24px;
}
</style>
