export default {
    apiKey: 'AIzaSyBj39RbyZS7Py2NUuRM1uMzdlxiCXddenM',
    authDomain: 'ilbnet-jp.firebaseapp.com',
    databaseURL: 'https://ilbnet-jp.firebaseio.com',
    projectId: 'ilbnet-jp',
    storageBucket: 'ilbnet-jp.appspot.com',
    messagingSenderId: '694600949826',
    appId: '1:694600949826:web:580adf1243318ce71b8ddb'

  }
  