<template>
  <section class="section container">
    <div class="columns">
      <div class="column is-3">
        <Menu @getdoc="getPageId" />
      </div>

      <div class="column">
        <h1 class="is-size-4 has-text-weight-bold">よくある質問</h1>
        <p class="">ページ、Google広告、Googleビジネスプロフィール、お支払いについてのFAQ（よくある質問）をご確認いただけます。</p>

        <hr />

        <h2 class="is-size-4 has-text-weight-bold">ページのFAQ</h2>
        <p>
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/1ceuKICQf5eMZhNeVEsa8UcQJnHYO-IBC4evw4QZ1WIc" target="_blanc">ページを公開する方法</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/14cZ2gTZyP5IK7yZ5w8Yr98M_SN2zJcGH32oeswQYIdE/edit?usp=sharing" target="_blanc">ページ作成のポイントと作成方法</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/1b0qPklE-JZbAFYFGOBVTbc1guYbdRoSjZY7lm7cL84s/edit?usp=sharing" target="_blanc">テキスト・画像の追加、修正方法</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/1W5akUVD6XOVc7M-VMSz5O_j6U8rXRnWW90hbGZBKijI/edit?usp=sharing" target="_blanc">画像編集の方法</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/19bXDMsUsGqgS053byjTOhWuvpuru5SJyNnRZG3jynQ8/edit?usp=sharing" target="_blanc">特長（選ばれる理由）の掲載ポイント</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/1dnTqiVE5hCN6CizDceURxlbZWAFRAFsweVEB6s7cTLo/edit?usp=sharing" target="_blanc">LINE友だち追加特典の掲載ポイント</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/1euXNJqYTsza6xYdMlD0uSCAHTpqpf4Rcp1Q_YKagSOk/edit?usp=sharing" target="_blanc">サービス宣伝用チラシの作成方法と活用方法</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/1kHsqBazgKxjqQkgrXZkZpGfNg6Nwj5DQtCclbFh9rho/edit?usp=sharing" target="_blanc">オファー宣伝用チラシの作成方法と活用方法</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/1I2U6bNqMgZkwv_kIZwRKj3MJi0vl_WU7trmS4trQJdA/edit?usp=sharing" target="_blanc">LINE友だち募集用チラシの作成方法と活用方法</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/1zTqCy-3fbloQRsLv1ETyiFxtgzP_WfN2azgVIaxieHA/edit?usp=sharing" target="_blanc">クチコミ募集用チラシの作成方法と活用方法</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/1o7xgFY5UBab6l-eliWiScbi7lN1ffO2nUArsiwNkNYo/edit?usp=sharing" target="_blanc">チラシ印刷時の注意点</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/19fQdXUoLZR3KgHxb27k_1NMJ5Y6p-DHGhtQVlvegxsU/edit?usp=sharing" target="_blanc">ログインメールアドレス、パスワードの変更方法</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/1b6bbDp_FQDW-jKUxkkdca7NPvkmJFjFaMY_OIR_SGTg/edit?usp=sharing" target="_blanc">ページを削除する方法</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/1W3fFun20mwsNNC96xQlqdRiGabOvL8_b9fX4oYJKfAg/edit?usp=sharing" target="_blanc">アカウントを削除する方法</a><br />
          <br />
          ※<a href="https://drive.google.com/drive/folders/1FuIJW0dE84m52xt8zi1DUWoGud4UyGgh" target="_blanc">ページのFAQ一覧を見る</a><br />
        </p>

        <hr />

        <h2 class="is-size-4 has-text-weight-bold">Google広告のFAQ</h2>
        <p>
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/1O5ZE4-mq-oPpT7oZEefACSaxP3OWyuzgWLh3OtPsNzQ/edit?usp=sharing" target="_blanc">Google広告の申込み方法</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/1XBom2suwF9YgZfcVpo5O0nn2WQqAdCUJANr0wfAty9s/edit?usp=sharing" target="_blanc">Google広告の種類と推奨の広告費について</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/1kzUc3qdcJ4Ef3tD7j9tVhuEsMNpkyDlneC6o860mvwo/edit?usp=sharing" target="_blanc">広告の設定内容の確認方法</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/1MIoZLddfUn2_jzZgmGjxxZDuVoKsvRwKHzFqpJSDx0c/edit?usp=sharing" target="_blanc">配信地域の変更方法</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/116COQvdOZziZyGNq8u-fTe8WFc3qGW4fqK_VzcU4Pm8/edit?usp=sharing" target="_blanc">広告費・決済日の変更方法</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/1FwJ2F8QBXzH7U4-Goo8eJnxjsbS-xVSUo5qKeK8GCsk/edit?usp=sharing" target="_blanc">広告を一時停止・再開する方法</a><br />
        </p>

        <hr />

        <!-- <h2 class="is-size-4 has-text-weight-bold">紹介プログラムのFAQ</h2>
        <p>
          <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/referral-method-ja" target="_blanc">紹介方法を教えてください</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/referral-fee-ja" target="_blanc">どのくらいの報酬になるのですか？</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/referral-account-ja" target="_blanc">報酬の振込先口座の登録、変更方法</a><br />

          <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/referral-transfer-fee-ja" target="_blanc">振込手数料などについて</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/referral-transfer-error-ja" target="_blanc">口座情報不備による振込エラー時の対応方法</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/referral-optout-mail-ja" target="_blanc">紹介数や報酬額をお知らせするメールの配信停止方法</a><br />
          <br />
          ※<a href="https://r.ilb.net/referral-faq-ja" target="_blanc">紹介プログラムのFAQ一覧を見る</a><br />
        </p> -->

        <hr />

        <h2 class="is-size-4 has-text-weight-bold">GoogleビジネスプロフィールのFAQ</h2>
        <p>
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/1uNy6taO2r_tIEkrEtnF9CHJGB4WiNlU-Y6sEu1mijNA/edit?usp=sharing" target="_blanc">新規登録する方法</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/18mi0VUpTBAzpada4Z40buBbIKrCSU5-56a6wi24OxPk/edit?usp=sharing" target="_blanc">オーナー確認する方法</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/1PI9F5ovMYiOUhBTG8L11wj6ZypilnH7wgoLje70jBZs/edit?usp=sharing" target="_blanc">Googleビジネスプロフィール設定マニュアル</a><br />

          <br />
          ※<a href="https://drive.google.com/drive/folders/1ZMboi5-m1qqJS4vxUXWGI1Zo7L36Dodd" target="_blanc">GoogleビジネスプロフィールのFAQ一覧を見る </a><br />
        </p>

        <hr />

        <h2 class="is-size-4 has-text-weight-bold">支払方法のFAQ</h2>
        <p>
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/1UZEBkAVvxwKwzadNmjfOCDjnBASu6pT1p0XAxYkokKE/edit?usp=sharing" target="_blanc">支払いで使用しているクレジットカードを変更する方法</a><br />
          <span class="icon-question-circle"></span>&nbsp;<a href="https://docs.google.com/document/d/14wlAqhTtsD6nHofhj_m_kcIo1FHYcCmruOyklAzBvn0/edit?usp=sharing" target="_blanc">決済の停止（サービスの解約）方法</a><br />
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import 'bulma/css/bulma.css';
import Menu from '@/components/Menu.vue';

export default {
  mixins: [Menu],
  components: {
    Menu,
  },
  data() {
    return {
      doc: {},
    };
  },
  methods: {
    getPageId(value) {
      this.doc = value;
    },
  },
};
</script>

<style>
input[readonly='readonly'] {
  background-color: #c0c0c0;
  color: #666;
}
</style>
