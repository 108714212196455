import Vue from 'vue/dist/vue.esm'
import Router from 'vue-router'
import Vuex from 'vuex'
import Main from '@/components/Main'
import Login from '@/components/Login'
import MailChange from '@/components/MailAddressChange'
import PasswordReset from '@/components/PasswordReset'
import PasswordChange from '@/components/PasswordChange'
import Faq from '@/components/Faq'
import SignUp from '@/components/SignUp'
import SignUpConfirm from '@/components/SignUpConfirm'
import AdsData from '@/components/AdsData'
import Flyer from '@/components/Flyer'
import Report from '@/components/Report'
import ReportGoogle from '@/components/ReportGoogle'
// import AdsReport from '@/components/AdsReport'
import Referral from '@/components/Referral'
import PageConversion from '@/components/PageConversion'
import AdsConversion from '@/components/AdsConversion'
import ContactList from '@/components/ContactList'
import Payment from '@/components/Payment';
import CardChange from '@/components/CardChange';
import AgentRequest from '@/components/AgentRequest';
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import VueSweetalert2 from 'vue-sweetalert2'
import VeeValidate, { Validator } from 'vee-validate'
import 'sweetalert2/dist/sweetalert2.min.css'
import ja from 'vee-validate/dist/locale/ja'
import Config from './config.js'
import mixInFunction from './mixInFunction.js';
import VueGoodTablePlugin from 'vue-good-table';
// import the styles
import 'vue-good-table/dist/vue-good-table.css'
Vue.mixin(mixInFunction);

Vue.config.productionTip = false
Vue.use(Router)
Vue.use(Vuex)
Vue.use(Buefy)
Vue.use(VueSweetalert2)
Vue.use(VeeValidate)
Validator.localize('ja', ja)
Vue.use(VueGoodTablePlugin);

const App = {
  template: `
    <div>
      <Main
      ref="main" />
    </div>
    `,
  components: { Main },
  // 保存前のページ移動を制限する
  beforeRouteLeave (to, from, next) {
    if((from.path === '/' && to.path !== '/')
      && (from.path === '/' && to.path !== '/ads_data') && (from.path === '/main' && to.path !== '/ads_data')
    ) {
      this.$swal({
        title: '変更内容を保存しましたか？',
        text: 'ページを移動すると、未保存の内容は破棄されます。',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ページを移動する',
        cancelButtonText: 'キャンセル'
      }).then(result => {
        if (result.value) {
          next()
        } else {
          next(false)
          // メニュー表示を変更しない(Main.vue mixin Menu.vueのactiveMenuメソッド)
          this.$refs.main.activeMenu(['/'], from.path, to.path)
          }
      })
      return
    }
    next()
  }
}

let app
Config.FIREBASE_AUTH.onAuthStateChanged(
  p => {
    if (app) {
      // vueオブジェクトが存在した場合、storeのuserを呼び出し、pを入れている
      app.$store.commit('user', p)
    } else {
      const router = new Router({
        mode: 'history',
        routes: [
          { path: '/login', component: Login },
          { path: '/signup', component: SignUp },
          { path: '/confirm', component: SignUpConfirm },
          { path: '/mailchange', component: MailChange },
          { path: '/passwordreset', component: PasswordReset },
          { path: '/passwordchange', component: PasswordChange },
          { path: '/ads_data', component: AdsData },
          { path: '/flyer', component: Flyer },
          { path: '/faq', component: Faq },
          { path: '/report-previous', component: Report },
          { path: '/report', component: ReportGoogle },
          { path: '/referral', component: Referral },
          { path: '/page_conversion', component: PageConversion },
          { path: '/ads_conversion', component: AdsConversion },
          { path: '/contact_list', component: ContactList },
          { path: '/payment', component: Payment },
          { path: '/card', component: CardChange },
          { path: '/agent-request', component: AgentRequest},
          { path: '/new-page', component: App, name: 'newPage' },
          { path: '/:id/payment', component: Payment },
          { path: '/:id/card', component: CardChange },
          { path: '/:id/ads_daily_budget_change', component: App, name: 'addailybudgetchange' },
          { path: '/:id/ads_delivery_range_change', component: App, name: 'addeliveryrangechange' },
          { path: '/:id/ads_target_change', component: App, name: 'adstargetchange' },
          { path: '/:id/ads_stop', component: App, name: 'adstoporrestart' },
          { path: '/:id/ads_data', component: AdsData },
          { path: '/:id/ads_budget_change', component: App, name: 'adbudgetchange' },
          { path: '/:id/plan_upgrade', component: App, name: 'checkout' },
          { path: '/:id/ads_apply', component: App, name: 'adcheckout' },
          { path: '/:id/ads_cancel', component: App, name: 'adcancel' },
          { path: '/:id/page_revision', component: App, name: 'agentrequest' },
          { path: '/:id/flyer', component: Flyer },
          { path: '/:id/report', component: ReportGoogle },
          { path: '/:id/report-previous', component: Report },
          // { path: '/:id/ads_report', component: Report },
          { path: '/:id/card_change', component: App, name: 'cardchange' },
          { path: '/:id/plan_downgrade', component: App, name: 'plandowngrade' },
          // { path: '/:id/plan_cancel', component: App, name: 'plandowngrade'},
          { path: '/:id/plan_restore', component: App, name: 'restorestandard' },
          { path: '/:id/contact_list', component: ContactList },
          { path: '/:id/page_conversion', component: PageConversion },
          { path: '/:id/ads_conversion', component: AdsConversion },
          { path: '/:id', component: App },
          { path: '/main', component: App }, // 初回遷移用
          { path: '/', component: App },
          { path: '/*', component: { template: '<div>NotFound</div>' } },
        ],
        scrollBehavior: (to, from, savedPosition) => {
          if (savedPosition) {
            return savedPosition;
          }
          if (to.hash) {
            return { selector: to.hash };
          }
          return { x: 0, y: 0 };
        },
      });
      router.beforeEach(
        async (to, from, next) => {
          if (Config.FIREBASE_AUTH.currentUser) {
            // loginページ指定の場合はサインアウト
            if (to.path === '/login') await Config.FIREBASE_AUTH.signOut()
            // TOPページ指定の場合にはそのまま表示
            next()
          } else {
            if (from.path !== '/signup' && to.path === '/confirm') {
              next()
              // next({ path: 'signup', query: { redirect: to.path } })
            } else if (
              to.path !== '/signup' &&
              to.path !== '/login' &&
              to.path !== '/passwordreset' &&
              to.path !== '/signup-agent' &&
              to.path !== '/confirm'
            ) {
              next({ path: 'login', query: { redirect: to.path } })
            } else {
              next()
            }
          }
        }
      )
      app = new Vue(
        {
          el: '#app',

          store: new Vuex.Store(
            {
              state: {
                user: p,
                page_id: '',
                referral_data:'',
                admin_data:'',
              },
              mutations: {
                user: function (state, payload) {
                  state.user = payload
                },
                page_id: function (state, payload) {
                  state.page_id = payload
                },
                referral_data: function (state, payload) {
                  state.referral_data = payload
                },
                admin_data: function (state, payload) {
                  state.admin_data = payload
                },
              }
            }
          ),
          router,
          template: `<router-view />`
        }
      )
    }
  }
)
