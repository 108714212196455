export default{
  methods: {
    menuMove(field, key, type, flag = false) {
      let src = []
      let dest = []

      switch (type) {
        // 上に上げる
        case 'up':
          // 一番上なら動作しない（メニューにも出さない）
          if(key !== 0) {
            // 一番上に
            if(flag) {
              // 配列の入れ替え
              this.pageData[field].unshift(this.pageData[field][key]);
              this.pageData[field].splice(key + 1, 1);
            } else {
              // 一つ上に
              src = this.pageData[field][key]
              dest = this.pageData[field][key-1]
              this.pageData[field][key - 1] = src
              this.pageData[field][key] = dest
            }
          }
          break;

        // 下に下げる
        case 'down':
          // 一番下なら動作しない（メニューにも出さない）
          if(key !== (this.pageData[field].length - 1)) {
            // 一番下に
            if(flag) {
              // 配列の入れ替え
              this.pageData[field].push(this.pageData[field][key]);
              this.pageData[field].splice(key, 1);
            } else {
              // 一つ下に
              src = this.pageData[field][key]
              dest = this.pageData[field][key + 1]
              this.pageData[field][key + 1] = src
              this.pageData[field][key] = dest
            }
          }
          break;
        default:
          break;
      }
      // console.log(this.pageData[field])
      this.updateFlg = Math.random()
    },
    // テキスト入力のtrim処理
    trim(firstProp, secondProp=null, thirdProp=null) {
      if(secondProp === null) {
        this.$set(this.pageData, firstProp, this.pageData[firstProp].trim())
      } else if(thirdProp === null) {
        this.$set(this.pageData[firstProp], secondProp, this.pageData[firstProp][secondProp].trim())
      } else {
        this.$set(this.pageData[firstProp][secondProp], thirdProp, this.pageData[firstProp][secondProp][thirdProp].trim())
      }
    },
    // 禁止文字削除
    // "‘｀´’[]｛｝＿±÷＝≠∞＼⇒⇔→←↑↓♪〆※〒¥♯！!
    // 冒頭、末尾のスペース削除
    // 連続使用禁止：、、。。,,..
    removeForbiddenString(firstProp, secondProp = null, thirdProp = null) {
      if (secondProp === null) {
        this.$set(this.pageData, firstProp, this.pageData[firstProp]
          .replace(/["'‘｀´’[\]｛｝＿±÷＝≠∞＼⇒⇔→←↑↓♪〆※〒¥♯！!]/g, '')
          .replace(/,,/g, ',')
          .replace(/\.\./g, '.')
          .replace(/、、/g, '、')
          .replace(/。。/g, '。')
        )
        console.log('first',this.pageData[firstProp])
      } else if (thirdProp === null) {
        this.$set(this.pageData[firstProp], secondProp, this.pageData[firstProp][secondProp]
          .replace(/["'‘｀´’[\]｛｝＿±÷＝≠∞＼⇒⇔→←↑↓♪〆※〒¥♯！!]/g, '')
          .replace(/,,/g, ',')
          .replace(/\.\./g, '.')
          .replace(/、、/g, '、')
          .replace(/。。/g, '。')
        )
        console.log('second',this.pageData[firstProp][secondProp])
      } else {
        this.$set(this.pageData[firstProp][secondProp], thirdProp, this.pageData[firstProp][secondProp][thirdProp]
          .replace(/["'‘｀´’[\]｛｝＿±÷＝≠∞＼⇒⇔→←↑↓♪〆※〒¥♯！!]/g, '')
          .replace(/,,/g, ',')
          .replace(/\.\./g, '.')
          .replace(/、、/g, '、')
          .replace(/。。/g, '。')
        )
        console.log('third',this.pageData[firstProp][secondProp][thirdProp])
      }
    },
    // 禁止文字削除（business_name, page_keyword, service_keywords用）
    // "‘｀´’[]｛｝＿±÷＝≠∞＼⇒⇔→←↑↓♪〆※〒¥♯！!()（）
    // 冒頭、末尾のスペース削除
    // 連続使用禁止：、、。。,,..
    removeForbiddenStringOther(firstProp, secondProp = null, thirdProp = null) {
      if (secondProp === null) {
        this.$set(this.pageData, firstProp, this.pageData[firstProp]
          .replace(/["'‘｀´’[\]｛｝＿±÷＝≠∞＼⇒⇔→←↑↓♪〆※〒¥♯！!()（）]/g, '')
          .replace(/,,/g, ',')
          .replace(/\.\./g, '.')
          .replace(/、、/g, '、')
          .replace(/。。/g, '。')
        )
        console.log('first',this.pageData[firstProp])
      } else if (thirdProp === null) {
        this.$set(this.pageData[firstProp], secondProp, this.pageData[firstProp][secondProp]
          .replace(/["'‘｀´’[\]｛｝＿±÷＝≠∞＼⇒⇔→←↑↓♪〆※〒¥♯！!()（）]/g, '')
          .replace(/,,/g, ',')
          .replace(/\.\./g, '.')
          .replace(/、、/g, '、')
          .replace(/。。/g, '。')
        )
        console.log('second',this.pageData[firstProp][secondProp])
      } else {
        this.$set(this.pageData[firstProp][secondProp], thirdProp, this.pageData[firstProp][secondProp][thirdProp]
          .replace(/["'‘｀´’[\]｛｝＿±÷＝≠∞＼⇒⇔→←↑↓♪〆※〒¥♯！!()（）]/g, '')
          .replace(/,,/g, ',')
          .replace(/\.\./g, '.')
          .replace(/、、/g, '、')
          .replace(/。。/g, '。')
        )
        console.log('third',this.pageData[firstProp][secondProp][thirdProp])
      }
    },
    // 文字数カウント用
    strCount (str) {
      let len = 0;
      for (let i = 0; i < str.length; i++) {
        (str[i].match(/[ -~]/)) ? len += 1 : len += 2;
        }
      return len;
    }
  }
}
