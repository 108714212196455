<template>
  <section id="report-board" class="report-board">
    <div class="datepicker-title mt-4 has-text-centered has-text-weight-bold">
      <div>年月を選択してください</div>
    </div>
    <b-field>
      <b-datepicker
        v-model="targetDate"
        type="month"
        icon="calendar-today"
        :mobile-native=false
        :date-formatter="(d) => { return d.toLocaleDateString('ja-JP', {year: 'numeric', month: 'long'}) }"
        :minDate="new Date('2024-09-30')"
        :maxDate="new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())"
        :month-names='["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]'
        @input="getAllReportDatas()">
      </b-datepicker>
    </b-field>

    <div v-if="this.reportDatas['update']" class="last-update">更新日時：{{ this.reportDatas['update'] }}</div>
    <div class="has-text-centered">レポートへの最新の数値反映は、最大で72時間後になります。</div>

    <div v-if="this.isLoading">
      <b-loading :active.sync="this.isLoading"></b-loading>
    </div>
    <div v-else>
      <div v-if="reportDatas['ga'] && reportDatas['ga']['daily'].length && Object.keys(reportDatas['ga']['total']).length && Object.keys(reportDatas['ga']['channel']).length">
        <GoogleTotalCard :pageData="pageData" :reportDatas="reportDatas"/>
      </div>
      <div v-else class="content has-text-grey has-text-centered no-data">
        <p><b-icon icon="file-chart" size="is-large"></b-icon></p>
        <p>閲覧数が「0」のため、レポートで表示するデータがありません。</p>

        <div class="mt-5 has-text-centered">
          <a href="#"
            class="external-link"
            @click="$router.push('/report-previous').catch(() => {})"
          >
            2024年10月以前のレポートを見る
            <span class="icon-external-link"></span>
          </a>
        </div>

      </div>
    </div>

    <!-- コンポーネント Modal -->
    <Modal
      class="change-plan qrcode-download"
      v-if="modal"
      @close="modalClose"
      @checkout="changeModal('checkout')"
      :pagedata="pageData"
      :adsdata="reportDatas['ads_data']"
      :chargesdata="reportDatas['charges_data']"
      :type="type" />
  </section>
</template>

<script>

import GoogleTotalCard from './modules/GoogleTotalCard'
import Modal from '../Modal.vue'

import Util from './lib/util'

import firebase from '@/firebase/firebaseInit'
import GaReport from './lib/gaReport'
import GoogleReport from './lib/googleReport'

export default {
  props: {
    pageData: Object
  },
  components: {
    GoogleTotalCard,
    Modal,
  },
  data() {
    return {
      modal: false,
      type:'',
      isLoading: false,
      targetDate: new Date(),
      reportDatas: this.clearReportDatas,
    }
  },
  watch: {
    pageData: {
      immediate: true,
      async handler() {
        if (this.pageData['page_id']) {
          // 全てのレポートデータを取得
          this.getAllReportDatas()

          // レポート、集客数を増やす方法のボックスを閉じる
          if (document.getElementById("ads_total_table_check")) {
            document.getElementById("ads_total_table_check").checked = false
          }
        }
      }
    }
  },
  methods: {
    getValue(obj, key) {
      return Util.getValue(obj, key)
    },
    openModal(type) {
      // 自分で作るパターン
      this.modal = true
      this.type = type
    },
    modalClose() {
      console.log('AdsReportBoard.vue modalClose()')
      this.modal = false
      this.$emit('close')
    },
    changeModal(type) {
      // いったんモーダル閉じる
      this.modal = true
      this.type = type
    },
    async getAllReportDatas() {
      this.clearReportDatas()

      if (!this.pageData['page_id']) {
        return
      }

      if (!this.targetDate) {
        this.targetDate = new Date()
      }

      this.isLoading = true

      this.reportDatas['targetDate'] = this.targetDate

      // Promise.allで全てデータを一度に取得（Promise.allを多重化しているのは、取得結果を各広告ごとに分けるため）
      const results = await Promise.all([
        firebase.firestore().collection('admin_data').doc('charges').get(),                               // results[0]
        firebase.firestore().collection('admin_data').doc('service_name').get(),                          // results[1]
        firebase.firestore().collection('ads_data').doc(this.pageData['page_id']).get(),                  // results[2]
        firebase.firestore().collection('charges_data').doc(this.pageData['page_id']).get(),              // results[3]
        firebase.firestore().collection('report_data').doc(this.pageData['page_id']).get(),               // results[4]
        Promise.all(GaReport.getReportData('report_data/' + this.pageData['page_id'], this.targetDate)),  // results[5]
        Promise.all(GoogleReport.getReportData('report_data/' + this.pageData['page_id'], this.targetDate)),  // results[6]
      ])

      this.reportDatas['admin_data']['charges'] = results[0].data()
      this.reportDatas['admin_data']['service_name'] = results[1].data()
      this.reportDatas['ads_data'] = results[2].data()
      this.reportDatas['charges_data'] = results[3].data()
      this.reportDatas['update'] = results[4].exists ? Util.dateFormatter(results[4].data()['last_date'].toDate(), 'YYYY/MM/DD hh:mm') : ''
      this.reportDatas['ga'] = GaReport.createReportData(results[5][0], results[5][1], this.targetDate)
      if (this.reportDatas['ads_data'] && this.reportDatas['ads_data']['google'] && this.reportDatas['charges_data'] && this.reportDatas['charges_data']['google']) {
        this.reportDatas['google'] = GoogleReport.createReportData(results[6][0], results[6][1], results[6][2], this.reportDatas['ads_data']['google'], this.reportDatas['admin_data'], this.reportDatas['charges_data']['google'], this.targetDate)
      }

      this.isLoading = false
    },
    clearReportDatas() {
      this.reportDatas = {
        admin_data: {},
        ads_data: {},
        update: '',
        ga: {daily: [], total: {}, channel: {}},
        google: {daily: [], total: {}},
      }
    },
  },
}
</script>

<style>
.datepicker-title {
  margin-bottom: 10px;
}

.datepicker input {
  width: auto;
}
.datepicker input[readonly="readonly"]{
  background-color: white;
  color: #363636;
}

.report-board {
  font-size: 16px;
}

.report-board .head-line{
  padding: 0px 5px;
  max-width: 720px;
  margin: 0px auto;
  font-size: 16px;
}

.report-board .last-update {
  width: 300px;
  margin: 5px auto;
  text-align: center;
}

.report-board .no-data {
  margin-top: 30px;
}

.report-board table {
  min-width: 240px;
  margin: 0px auto;
  width: max-content;
}

.report-board .field {
  margin: 0px auto;
  width: max-content;
  text-align: center;
}

.report-board .tooltip {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .report-board nav li {
    font-size: 12px;
  }
}
</style>
