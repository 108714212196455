<template>
<section class="section container referral">

  <div class="columns">
    <div class="column is-3">
      <Menu @getdoc='getPageId' />
    </div>
    <div class="column">

        <h1 class="is-size-4 has-text-weight-bold py-1">紹介プログラム</h1>
        <p class="pb-1">
          インデックスページ作成サービスでは、紹介プログラムを導入しています。
        </p>
        <p>
          <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/refarral_program.png" alt="紹介プログラム" class="mt-4">
        </p>
        <p class="">
          紹介プログラムの詳細、紹介方法、報酬についてご確認いただけます。<br>
          下記より表示したい項目を選択してください。
        </p>
        <hr>

        <div>

          <b-tabs>

            <b-tab-item label="プログラムの参加">

              <b-notification aria-close-label="Close notification">
                １．直接紹介<br>
                御社のご紹介先がインデックスページの有料プランをご利用になった場合、１件につき有料プランの利用金額の２５％をお受取りいただけます。<br>
                <br>
                ２．間接紹介<br>
                御社のご紹介先が紹介した方がインデックスページの有料プランをご利用になった場合、１件につき有料プランの利用金額の５％をお受取りいただけます。<br>
                <br>
                ※直接紹介、間接紹介ともに有料プランの利用が続く限り継続的に報酬が発生します。<br>
                <br>
                紹介プログラムの詳細については、利用規約をご確認ください。<br>
                ※自分で自分を紹介することは規約違反となりますのでご注意ください。<br>
                <a href="https://r.ilb.net/tos-ja" target="_blank">利用規約を見る&nbsp;<span class="icon-external-link"></span></a>
              </b-notification>

              <h2 class="title is-4 is-size-5-mobile" id="">紹介プログラムへの参加について</h2>

              <p class="pb-1">
                「参加しない」を選択すると、報酬は発生しなくなります。<br>
                ※紹介数や報酬額をお知らせするメールの配信も停止されます。
              </p>

              <div class="field is-size-3 pt-2">
                <b-radio v-model="referral_doc['declined']"
                    name="紹介プログラムへの参加"
                    native-value="false"
                    @input="onBlur"
                >
                    参加する
                </b-radio>
                <b-radio v-model="referral_doc['declined']"
                    name="紹介プログラムへの参加"
                    native-value="true"
                    @input="onBlur"
                >
                    参加しない
                </b-radio>
              </div>

              <div v-if="referral_doc['declined'] === `true`">
                <p class="has-text-danger">報酬の振込先口座情報を入力するためには、「参加する」を選択ください。</p>
              </div>

              <div v-if="referral_doc['declined'] === `false`">
                <hr>

                <h2 class="title is-4 is-size-5-mobile" id="">１．適格請求書発行事業者情報</h2>
                <p>
                  適格請求書発⾏事業者登録番号を取得済みの場合は、下記に登録をお願いします。
                </p>
                <p class="pb-1">
                  国税庁の<a href="https://www.invoice-kohyo.nta.go.jp/" target="_BLANK">適格請求書発行事業者公表サイト</a>より登録番号及び登録事業者名を確認し、正しい情報を入力してください。
                </p>

                <b-field
                  :type="errors.has('invoice_registration_number') ? 'is-danger': ''"
                  :message="errors.has('invoice_registration_number') ? invoice_registration_number_not_alpha_num_message : ''">
                  <template slot="label">
                    &nbsp;登録番号
                  </template>
                  <div class="control">
                    <b-input
                      name="invoice_registration_number"
                      v-model="referral_doc['invoice_registration_number']"
                      data-vv-as="T1234567890123"
                      id="invoice_registration_number"
                      maxlength="14"
                      v-validate="'alpha_num|max:14'"
                      :disabled="disabled"
                    >
                    </b-input>
                    <p>法人の場合：T+法人番号</p>
                    <p>法人以外の場合：T＋数字13桁</p>
                  </div>
                </b-field>

                <b-field
                  :type="errors.has('invoice_registration_name') ? 'is-danger': ''"
                  :message="errors.has('invoice_registration_name') ? errors.first('invoice_registration_name') : ''">
                  <template slot="label">
                    &nbsp;登録事業者名
                  </template>
                  <div class="control">
                    <b-input
                      name="invoice_registration_name"
                      v-model="referral_doc['invoice_registration_name']"
                      data-vv-as="登録事業者名"
                      id="invoice_registration_name"
                      :disabled="disabled"
                    >
                    </b-input>
                  </div>
                </b-field>

                <h2 class="title is-4 is-size-5-mobile" id="">２．報酬の振込先口座情報</h2>
                <p class="pb-1">
                  報酬の振込先口座（自身に帰属する金融機関口座）を入力し、保存ボタンを押してください。
                </p>

                <b-field
                  :type="errors.has('bank_code') ? 'is-danger': ''"
                  :message="errors.has('bank_code') ? bank_code_not_half_number_message : ''">
                  <template slot="label">
                    <span class="tag is-danger">必須</span>&nbsp;金融機関コード（半角数字4ケタ）
                  </template>
                  <div class="control">
                    <b-input
                      name="bank_code"
                      v-model="referral_doc['bank_code']"
                      v-validate="'required|numeric|digits:4'"
                      data-vv-as="金融機関コード"
                      id="bank_code"
                      maxlength="4"
                      :disabled="disabled"
                    >
                    </b-input>
                  </div>
                </b-field>

                <b-field
                  :type="errors.has('branch_code') ? 'is-danger': ''"
                  :message="errors.has('branch_code') ? branch_code_not_half_number_message : ''">
                  <template slot="label">
                    <span class="tag is-danger">必須</span>&nbsp;支店番号（半角数字3ケタ）
                  </template>
                  <div class="control">
                    <b-input
                      name="branch_code"
                      v-model="referral_doc['branch_code']"
                      v-validate="'required|numeric|digits:3'"
                      data-vv-as="支店番号"
                      id="branch_code"
                      maxlength="3"
                      :disabled="disabled"
                    >
                    </b-input>
                  </div>
                </b-field>

                <div class="field">
                  <label class="label has-text-weight-bold" for="account_type"><span class="tag is-danger">必須</span>&nbsp;預金種目</label>

                  <b-radio v-model="referral_doc['account_type']"
                      name="預金種目"
                      native-value="1"
                      :disabled="disabled"
                      >
                      普通
                  </b-radio>
                  <b-radio v-model="referral_doc['account_type']"
                      name="預金種目"
                      native-value="2"
                      :disabled="disabled"
                      >
                      当座
                  </b-radio>
                  <b-radio v-model="referral_doc['account_type']"
                      name="預金種目"
                      native-value="4"
                      :disabled="disabled"
                      >
                      貯蓄
                  </b-radio>
                </div>

                <b-field
                  :type="errors.has('account_number') ? 'is-danger': ''"
                  :message="errors.has('account_number') ? account_number_not_half_number_message : ''">
                  <template slot="label">
                    <span class="tag is-danger">必須</span>&nbsp;口座番号（半角数字7ケタ）<br>
                    <span class="memo">※口座番号が7桁に満たない場合は、先頭に「0」を入力して、全部で7桁となるように入力してください。</span>
                  </template>
                  <div class="control">
                    <b-input
                      name="account_number"
                      v-model="referral_doc['account_number']"
                      v-validate="'required|numeric|digits:7'"
                      data-vv-as="口座番号"
                      id="account_number"
                      maxlength="7"
                      :disabled="disabled"
                    >
                    </b-input>
                  </div>
                </b-field>

                <b-field
                  :type="errors.has('account_name') ? 'is-danger': ''"
                  :message="errors.has('account_name') ? account_name_not_fullwidth_kana_message : ''">
                  <template slot="label">
                    <span class="tag is-danger">必須</span>&nbsp;口座名義（全角カナ）<br>
                    <span class="memo">
                    ※通帳やオンラインバンキングに記載されている通り、正確な口座名義を入力してください。<br>
                    例）<br>
                    ヤマダ&nbsp;&nbsp;ハナコ<br>
                    カ）アイエルビー<br>
                    シヤ）アイエスビー</span>
                  </template>
                  <div class="control">
                    <b-input
                      name="account_name"
                      v-model="referral_doc['account_name']"
                      v-validate="{regex:regex}"
                      data-vv-as="口座名義"
                      id="account_name"
                      maxlength="30"
                      :disabled="disabled"
                    >
                    </b-input>
                  </div>
                </b-field>

                入力後は必ず「保存する」ボタンをクリックしてください。<br>

                <span>
                    <div v-if="displayButtons">
                      <div class="control">
                        <button
                          class="button is-success"
                          id="saveForm"
                          :disabled="errors.any() || !isFormCompleted"
                          @click="submit_referral( referral_doc )"
                        >
                          保存する
                        </button>
                      </div>
                    </div>
                    <div v-else>
                      <loading-for-submit />
                    </div>
                </span>
              </div>

            </b-tab-item>

            <b-tab-item label="紹介方法、報酬例">

              <h2 class="title is-4 is-size-5-mobile" id="">１．紹介方法</h2>
              <p class="pb-1">
                １）御社の紹介URLを知り合いにシェアして新規登録していただく<br>
              </p>

              <b-message type="is-info is-light">
                御社の紹介URL<br>
                <p class="mb-2">
                  <a :href="referralUrl" target="_blank">
                  {{ referralUrl }}
                  </a>
                </p>
                <p>
                  <button
                    class="button is-success"
                    id="copyReferralUrl"
                    @click="copyReferralUrl(referralUrl)"
                  >
                  紹介URLをコピーする
                  </button>
                </p>
              </b-message>

              <p class="pb-3">
                <span class="has-text-weight-bold">御社のご紹介URLの活用例</span><br>
                ①知り合いに送る<br>
                ②ブログやSNSで紹介する
              </p>

              <p>
                下記の文章をコピーして、ご活用ください。
              </p>

              <b-message type="is-info is-light">
                ウェブページとチラシが完全無料で作成できるサービスです。<br>
                <a :href="referralUrl" target="_blank">
                  {{ referralUrl }}
                </a>
              </b-message>

              <br>

              <p class="pb-1">
                ２）紹介チラシのQRコードから新規登録していただく<br>
                下記より御社専用の紹介チラシがダウンロードできます。印刷してお使いください。
              </p>

              <a :href="referralFlyerUrl" target="_blank" class="button is-success">紹介チラシをダウンロードする</a><br>

              <p class="pt-3">
                <span class="has-text-weight-bold">チラシの使用例</span><br>
                ①知り合いに渡す<br>
                ②取引先、業者に渡す<br>
                ③店舗や事業所に置いておく
              </p>

              <hr>

              <h2 class="title is-4 is-size-5-mobile" id="">２．報酬の計算方法</h2>
              <p class="pb-3">
                報酬の計算は、毎月末日に実施します。<br>
              </p>
              <p class="pb-1">
                １．直接紹介の報酬計算（有料プラン件数が10件の場合）<br>
                10件&nbsp;☓&nbsp;825円<br>
                <span class="has-text-weight-bold pt_1">直接紹介の紹介料：8,250円（税込）</span><br>
                <br>
                ２．間接紹介の報酬計算（有料プラン件数が30件の場合）<br>
                30件&nbsp;☓&nbsp;165円<br>
                <span class="has-text-weight-bold pt_1">間接紹介の紹介料：4,950円（税込）</span>
              </p>
              <p class="has-text-weight-bold has-text-danger pt-2">
                報酬の合計金額：13,200円（税込）<br>
              </p>
            </b-tab-item>

          </b-tabs>
        </div>
      </div>
    </div>

</section>
</template>

<script>
import 'bulma/css/bulma.css'
import Menu from '@/components/Menu.vue'
import LoadingForSubmit from '@/components/LoadingForSubmit'
import Config from '../config.js'
import Parser from 'ua-parser-js'

export default {
  components: {
    LoadingForSubmit,
    Menu,
  },
  data () {
    return {
      uid : this.$store.state.user.uid,
      referral_doc: [],
      draft_referral:{
        account_number: '',
        account_name: '',
        account_type: '1',
        bank_code: '',
        branch_code: '',
        declined: 'false',
        deleted: 'false',
        referral_code: '',
        invoice_registration_number:'',
        invoice_registration_name:'',
      },
      regex: /^([ァ-ン]|ー|（|）|\u3000)+$/,
      displayButtons: true,
      bank_code_not_half_number_message: '半角数字4ケタで入力してください',
      branch_code_not_half_number_message: '半角数字3ケタで入力してください',
      account_number_not_half_number_message: '半角数字7ケタで入力してください',
      account_name_not_fullwidth_kana_message:'全角カナで入力してください',
      invoice_registration_number_not_alpha_num_message: '半角英数字14桁で入力してください',
      referralUrl: 'https://admin-jp.ilb.net/signup?code=' + this.$store.state.user.uid,
      referralFlyerUrl: 'https://app.ilb.net/flyer/lmm/jp/referral.html?id=' + this.$store.state.user.uid,
      msg: '',
    }
  },
  computed: {
    isFormCompleted: function() {
      if(this.referral_doc) {
        if (
          !this.referral_doc.account_number ||
          !this.referral_doc.account_name ||
          !this.referral_doc.account_type ||
          !this.referral_doc.bank_code ||
          !this.referral_doc.branch_code
        ) {
          return false
        }
        return true
      }

      // 初期入力時のバリデーション
      if (
        !this.draft_referral.account_number ||
        !this.draft_referral.account_name ||
        !this.draft_referral.account_type ||
        !this.draft_referral.bank_code ||
        !this.draft_referral.branch_code
      ) {
        return false
      }
      return true
    },
    disabled () {
      return this.referral_doc['declined'] === `true`
    }
  },
  methods: {
    getPageId (value) {
      this.doc = value
    },
    async listenReferralData () {
      let doc = await Config.REFERRAL_COLLECTION.doc(this.$store.state.user.uid).get()
      this.referral_doc = doc.data() ? doc.data() : this.draft_referral
    },
    async submit_referral (p) {
      const isValid = await this.$validator.validate()
        if(!isValid) return

      // ボタンの非アクティブ
      this.displayButtons = false

      // page_id兼ドキュメントID発行
      let draft_referral = p ? p : this.draft_referral
      let listenReferralData = this
      let swal = this.$swal

      Config.REFERRAL_COLLECTION.doc(this.$store.state.user.uid)
      .set(draft_referral, { merge: true })
      .then(function () {
          swal.fire({
            title: '',
            text: '保存しました',
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(function(val) {
            if (val) {
              listenReferralData.listenReferralData()
            }
        })
      }).catch(function (e) {
        swal('error', '保存エラー：' + e, 'error')
      })
        // 送信ボタンアクティブに戻す
        this.displayButtons = true
        return
      },
    onBlur: function() {
      // 参加しない場合は振込先口座情報を空にする
      if(this.referral_doc['declined'] === `true`) {
        this.referral_doc['account_number'] = ''
        this.referral_doc['account_name'] = ''
        this.referral_doc['account_type'] = ''
        this.referral_doc['bank_code'] = ''
        this.referral_doc['branch_code'] = ''
        this.referral_doc['invoice_registration_number'] = ''
        this.referral_doc['invoice_registration_name'] = ''
      }
      this.autosave(this.referral_doc)
    },
    autosave(p){  // 新規作成以外は自動保存
      let listenReferralData = this
      Config.REFERRAL_COLLECTION
        .doc(this.$store.state.user.uid)
        .set(p, { merge: true })
        .then(function(){
          listenReferralData.listenReferralData()
        })
    },
    // 紹介URLをコピーする（navigator.clipboard利用）
    async copyReferralUrl (url) {
      let ua = Parser(window.navigator.userAgent);

      if (ua.device.type === "mobile" || ua.device.type === "tablet") {
        if (ua.browser.name === "Safari" || ua.browser.name === "Mobile Safari") {
          await navigator.clipboard.writeText(url);
          this.$swal('success', 'コピーしました', 'success')
        } else{
          await navigator.clipboard.writeText(url);
          this.$swal('success', 'コピーしました', 'success')
        }
      } else {
        if (ua.browser.name === "Chrome" || ua.browser.name === "Edge") {
          console.log(ua.browser.name)
          await navigator.clipboard.writeText(url);
          this.$swal('success', 'コピーしました', 'success')
        } else if (/Safari/.test(ua.browser.name)) {
          await navigator.clipboard.writeText(url);
          this.$swal('success', 'コピーしました', 'success')
        }
      }
    },
  },
  watch: {
    referral_doc: function (val) {
      const words = ['ァ','ィ','ゥ','ェ','ォ','ヵ','ヶ','ャ','ュ','ョ','ッ']
      const replace_words = ['ア','イ','ウ','エ','オ','カ','ケ','ヤ','ユ','ヨ','ツ']
      let i = words.length
      let input = val['account_name'] ? val['account_name'] : ''

      while (i--) {
        if (input.indexOf(words[i]) !== -1) {
          // フラグ変数 match を使わず、ここでマッチした時の処理もやっておく
          input = input.replace(words[i], replace_words[i])
        }
      }
      this.referral_doc['account_name'] = input
    }
  },
  created () {
    this.unsubscribe_referral = this.listenReferralData()
  },
  beforeDestroy () {
    if(typeof this.unsubscribe_referral == 'function') {
      this.unsubscribe_referral()
    }
  },
}
</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
.memo{
  font-weight: normal;
}
.referral img {
  width: 640px;
}
</style>
