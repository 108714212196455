<template>
  <form @submit.prevent="validateForm">
    <section class="row payment-form">
      <h5>
        配信地域の変更
      </h5>
      <p class="has-text-centered service-title">広告の着地先URL</p>
      <p class="has-text-centered service-title">
        {{ pagedata['business_name'] }} {{ pagedata['branch_name'] }}<br>
        <a :href="'https://jp.ilb.net/' + pagedata['page_id'] + '/'" target="_blank">
          https://jp.ilb.net/{{ pagedata['page_id'] }}/
        </a>
      </p>
      <br>
      <p class="is-size-7">ページの住所を中心に、どこまでの範囲で広告を配信するかを設定できる項目です。</p>
      <p class="is-size-7">できるだけ、事業所の住所から近い範囲に広告配信することをオススメします。広告が表示されず、クリックされないなどの場合には、少しつづ配信地域を拡大してください。</p>
      <br>

      <div class="box-container">
        <p><b>現在の配信地域（半径）：<span style="color:#ff0000;">{{ delivery_range === 0 ? '日本全域' : delivery_range +
    'km' }}</span></b></p>
        <p><b>希望の配信地域（半径）</b></p>
        <p class="is-size-7">半角数字で入力してください</p>

        <b-field grouped>
          <b-input size="is-medium" placeholder="" v-model="deliveryRange" name="希望の配信地域（半径）" v-validate="'required|numeric|min_value:3|max_value:500'" :disabled="disabled" class="delivery-range"
          >
          </b-input>
          <p class="service-price">
            km
          </p>
        </b-field>
        <b-field>
          <b-checkbox v-model="delivery_range_all">
            日本全域
          </b-checkbox>
        </b-field>
        <span class="help has-text-danger">配信地域（半径）の設定は3〜500（km）の範囲内で入力してください。さらに広い範囲で設定したい場合は、「日本全域」にチェックを入れてください。</span>
        <!-- <span class="help is-danger" v-show="errors.has('希望の配信地域（半径）')">{{ errors.first('希望の配信地域（半径）') }}</span> -->
      </div>
      <div class="my-2">
        <a href="https://r.ilb.net/ad-area-check-ja" target="_BLANK">半径距離を地図で確認する</a>
      </div>

      <div class="col s12 place-order-button-block">
        <b-button v-if='success === false' class="btn col s12 #e91e63 pink" type="is-info" @click="validateForm" :disabled="buttonDisabled">
          変更内容を確認する
        </b-button>
        <b-button v-if="success === 'loading'" loading>Loading</b-button>
        <b-button v-if='success === true' class="btn col s12 #e91e63 pink" @click="modalClose">閉じる</b-button>
        <b-loading :is-full-page="isFullPage" :active.sync="isLoading"></b-loading>
      </div>
    </section>
  </form>
</template>

<script>
import Config from '../../config.js'
import moment from 'moment'

export default {
  props: ['pagedata', 'adsdata', 'chargesdata'],
  computed: {
    delivery_range() {
      if (this.serviceName) {
        return this.adsdata[this.serviceName].delivery_range;
      }
      return ''
    },
    delivery_range_all: {
      // getter 関数
      get: function () {
        return this.deliveryRange === null ? true : false
      },
      // setter 関数
      set: function (newValue) {
        if (newValue) {
          this.disabled = true
          this.deliveryRange = null
        } else {
          this.disabled = false
          this.deliveryRange = 0
        }
      }
    },
    buttonDisabled() {
      // サービスネーム未選択
      if (!this.serviceName) {
        return true
      }
      // 日本全域チェックなしの時は通常のバリデーション
      if (!this.delivery_range_all) {
        if (this.errors.any()) {
          return true
        }
        // もしくは値の変更なしのとき
        if (parseInt(this.delivery_range) === parseInt(this.deliveryRange)) {
          return true
        }
      } else {
        // 日本全域チェックありの場合には変更なしの時のみ
        if (this.delivery_range === 0) {
          return true
        }
      }
      return false
    }
  },
  watch: {
    serviceName: function (val) {
      if (val) {
        if (this.delivery_range_all) {
          this.disabled = true
          this.deliveryRange = null
        } else {
          this.disabled = false
        }
      }
    }
  },
  data() {
    return {
      deliveryRange: 0,
      serviceName: 'google',
      success: false,
      isLoading: false,
      isFullPage: true,
      disabled: false,
    }
  },
  async mounted() {
    this.$validator.validateAll();
  },
  methods: {
    // 変更するボタン
    async buttonPressed() {
      // loading用
      this.success = 'loading'
      this.isLoading = true

      // 変更後deliveryRange 変更前delivery_range
      let delivery_range = this.deliveryRange !== null ? this.deliveryRange : 0

      // swalの中身取得
      let confirmSwalParam = this.getSwalParam();
      let resultSwalParam = this.getSwalParam({
        title: '変更を承りました',
        confirmButtonText: '管理画面に戻る',
        icon: 'success',
        showCancelButton: false
      }, '変更には１時間程度かかります<br><br>');

      // １）確認用のswal
      let result = await this.$swal(confirmSwalParam);
      // キャンセルおよびモーダル以外をクリックされたら一つ前の画面に戻る
      if (!result.isConfirmed) {
        this.success = false
        this.isLoading = false
        return
      }

      try {
        // ２）sendmail_targetの追加
        let data = {
          template_id: 'AD_DELIVERY_RANGE_CHANGE_SUCCESS',
          email_auth: this.pagedata.email || this.$store.state.user.email,
          url: Config.DESTINATION_URL + '/' + this.$store.state.page_id + '/',
          admin_url: Config.MANAGE_URL,
          page_id: this.$store.state.page_id,
          service_name: this.$store.state.admin_data.service_name[this.serviceName].name,
          delivery_range: delivery_range,
          business_name: this.pagedata.business_name,
          branch_name: this.pagedata.branch_name || '',
          category: this.pagedata.category || '',
          page_keyword:this.pagedata.page_keyword || '',
          status: 'sending',
          phone: this.pagedata.phone,
          staff_name: this.pagedata.staff_name,
          email: this.pagedata.email,
          date: moment().format("YYYY-MM-DD HH:MM:SS"),
          name: this.$store.state.admin_data.service_name[this.serviceName].name,
          short_name: this.$store.state.admin_data.service_name[this.serviceName].short_name,
          system_name: this.$store.state.admin_data.service_name[this.serviceName].system_name,
          country_code: this.$store.state.admin_data.service_name.url.country_code,
          tld: this.$store.state.admin_data.service_name.url.tld,
        }
        await Config.SENDMAIL_TARGET_COLLECTION.add(data);

        // ３）変更するなら、ads_dataの更新
        await Config.ADS_COLLECTION.doc(this.$store.state.page_id)
          .set({
            [this.serviceName]: {
              delivery_range: Number(delivery_range)
            }
          }, { merge: true })

        // 結果用のswal
        await this.$swal(resultSwalParam)
        // modal close
        await this.$emit('modalclose')
      } catch (e) {
        await this.$swal({
          title: '変更が失敗しました',
          text: '管理者に連絡してください:' + e.message,
          confirmButtonText: '管理画面に戻る',
          icon: 'error',
        })
        await this.$emit('modalclose')
      }
      this.success = true
      this.isLoading = false
    },
    getSwalParam(array = {}, text = null) {
      let delivery_range_display = this.delivery_range_all
        ? '日本全域'
        : this.deliveryRange
          ? this.deliveryRange + 'km'
          : '変更なし'

      let swalParam = {
        title: '変更内容',
        html: (text || '')
          + this.$store.state.admin_data.service_name[this.serviceName].name
          + '<br><br>配信地域：'
          + delivery_range_display,
        confirmButtonText: '変更する',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'キャンセル'
      }

      // 引数としてあれば上書き
      if (array.title) {
        Object.keys(array).map(key => {
          swalParam[key] = array[key] // あれば上書き
        })
      }
      return swalParam
    },
    modalClose() {
      this.$emit('modalclose')
    },
    validateForm() {
      if (this.delivery_range_all) {
        this.buttonPressed();
        return;
      } else {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.buttonPressed();
            return;
          }
        });
      }
    },
  },
}
</script>

<style scoped>
.service-title {
  margin-bottom: 6px;
}

.service-type {
  margin-bottom: 8px;
}

.service-price {
  margin-top: 8px;
}

.payment-form .comment {
  font-size: 0.8rem;
  margin-bottom: 10px;

}

.payment-form .error {
  color: red;

}

.payment-form {
  max-width: 400px;
  margin: 20px auto;
  border: 2px solid #ececec;
  padding: 12px;
  margin-bottom: 8px;
}

.payment-form h5 {
  margin: 5px;
  padding: 0px;
  text-align: center;
  font-size: 1.2rem;
}

.payment-form h6 {
  margin-bottom: 5px;
  padding: 0px;
  text-align: center;
  font-size: 1.5rem;
}

.card-element {
  margin-top: 10px;
}

.place-order-button-block {
  margin: 5px 0;
  text-align: center;
}

button {
  background-color: #23D160;
  color: white;
  border-radius: 4px;
  font-size: 20px;
  /* font-weight: 600; */
  border: 0;
  box-shadow: none;
  padding: 8px 24px;
}

.box-container {
  margin-top: 10px;
}

.delivery-range {
  width: 80%;
}
</style>
