<template>
  <section class="row payment-form">
    <h6><b> ページを公開する（月額利用料の決済） </b></h6>
    <h6>{{ admindata.service_name.page.name }}</h6>
    <h6>月額{{ amount.toLocaleString() }}円（税込）</h6>
    <div class="has-text-centered">
      <p class="help">10%対象(税抜)：{{ tax_excluded_price.toLocaleString() }}円</p>
      <p class="help">10%対象消費税：{{ tax.toLocaleString() }}円</p>
      <!-- <p>
        <a href="https://r.ilb.net/ip-plan-ja" target="_blank">
          有料プランと無料プランの違い<b-icon icon="launch" size="is-small"></b-icon></a>
      </p> -->
    </div>
    <br />
    <div class="has-text-centered">
      <p>{{ pagedata['business_name'] }} {{ pagedata['branch_name'] }}</p>
      <a :href="'https://jp.ilb.net/' + pagedata['page_id']" target="_blank"> https://jp.ilb.net/{{ pagedata['page_id'] }} </a>
    </div>

    <p class="help py-3">Visa、Mastercard、JCB、American Express、Diners Clubがご利用いただけます。</p>

    <b-field label="カード番号（ハイフンなし）">
      <div class="col s12 card-element">
        <div class="error">{{ stripeValidationErrorCardnumber }}</div>
        <div id="card-number-element" class="input-value"></div>
      </div>
    </b-field>

    <b-field label="有効期限">
      <div class="col s6 card-element">
        <div class="error">{{ stripeValidationErrorExpired }}</div>
        <div id="card-expiry-element"></div>
      </div>
    </b-field>
    <div class="comment">カードに記載されている通り入力してください</div>

    <b-field label="セキュリティーコード（CVC）">
      <div class="col s6 card-element">
        <div id="card-cvc-element"></div>
      </div>
    </b-field>
    <div class="comment">セキュリティコードとは、クレジットカードの裏面に記載されている3桁の数字です。</div>
    <div class="comment">AMEXの場合は、カード表面右上にある4桁の数字です。</div>

    <p class="py-3 has-text-centered">
      <a href="https://docs.google.com/document/d/1dDwiiCIn9qtttsSwraAFYzHQCZGsuHQhupXCSYfCF4g/edit" target="_blank" rel="noopener">利用規約を見る<b-icon icon="launch" size="is-small"></b-icon></a>
    </p>

    <div class="col s12 place-order-button-block">
      <b-button v-if="success === false" class="btn col s12 #e91e63 pink" type="is-success has-text-weight-bold" @click="placeOrderButtonPressed('page')"> 利用規約に同意の上、決済する </b-button>
      <b-button v-if="success === 'loading'" loading>Loading</b-button>
      <b-button v-if="success === true" class="btn col s12 #e91e63 pink" @click="modalClose">閉じる</b-button>
      <b-loading :is-full-page="isFullPage" :active.sync="isLoading"></b-loading>
    </div>
    <p class="help has-text-centered mt-0">1ヶ月間ごとの自動更新となります</p>
    <br />
    <p class="help has-text-centered mt-0">登録番号：T6010001124948</p>
    <p class="help has-text-centered mt-0">株式会社ILB</p>
    <p class="mt-2 has-text-centered">
      <a href="#" @click="modalClose">管理画面に戻る</a>
    </p>
  </section>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import Config from '../../config.js';

export default {
  props: ['pagedata', 'salesdata', 'admindata'],
  data() {
    return {
      stripe: null,
      cardNumberElement: null,
      cardExpiryElement: null,
      cardCVCElement: null,
      stripeValidationErrorCardnumber: '',
      stripeValidationErrorExpired: '',
      amount: Config.AMOUNT,
      success: false,
      isLoading: false,
      isFullPage: true,
    };
  },
  async mounted() {
    this.stripe = await loadStripe(Config.PUBLIC_KEY);

    this.createAndMountFormElements();
  },
  computed: {
    tax() {
      return Math.floor(this.amount - this.amount / (1 + Config.TAX_RATE));
    },
    tax_excluded_price() {
      return this.amount - this.tax;
    },
  },
  methods: {
    // stripe UIを使って決済画面elementの作成
    createAndMountFormElements() {
      const elementStyles = {
        base: {
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: '#CFD7E0',
          },
          ':-webkit-autofill': {
            color: '#e39f48',
          },
        },
        invalid: {
          color: '#E25950',

          '::placeholder': {
            color: 'red',
          },
        },
      };

      const elementClasses = {
        focus: 'focused',
        empty: 'empty',
        invalid: 'invalid',
      };

      const elements = this.stripe.elements();
      this.cardNumberElement = elements.create('cardNumber', {
        style: elementStyles,
        class: elementClasses,
      });
      this.cardNumberElement.mount('#card-number-element');
      this.cardExpiryElement = elements.create('cardExpiry', {
        style: elementStyles,
        class: elementClasses,
      });
      this.cardExpiryElement.mount('#card-expiry-element');
      this.cardCvcElement = elements.create('cardCvc', {
        style: elementStyles,
        class: elementClasses,
        placeholder: '123',
      });
      this.cardCvcElement.mount('#card-cvc-element');
      this.cardNumberElement.on('change', this.setValidationError);
      this.cardExpiryElement.on('change', this.setValidationError);
      this.cardCvcElement.on('change', this.setValidationError);
    },
    // 入力バリデーション
    setValidationError(event) {
      this.stripeValidationErrorCardnumber = event.error && event.error.code === 'invalid_number' ? event.error.message : '';

      if (event.error) {
        switch (event.error.code) {
          case 'invalid_expiry_year_past':
            this.stripeValidationErrorExpired = event.error.message;
            break;
          case 'invalid_expiry_month_past':
            this.stripeValidationErrorExpired = event.error.message;
            break;
          default:
            this.stripeValidationErrorExpired = '';
            break;
        }
      } else {
        this.stripeValidationErrorExpired = '';
      }
    },
    // 決済するボタン
    async placeOrderButtonPressed(service_name) {
      this.success = 'loading';
      this.isLoading = true;
      this.stripe.createToken(this.cardNumberElement).then((result) => {
        if (result.error) {
          this.stripeValidationError = result.error.message;
          this.success = false;
          this.isLoading = false;
        } else {
          let stripeObject = {
            amount: this.amount,
            source: result.token,
          };
          this.chargeStripe(service_name, stripeObject);
        }
      });
    },
    // stripe決済処理
    async chargeStripe(service_name, stripeObject) {
      const checkOut = Config.FIREBASE_FUNCTIONS.httpsCallable('instantPayment');

      let checkout_data = {};
      checkout_data['stripe'] = stripeObject;
      checkout_data['page_data'] = this.pagedata;
      checkout_data['service_name'] = service_name;

      let agentRequestBtnClick = false;
      let swal = this.$swal;

      try {
        let result = await checkOut(checkout_data);
        if (result.data.status) {
          // salesdata.page_revisionがtrue以外は代理入力表示させる
          if (!this.salesdata || !this.salesdata.page_revision) {
            await swal
              .mixin({
                onOpen: () => {
                  document.getElementById('agent-request-button').addEventListener('click', function () {
                    agentRequestBtnClick = true;
                    swal.clickConfirm();
                  }),
                    document.getElementById('close-modal').addEventListener('click', function () {
                      swal.clickConfirm();
                    });
                },
              })
              .fire({
                title: '決済が完了し、ページが公開されました',
                html:
                  this.$store.state.admin_data.service_name[service_name].name +
                  '<br>月額' +
                  this.amount.toLocaleString() +
                  '円（税込）<br><br>' +
                  '10%対象(税抜)：' +
                  this.tax_excluded_price.toLocaleString() +
                  '円<br>' +
                  '10％対象消費税：' +
                  this.tax.toLocaleString() +
                  '円（税込）<br><br>' +
                  '<div class="block">' +
                  '<div style="margin-bottom: 10px;padding: 10px;background-color:#effaf5;color: #257953;">' +
                  '<b><div class="tag is-danger">' +
                  '<span class="">無料</span></div>' +
                  '<span class="has-text-grey-dark">&nbsp;ページの代理入力を依頼する</span></b>' +
                  '<div class="help" style="margin-bottom: 10px;">問合せ数を増やすため、ページのデータ入力・修正を無料で代行します。</div>' +
                  '<button id="agent-request-button" type="button" class="button button is-outlined is-fullwidth is-link">' +
                  '<span>詳細を見る</span></button>' +
                  '</div>' +
                  '</div>' +
                  '<br>' +
                  '<p class="">' +
                  '<a id="close-modal" href="#">管理画面に戻る</a>' +
                  '</p>',
                showConfirmButton: false,
                allowOutsideClick: false,
              });
          } else {
            await swal.fire({
              icon: 'success',
              title: '決済が完了し、ページが公開されました',
              html: this.$store.state.admin_data.service_name[service_name].name + '<br>月額' + this.amount.toLocaleString() + '円（税込）',
              confirmButtonText: '管理画面に戻る',
            });
          }

          this.success = await true;
          this.isLoading = await false;

          // 代理入力を依頼する
          if (agentRequestBtnClick) {
            await new Promise((s) => setTimeout(s, 500));
            this.changeModal('changeModalAgentRequest');
            return;
          }
          // modal閉じる
          this.modalClose();
        } else {
          await swal({
            title: '決済がエラーとなりました',
            html: result.data.error.code + '<br>' + result.data.error.message + '<br>',
            confirmButtonText: '管理画面に戻る',
            icon: 'error',
          });
          await this.$emit('modalclose');
          this.success = await true;
          this.isLoading = await false;
        }
      } catch (e) {
        await swal({
          title: '決済がエラーとなりました',
          text: '管理者に連絡してください:' + e.message,
          confirmButtonText: '管理画面に戻る',
          icon: 'error',
        });
        await this.$emit('modalclose');
        this.success = await true;
        this.isLoading = await false;
      }
    },
    modalClose() {
      this.$emit('modalclose');
    },
    changeModal(type) {
      this.$emit(type);
    },
  },
};
</script>

<style scoped>
.payment-form .comment {
  font-size: 0.8rem;
  margin-bottom: 10px;
}
.payment-form .error {
  color: red;
}
.payment-form {
  max-width: 400px;
  margin: 20px auto;
  border: 2px solid #ececec;
  padding: 12px;
  margin-bottom: 8px;
}
.payment-form h5 {
  margin: 5px;
  padding: 0px;
  text-align: center;
  font-size: 1.2rem;
}
.payment-form h6 {
  margin-bottom: 5px;
  padding: 0px;
  text-align: center;
  font-size: 1.5rem;
}
.card-element {
  margin-top: 10px;
}
#card-number-element,
#card-expiry-element,
#card-cvc-element {
  background: white;
  padding: 5px;
  border: 2px solid #ececec;
  height: 30px;
}

.place-order-button-block {
  margin: 5px 0;
  text-align: center;
}

button {
  background-color: #23d160;
  color: white;
  border-radius: 4px;
  font-size: 20px;
  /* font-weight: 600; */
  border: 0;
  box-shadow: none;
  padding: 8px 24px;
}
.notification.is-link.is-light {
  padding: 1ex;
}
.notification.is-danger.is-light {
  padding: 1ex;
}
</style>
