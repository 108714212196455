// 文字列カウント
function charaCountAndLimitStr(str) {
  let len = 0;
  for (let i = 0; i < str.length; i++) {
    str[i].match(/[ -~]/)
      ? (len += 1)
      : !str[i].match(/^[ｦ-ﾟ]*$/)
        ? (len += 2)
        : (len += 1);
    if (len > 25) {
      return false;
    }
  }
  return true;
}

/**
 * 新規ページ作成モーダル
 * @param {*} instance
 */
async function selectid(instance = null) {
  let swal;
  let doc = instance.draft;
  if (instance) {
    swal = instance.$swal;
  } else {
    swal = this.$swal;
  }

  // 新規作成
  /**
   * 会社名、支店名、電話番号の入力
   */
  let swalParamInput = {
    title: 'ページを作成する',
    html:
      '<b>事業所名（店舗名、会社名など）</b><br>半角25文字（全角12文字）以内' +
      '<input id="swal-input1" class="swal2-input" maxlength="25">' +
      '<br>' +
      '<b>支店名（任意）</b><br>' +
      '事業所が支店や支社の場合のみ入力してください' +
      '<input id="swal-input2" class="swal2-input" maxlength="20">' +
      '<br>' +
      '<b>電話番号（ページ掲載用）</b>' +
      '<br>' +
      '顧客からの問合せを受ける際の電話番号です' +
      '<br>' +
      '半角（ハイフンあり）で入力してください' +
      '<br>' +
      '<input id="swal-input3" class="swal2-input" maxlength="15">' +
      '<br>' +
      '<b>電話番号（当社からの連絡用）任意</b>' +
      '<br>' +
      '当社（ILB）から連絡を受ける際の電話番号です' +
      '<br>' +
      '半角（ハイフンあり）で入力してください' +
      '<br>' +
      '<input id="swal-input4" class="swal2-input" maxlength="15">' +
      '<br>',
    showCancelButton: true,
    confirmButtonText: '次へ',
    cancelButtonText: 'キャンセル',
    allowOutsideClick: false,
    onAfterClose: function () {
      location.reload();
    },
    onOpen: function () {
      document.getElementById('swal-input1').focus();
    },
    preConfirm: () => {
      let errorMessages = [];
      const business_name = document.getElementById('swal-input1').value;
      const branch_name = document.getElementById('swal-input2').value;
      const phone = document.getElementById('swal-input3').value;
      // const phone_contact = document.getElementById('swal-input4').value;
      if (!business_name) {
        errorMessages.push('事業所名を入力してください');
      }
      if (!charaCountAndLimitStr(business_name)) {
        errorMessages.push('事業所名は半角25文字（全角12文字）以内で入力してください');
      }
      if (branch_name !== null && branch_name.length > 20) {
        errorMessages.push('支店名は半角、全角に関わらず20文字以内で入力してください');
      }

      if (!phone) {
        errorMessages.push('電話番号を入力してください');
      }
      if (!/^0[1-9][0-9]{0,4}-[0-9]{1,5}-[0-9]{1,5}$/.test(phone)) {
        errorMessages.push('正しい電話番号の形式で入力してください');
      }

      // if (phone_contact && !/^0[1-9][0-9]{0,4}-[0-9]{1,5}-[0-9]{1,5}$/.test(phone_contact)) {
      //   errorMessages.push('正しい電話番号（当社からの連絡用）の形式で入力してください');
      // }

      if (errorMessages.length) {
        swal.showValidationMessage(errorMessages.join('<br>'));
      }

      return [document.getElementById('swal-input1').value, document.getElementById('swal-input2').value, document.getElementById('swal-input3').value, document.getElementById('swal-input4').value];
    },
  };

  /**
   *
   * 最終確認
   * @param {*} businessName
   * @param {*} phoneNumber
   * @returns
   */
  let swalParamConfirm = (result) => {
      return {
        title: '最終確認',
        html:
          '以下の内容でページを作成を開始しますか？' +
          '<br>' +
          '<br>' +
          '事業所名： ' +
          result.value[0] +
          (result.value[1] ? ' ' + result.value[1] : '') +
          '<br>' +
          '電話番号（ページ掲載用）： ' +
          result.value[2] +
          '<br>' +
          '電話番号（当社からの連絡用）： ' +
          result.value[3] +
          '<br><br>',
        // + "<div>"
        // + "<input type='checkbox' id='agent_request' name='agent_request' checked>"
        // + "<label for='agent_request'>&nbsp;代理入力を依頼する（無料）</label>"
        // + "</div>"
        // + "<br>"
        // + "代理入力とは、インターネット上で確認できる御社の公式サイト、SNS、ブログなどの情報を元に、当社でページの入力を代行するものです。"
        // + "<br>"
        // + "*代理入力が不要な場合はチェックを外してください。"
        // + "<br><br>"
        // + "<div id='agent-request-element'></div>"
        confirmButtonText: 'ページを作成',
        showCancelButton: true,
        allowOutsideClick: false,
        cancelButtonText: 'キャンセル',
      };
  }

  /**
   * 本処理
   */
  try {
    let result = await swal.fire(swalParamInput)
    let confirm

    // 最終確認
    // let agentRequestBtnClick = true;
    let agentRequestBtnClick = false;
    if (result.value) {
      confirm = await swal.mixin({
        // onOpen: () => {
        //   // チェックに対してイベントを設定
        //   document.getElementById("agent_request")
        //     .addEventListener('change', function () {
        //       agentRequestBtnClick = this.checked
        //   })
        // }
      }).fire(swalParamConfirm(result))
    }

    // DB登録
    if (confirm.value) {
      doc["business_name"] = result.value[0];
      doc["branch_name"] = result.value[1];
      doc["phone"] = result.value[2];
      doc["phone_contact"] = result.value[3];

      await instance.submit(
        doc,
        agentRequestBtnClick
        );
    } else {
      location.reload();
    }

  } catch (err) {
    console.log(err);
  }
}

export { selectid };
