<template>
  <div>
    <b-notification aria-close-label="Close notification">
      Googleビジネスプロフィールのクチコミ、評価を引用して表示する機能です。各クチコミは評価数値を基準に、表示・非表示を選択できます。<br>
      <br>
      <div v-if="pageData['place_id']">
        クチコミ件数：{{ pageData['review_number'] }}<br>
        クチコミの平均評価：{{ pageData['review_rating'] }}<br>
        <a :href="'https://search.google.com/local/reviews?placeid=' + pageData['place_id']" target="_blank">
          クチコミの一覧を見る&nbsp;<span class="icon-external-link"></span>
        </a>
      </div>
      <div v-else>
        Googleビジネスプロフィールのクチコミ、評価の表示設定ができます。<br>
        <br>
        クチコミは、「必須項目」のタブから、御社のGoogleビジネスプロフィール（Place ID）を選択することで表示できます。<br>
      </div>
    </b-notification>
    
    <div v-if="pageData['review_display']">
      <div v-if="pageData['plan'] === `standard`">
        <div v-if="pageData['place_id']" class="field pb-3 notification is-danger is-light">
          <p class="pb-1">
            表示するクチコミは30日間ごとに自動更新します。下記ボタンをクリックすることで、1日1回手動でも更新可能です。クチコミは、最大5件（新着順）まで表示が可能です。
          </p>
          <button @click="requestReviewData()" class="button is-primary">クチコミの更新を依頼する</button>
        </div>

        <div class="mb-2">
          <p>※依頼した翌日の朝8時にクチコミを更新します。</p>
        </div>
      </div>

      <div class="field pt-3" v-if="pageData['place_id']">
        <label class="label has-text-weight-bold">{{ title.review_display_rating }}</label>

        <div class="block">
          <b-radio v-model="pageData['review_display_rating']"
              name="review_display_rating"
              native-value="1">
              全て表示
          </b-radio>
          <b-radio v-model="pageData['review_display_rating']"
              name="review_display_rating"
              native-value="2">
              評価2以上を表示
          </b-radio>
          <b-radio v-model="pageData['review_display_rating']"
              name="review_display_rating"
              native-value="3">
              評価3以上を表示
          </b-radio>
          <b-radio v-model="pageData['review_display_rating']"
              name="review_display_rating"
              native-value="4"
              >
              評価4以上を表示
          </b-radio>
          <b-radio v-model="pageData['review_display_rating']"
              name="review_display_rating"
              native-value="5"
              >
              評価5のみを表示
          </b-radio>
          <p>クチコミの表示基準を変更した場合<span class="has-text-danger">「変更内容を保存する」ボタン</span>を押してください。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'bulma/css/bulma.css'
import moment from 'moment'
import Config from '../../config.js'

export default {
  props: ['doc','title'],
  computed: {
    pageData: {
      get() {
        return this.doc;
      },
      set(data) {
        this.$emit('doc', data);
      }
    },
  },
  methods: {
    async requestReviewData() {
      // 保存前のfirestore上のpage_dataを取得
      try {
        await Config.COLLECTION
          .doc(this.pageData['page_id'])
          .update({ review_date: '', date : moment(moment().unix(),'X').format() })

        await this.$swal.fire({
          html: 'クチコミの更新を受け付けました<br>口コミ更新が完了しましたら、メールをお送りします。',
          icon: 'success',
        })
      } catch(e) {
        await this.$swal('error', '保存エラー：' + e, 'error')
        return
      }
    },
  },
  data () {
    return {
      review_display_rating : this.doc.review_display_rating
    }
  },
  async updated() {
    const page_doc = await Config.COLLECTION.doc(this.pageData.page_id).get()
    const review_display_rating = await page_doc.data().review_display_rating
    // firestoreも更新されていたら、this.pageData.review_display_ratingで置き換える
    if (this.pageData.review_display_rating === review_display_rating) {
      this.review_display_rating = this.pageData.review_display_rating
    }
  },  
}
</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
</style>
