<template>
  <section class="how-to-attract-customers">
    <div id="conversion" class="is-size-4 has-text-weight-bold">インデックスページで問合せ数（CV数）を増やす方法</div>

    <iframe width="560" height="315" style="width: 560px; height: 315px" class="mt-4" src="https://www.youtube.com/embed/Yj1aOLao0No" title="【動画】インデックスページでCV数を増やす方法" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

    <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/page-conversion/conversion.jpg" class="mt-4" />

    <div class="mt-4">
      インデックスページの問合せ数（CV数）は、<br />
      <span class="has-text-weight-bold closing-rate-font">閲覧数（PV数）× 問合せ率（CV率）</span>で計算できます。<br />
      <div class="mt-4">CV数を増やすためには、PV数を増やして、CV率を高める必要があります。</div>
    </div>

    <div class="mt-4">
      <div class="has-text-weight-bold">＜目次＞</div>
      <div><a href="#page-view">１．PV数を増やす方法</a></div>
      <ul>
        <li class="ml-4"><a href="#gbp">１）Googleビジネスプロフィールにインデックスページのリンクを追加する</a></li>
        <li class="ml-4"><a href="#flyer">２）QRコード付きのチラシを配布する</a></li>
        <li class="ml-4"><a href="#ads">３）ネット広告を利用する</a></li>
        <li class="ml-4"><a href="#page-view-other">４）その他、PV数を増やす方法</a></li>
      </ul>
      <div class="mt-4"><a href="#conversion-rate">２．CV率を高める方法</a></div>
      <ul>
        <li class="ml-4"><a href="#offer">１）魅力的なオファーの掲載</a></li>
        <li class="ml-4"><a href="#line">２）LINEでの問合せを追加、特典の掲載</a></li>
        <li class="ml-4"><a href="#case-voc">３）提供サービスの結果事例やお客様の声の掲載</a></li>
        <li class="ml-4"><a href="#conversion-rate-other">４）その他、CV率を高める方法</a></li>
      </ul>
    </div>

    <div id="access_count" class="mt-6 notification is-info is-light">
      <div id="page-view" class="is-size-4 has-text-weight-bold access-count-font">１．PV数を増やす方法</div>
    </div>

    <div class="access-count-details-content">
      <!-- １）Googleビジネスプロフィールにインデックスページのリンクを追加する -->
      <div id="gbp" class="is-size-6 has-text-weight-bold mt-4">
        １）Googleビジネスプロフィールにインデックスページのリンクを追加する
      </div>

      <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/page-conversion/gbp-link.jpg" class="mt-4" />

      <div class="mt-4">
        Googleビジネスプロフィールの管理画面にある「ウェブサイト」という項目に、インデックスページのURLを入力することでPV数を増やすことができます。
      </div>

      <div class="mt-4">
        <a href="https://r.ilb.net/gmb-setting-ja" target="_blank">
          Googleビジネスプロフィール設定マニュアルを見る
          <span class="icon-external-link"></span>
        </a>
      </div>

      <div v-if="!pageData['place_id']" class="mt-4">
        <div class="mt-1">
          <a href="https://r.ilb.net/gbp-register-ja" target="_blank">
            Googleビジネスプロフィールに新規登録する方法
            <span class="icon-external-link"></span>
          </a>
        </div>
      </div>

      <hr />

      <!-- ２）QRコード付きのチラシを配布する -->
      <div id="flyer" class="is-size-6 has-text-weight-bold">
        ２）QRコード付きのチラシを配布する
      </div>

      <div class="mt-1">
        インデックスページのQRコードが入ったチラシを配布して、PV数を増やせます。
      </div>

      <div class="mt-5">
        ＜宣伝用チラシの作成イメージ＞
      </div>

      <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/page-conversion/flyer-service-offer.jpg" class="mt-1" />

      <div class="mt-4">
        ■ インデックスページのQRコードが入った、ポスティングなどにお使いいただける「サービス宣伝用チラシ」と「オファー宣伝用チラシ」は、下記のボタンをクリックして作成できます。
      </div>

      <div class="mt-5">
        <div>
          <a :href="'http://app.ilb.net/flyer/jp/page_service.html?id=' + pageData['page_id']" target="_blank" class="button is-link is-outlined is-fullwidth">サービス宣伝用チラシを作成する</a>
        </div>

        <div class="mt-5">
          <a :href="'http://app.ilb.net/flyer/jp/page_offer.html?id=' + pageData['page_id']" target="_blank" class="button is-link is-outlined is-fullwidth">オファー宣伝用チラシを作成する</a>
        </div>

        <div class="mt-5">
          <a href="https://r.ilb.net/ip-flyer-page-ja" target="_blank">
            宣伝用チラシの作成方法と活用方法を見る
            <span class="icon-external-link"></span>
          </a>
        </div>

      </div>

      <hr />

      <!-- ３）ネット広告を利用する -->
      <div id="ads" class="is-size-6 has-text-weight-bold mt-4">
        ３）ネット広告を利用する
      </div>

      <div class="mt-1">
        ネット広告を利用することでインデックスページのPV数を確実に増やすことができます。広告がクリックされた時だけ費用が発生するので無駄がありません。<br />
        <br />
        ローカルビジネス（地域密着の店舗・企業など）で利用するネット広告は、「Google広告」がオススメです。
      </div>

      <div class="has-text-weight-bold mt-4">
        ■ Google広告（検索）<template v-if="!getValue(adsData, 'disapproved')">：<span v-if="getValue(chargesData, 'gs.payment_date')" class="access-count-font">実施中</span><span v-else class="closing-rate-font">未実施</span></template>
      </div>

      <div class="mt-1">
        商圏内でGoogleの検索結果の画面内に表示する広告です。自分で検索を行っているニーズの高いユーザーからの閲覧数を増やせます。
      </div>

      <div class="mt-4">
        ＜Google広告（検索）の表示イメージ＞
        <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/page-conversion/gs.jpg" />
      </div>

      <div class="has-text-weight-bold mt-4">
        ■ Google広告（ディスプレイ）<template v-if="!getValue(adsData, 'disapproved')">：<span v-if="getValue(chargesData, 'gd.payment_date')" class="access-count-font">実施中</span><span v-else class="closing-rate-font">未実施</span></template>
      </div>

      <div class="mt-1">
        商圏内で検索をしていないユーザーにも、ウェブページやアプリ内に表示される広告です。検索広告ではカバーできない潜在的なニーズのあるユーザーからの閲覧数を増やせます。
      </div>

      <div class="mt-4">
        ＜Google広告（ディスプレイ）の表示イメージ＞
        <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/page-conversion/gd.jpg" />
      </div>

      <b-message v-if="!getValue(adsData, 'disapproved')" class="mt-4" type="is-info is-light">
        <span class="has-text-weight-bold">【Google広告の運用代行について】</span><br />
        インデックスページを着地先とした、Google広告の運用代行をご利用いただけます。ウェブページに掲載する内容をもとに、自動でGoogle広告の設定が完了するので手間がかかりません。
      </b-message>

      <div v-if="(!getValue(chargesData, 'gs.payment_date') || !getValue(chargesData, 'gd.payment_date')) && !getValue(adsData, 'disapproved')" class="mt-4">
        <div>
          Google広告の運用代行をご希望の場合、下記のボタンをクリックしてお申込ください。<br />
          ※1ヵ月ごとの契約のため、気軽にご利用いただけます。
        </div>

        <b-button class="mt-4 button is-success is-fullwidth has-text-weight-bold" @click="openModal('adcheckout')">
          Google広告を申込む
        </b-button>

        <div class="mt-4">
          それぞれの広告の特長と表示イメージ、推奨の広告費については下記URLよりご確認いただけます。
        </div>

        <div class="mt-1">
          <a href="https://r.ilb.net/ad-google-ja" target="_blank">
            Google広告の種類と推奨の広告費について
            <span class="icon-external-link"></span>
          </a>
        </div>
      </div>

      <hr />

      <!-- ４）その他、PV数を増やす方法 -->
      <div id="page-view-other" class="is-size-6 has-text-weight-bold mt-4">
        ４）その他、PV数を増やす方法
      </div>

      <div class="mt-4">
        <span class="is-size-6 has-text-weight-bold">① 公式サイトやポータルサイトなどにインデックスページのリンクを追加する</span><br />
        御社が管理している公式サイトやポータルサイトなどに、インデックスページのリンクを追加することでPV数を増やすことができます。
      </div>

      <div class="mt-4">
        <span class="is-size-6 has-text-weight-bold">② SNSにインデックスページのリンクを追加する</span><br />
        SNS（Facebook、Instagram、YouTube、X、アメブロなど）のプロフィールや説明文などに、インデックスページのリンクを追加することで閲覧数（PV数）を増やすことができます。
      </div>

      <div class="mt-4">
        <span class="is-size-6 has-text-weight-bold">③ SNSでインデックスページのURLを紹介する</span><br />
        下記リンクをクリックすると、インデックスページのURLを紹介できます。<br />

        <div class="mt-4">
          <span class="icon-facebook" style="color: #315096;"></span>
          <a :href="'https://www.facebook.com/share.php?u=https://jp.ilb.net/' + pageData['page_id']"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebookでシェアする
          </a>
        </div>

        <div class="mt-2">
          <span class="icon-x" style="color: #55acee;"></span>
          <a :href="'https://x.com/intent/tweet?text=' + pageData['business_name'] + (pageData['branch_name'] ? ' ' + pageData['branch_name'] : '') + 'の特長をまとめた新しいウェブページを作成しました。&url=https://jp.ilb.net/' + pageData['page_id'] + '&hashtags=' + pageData['category'] + ',' + pageData['city'] + ',' + pageData['catchphrase']"
            target="_blank"
            rel="noopener noreferrer"
          >
            Xでツイートする
          </a>
        </div>

        <div class="mt-2 mb-4">
          <span class="icon-line" style="color: #00c300;"></span>
          <a :href="'https://line.me/R/msg/text/?' + pageData['business_name'] + (pageData['branch_name'] ? ' ' + pageData['branch_name'] : '') + 'の特長をまとめた新しいウェブページを作成しました。%0D%0Ahttps://jp.ilb.net/' + pageData['page_id']"
            target="_blank"
            rel="noopener noreferrer"
          >
            LINEのタイムラインでシェアする
          </a>
        </div>
      </div>

      <div class="mt-4">
        <span class="is-size-6 has-text-weight-bold">④ メルマガやLINE公式アカウントでインデックスページのURLを紹介する</span><br />
        メルマガの登録者やLINE公式アカウントの友だちなど、御社のことを知っている方に、インデックスページのURLを紹介することでPV数を増やすことができます。
      </div>

    </div>


    <div id="closing_rate" class="mt-6 notification is-danger is-light">
      <div id="conversion-rate" class="is-size-4 has-text-weight-bold closing-rate-font">２．CV率を高める方法</div>
    </div>

    <div class="closing-rate-details-content">
      <div>
        <div class="is-size-6 has-text-weight-bold mt-4">１）魅力的なオファーの掲載</div>
        <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/page-conversion/offer.jpg" class="mt-4" />
        <div class="mt-4">
          <div>ページを見た方が予約・問合せをするきっかけとなるサービスや特典などを掲載すると効果的です。</div>
        </div>
        <div class="mt-4">
          <a href="https://r.ilb.net/ip-offer-ja"
            class="external-link"
            target="_blank"
          >
            オファーの掲載ポイントを見る
            <span class="icon-external-link"></span>
          </a>
        </div>
        <div class="mt-4 button is-link is-outlined is-fullwidth" @click="$router.push('/?active_tab=2')">オファーを入力する</div>
      </div>
    </div>
    <div>
      <hr class="mt-5"/>

      <div class="is-size-6 has-text-weight-bold mt-4">２）LINEでの問合せを追加、特典の掲載</div>

      <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/page-conversion/line-friend.jpg" class="mt-4" />

      <div class="mt-4">
        <div>LINE公式アカウントのIDを入力すると、LINEで問合せの受付ができます。また、特典を掲載することで友だちを増やせます。</div>
      </div>

      <div class="mt-4">
        <a href="https://r.ilb.net/line-offer-ja"
          class="external-link"
          target="_blank"
        >
          LINE友だち追加特典の掲載ポイントを見る
          <span class="icon-external-link"></span>
        </a>
      </div>

      <div class="mt-4">
        <div>※LINEでの問合せに対応するには、LINE公式アカウントの管理画面より「応答設定 > 応答機能」でチャットをオンにしておく必要があります。</div>
      </div>

      <div class="mt-4">
        <a href="https://www.lycbiz.com/jp/manual/OfficialAccountManager/chats-settings"
          class="external-link"
          target="_blank"
        >
          チャットを有効にする方を見る
          <span class="icon-external-link"></span>
        </a>
      </div>

      <div class="mt-4 button is-link is-outlined is-fullwidth" @click="$router.push('/?active_tab=3')">LINEを入力する</div>
    </div>
    <div>
      <hr class="mt-5" />

      <div class="is-size-6 has-text-weight-bold mt-4">３）提供サービスの結果事例やお客様の声の掲載</div>
      <div class="has-text-weight-bold mt-4">① 事例の掲載</div>

      <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/page-conversion/case.jpg" class="mt-4" />

      <div class="mt-4">
        <div>施術写真、ビフォーアフターの画像などを5件まで掲載できます。</div>
      </div>

      <div class="mt-4">
        <a href="https://r.ilb.net/case-ja"
          class="external-link"
          target="_blank"
        >
          事例の掲載ポイントを見る
          <span class="icon-external-link"></span>
        </a>
      </div>

      <div class="mt-4 button is-link is-outlined is-fullwidth" @click="$router.push('/?active_tab=7')">事例を入力する</div>
    </div>
    <div>
      <div class="has-text-weight-bold mt-4">② お客様の声の掲載</div>

      <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/page-conversion/voice.jpg" class="mt-4" />

      <div class="mt-4">
        <div>サービスを利用したお客様の感想を5件まで掲載できます。</div>
      </div>

      <div class="mt-4">
        <a href="https://r.ilb.net/voc-ja"
          class="external-link"
          target="_blank"
        >
          お客様の声の掲載ポイントを見る
          <span class="icon-external-link"></span>
        </a>
      </div>

      <div class="mt-4 button is-link is-outlined is-fullwidth" @click="$router.push('/?active_tab=8')">お客様の声を入力する</div>
    </div>
    <div>
      <hr class="mt-5"/>

      <div class="is-size-6 has-text-weight-bold mt-4">４）その他、CV率を高める方法</div>
      <div class="mt-4">
        <div>ページに掲載する情報を充実させ、改善していくことでCV率を高めることができます。</div>
      </div>

      <div class="has-text-weight-bold mt-4">① 基本情報の掲載</div>

      <div class="mt-4">
        <div>最寄り駅や御社の営業日、営業時間など基本的な情報を掲載できます。掲載している基本情報が最新の状態になっているかを確認し、修正しておく必要があります。
			</div>
      </div>

      <div class="mt-4 button is-link is-outlined is-fullwidth" @click="$router.push('/?active_tab=1')">基本情報を入力する</div>
    </div>
    <div>
      <div class="has-text-weight-bold mt-4">② Googleのクチコミの掲載</div>

      <div class="mt-4">
        <div>Googleビジネスプロフィール（Place ID)を選択することで、Googleビジネスプロフィールに投稿されたクチコミがインデックスページに掲載されます。第三者からの評価（クチコミ）を見せることで、問合せ率が高まります。</div>
      </div>

      <div v-if="!pageData['place_id']">
        <a href="https://r.ilb.net/gbp-register-ja"
          class="external-link"
          target="_blank"
        >
          Googleビジネスプロフィールに新規登録する方法
          <span class="icon-external-link"></span>
        </a>
      </div>

      <div class="mt-4 button is-link is-outlined is-fullwidth" @click="$router.push('/?active_tab=0')">Googleビジネスプロフィールを選択する</div>
    </div>
    <div>
      <div class="has-text-weight-bold mt-4">③ 提供サービスの掲載</div>

      <div class="mt-4">
        <div>御社で提供しているサービスを10件まで掲載できます。</div>
      </div>

      <div class="mt-4 button is-link is-outlined is-fullwidth" @click="$router.push('/?active_tab=4')">提供サービスを入力する</div>
    </div>
    <div>
      <div class="has-text-weight-bold mt-4">④ 特長の掲載</div>

      <div class="mt-4">
        <div>御社の特長（選ばれる理由）を5件まで掲載できます。</div>
      </div>

      <div class="mt-4">
        <a href="https://r.ilb.net/ip-feature-ja"
          class="external-link"
          target="_blank"
        >
          特長（選ばれる理由）の掲載ポイントを見る
          <span class="icon-external-link"></span>
        </a>
      </div>

      <div class="mt-4 button is-link is-outlined is-fullwidth" @click="$router.push('/?active_tab=5')">特長を入力する</div>
    </div>
    <div>
      <div class="has-text-weight-bold mt-4">⑤ スタッフ紹介の掲載</div>

      <div class="mt-4">
        <div>御社スタッフのプロフィールを掲載できます。</div>
      </div>

      <div class="mt-4 button is-link is-outlined is-fullwidth" @click="$router.push('/?active_tab=6')">スタッフ紹介を入力する</div>
    </div>
    <div>
      <div class="has-text-weight-bold mt-4">⑥ 関連サイトの掲載</div>

      <div class="mt-4">
        <div>公式サイトやSNSなど、関連サイトへのリンクを掲載できます。</div>
      </div>

      <div class="mt-4 button is-link is-outlined is-fullwidth" @click="$router.push('/?active_tab=9')">関連サイトを入力する</div>
    </div>
    <div>
      <div class="has-text-weight-bold mt-4">⑦ よくある質問の掲載</div>

      <div class="mt-4">
        <div>お客様からよく聞かれる質問を5件まで掲載できます。</div>
      </div>

      <div class="mt-4 button is-link is-outlined is-fullwidth" @click="$router.push('/?active_tab=10')">よくある質問を入力する</div>
    </div>
    <div id="conversion" class="mt-4 is-size-4 has-text-weight-bold">インデックスページで問合せ数（CV数）を増やす方法</div>

    <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/page-conversion/conversion.jpg" class="mt-4" />

    <div class="mt-4">
      インデックスページの問合せ数（CV数）は、<br />
      <span class="has-text-weight-bold closing-rate-font">閲覧数（PV数）× 問合せ率（CV率）</span>で計算できます。<br />
      <div class="mt-4">CV数を増やすためには、PV数を増やして、CV率を高める必要があります。</div>
    </div>

    <div class="mt-4">
      <div class="has-text-weight-bold">＜目次＞</div>
      <div><a href="#page-view">１．PV数を増やす方法</a></div>
      <ul>
        <li class="ml-4"><a href="#gbp">１）Googleビジネスプロフィールにインデックスページのリンクを追加する</a></li>
        <li class="ml-4"><a href="#flyer">２）QRコード付きのチラシを配布する</a></li>
        <li class="ml-4"><a href="#ads">３）ネット広告を利用する</a></li>
        <li class="ml-4"><a href="#page-view-other">４）その他、PV数を増やす方法</a></li>
      </ul>
      <div class="mt-4"><a href="#conversion-rate">２．CV率を高める方法</a></div>
      <ul>
        <li class="ml-4"><a href="#offer">１）魅力的なオファーの掲載</a></li>
        <li class="ml-4"><a href="#line">２）LINEでの問合せを追加、特典の掲載</a></li>
        <li class="ml-4"><a href="#case-voc">３）提供サービスの結果事例やお客様の声の掲載</a></li>
        <li class="ml-4"><a href="#conversion-rate-other">４）その他、CV率を高める方法</a></li>
      </ul>
    </div>

    <div v-if="pageData['plan'] === 'free' || (pageData['plan'] === 'standard' && !getValue(chargesData, 'page.payment_date'))">
      <div class="mt-4 border-danger">
        <div class="py-1 has-background-danger has-text-white has-text-centered has-text-weight-bold">
          さらなる集客アップのため<br />
          今すぐ有料プランをお試しください
        </div>

        <div class="px-4 py-4">
          <div class="has-text-weight-bold">■ 有料プランに変更するメリット</div>
          <div>・ウェブページ内の他社広告が非表示になる</div>
          <div>・オリジナルの転送用URLを設定できる</div>
          <div v-if="!getValue(adsDatas, 'disapproved')">・Google広告が利用できる</div>
          <div>・検索結果ページ内で上位に表示される</div>

          <div class="mt-4">
            <span class="has-text-weight-bold">■ 利用料金と契約期間</span><br />
            月額 3,300円（税込）<br />
            契約期間の縛りはなく、1ヶ月だけのご利用も可能です。
          </div>

          <div>
            <b-button
              v-if="pageData['plan'] === 'standard' && !getValue(chargesData, 'page.payment_date')"
              class="mt-4 button is-success is-fullwidth has-text-weight-bold"
              @click="openModal('restorestandard')"
            >
              有料プランに戻す
            </b-button>
            <b-button
              v-else
              class="mt-4 button is-success is-fullwidth has-text-weight-bold"
              @click="openModal('checkout')"
            >
              有料プランに変更する
            </b-button>
          </div>

          <div class="mt-4">
            <a href="https://r.ilb.net/ip-plan-ja"
              class="external-link"
              target="_blank"
            >
              無料プランと有料プランの違いを見る
              <span class="icon-external-link"></span>
            </a>
          </div>
        </div>
      </div>

      <hr />
    </div>
    <div v-else-if="
      (pageData['plan'] === 'standard' && getValue(chargesData, 'page.payment_date')) &&
      (!getValue(chargesData, 'gs.payment_date') || !getValue(chargesData, 'gd.payment_date')) &&
      !getValue(adsDatas, 'disapproved')
      "
    >
      <div class="mt-4 border-danger">
        <div class="py-1 has-background-danger has-text-white has-text-centered has-text-weight-bold">
          さらなる集客アップのため<br />
          今すぐGoogle広告をお試しください
        </div>

        <div class="px-4 py-4">
          <div>
            <span class="has-text-weight-bold">■ Google広告を活用するメリット</span><br />
            ・ページの閲覧数（PV数）を確実に増やすことができる<br />
            ・広告がクリックされた時だけ費用が発生するので無駄がない<br />
            ・商圏内の見込み客にだけ広告を表示できる
          </div>

          <div class="mt-4">
            <span class="has-text-weight-bold">■ 利用料金と契約期間</span><br />
            広告費1万円〜<br />
            契約期間の縛りはなく、1回だけのご利用も可能です。
          </div>

          <div class="mt-4">
            インデックスページを着地先とした、Google広告の運用代行をご利用いただけます。ウェブページに掲載する内容をもとに、自動でGoogle広告の設定が完了するので手間がかかりません。
          </div>

          <div>
            <b-button class="mt-4 button is-success is-fullwidth has-text-weight-bold" @click="openModal('adcheckout')">
              Google広告を申込む
            </b-button>
          </div>

          <div class="mt-4">
            <a href="https://r.ilb.net/ad-google-ja"
              class="external-link"
              target="_blank"
            >
              Google広告の種類と推奨の広告費について
              <span class="icon-external-link"></span>
            </a>
          </div>
        </div>
      </div>

      <hr />
    </div>

    <!-- コンポーネント Modal -->
    <Modal
      class="change-plan qrcode-download"
      v-if="modal"
      @close="modalClose"
      @checkout="changeModal('checkout')"
      :pagedata="pageData"
      :adsdata="adsData"
      :chargesdata="chargesData"
      :type="type" />
  </section>
</template>

<script>
import Util from '../lib/util'

import Modal from '../../Modal.vue'
import Config from '../../../config.js'
import {loadStripe} from '@stripe/stripe-js'

export default {
  components: { Modal },
  props: {
    pageData: Object,
    adsData: Object,
    chargesData: Object,
    itemNames: Object,
  },
  data () {
    return {
      modal: false,
      type:'',
      payment_date: '',
      last_payment_date: ''
    }
  },
  computed: {
  },
  methods: {
    getValue(obj, key) {
      return Util.getValue(obj, key)
    },
    openModal(type) {
      // 自分で作るパターン
      this.modal = true
      this.type = type
    },
    modalClose() {
      this.modal = false
      this.$emit('close')
    },
    changeModal(type) {
      // いったんモーダル閉じる
      this.modal = true
      this.type = type
    },
  },
  async mounted() {
    this.stripe = await loadStripe(Config.PUBLIC_KEY)
  },
}
</script>

<style>
.border-danger {
  border: 3px solid #ff3860;
  border-radius: 5px;
}

.border-success {
  border: 3px solid #23d160;
  border-radius: 5px;
}

.how-to-attract-customers {
  padding: 0px 5px;
  max-width: 720px;
  margin: 0px auto;
  font-size: 16px;
}

.how-to-attract-customers img {
  width: 640px;
}

.closing-rate-font {
  color: #d74f53;
}

.access-count-font {
  color: #2f61cf;
}

.item-entered {
  text-decoration: line-through;
  color: #ccc;
}

.external-link {
  color: #7957d5 !important;
  text-decoration: none !important;
}


/* 入力状況を確認する */
.closing-rate-details-content {
  margin-top: 20px;
}

.closing-rate-details-content img {
  width: 640px;
  padding: 5px;
}

.closing-rate-details-check-item img {
  border: none;
  width: 20px;
  height: 20px;
  padding: 0px;
  margin-right: 5px;
  vertical-align: text-top;
}

/* 施策の詳細を見る */
.access-count-details-content {
  margin-top: 20px;
}

.access-count-details-content img {
  width: 640px;
  padding: 5px;
}

.change-plan {
  color: #4a4a4a;
}
.change-plan a {
  color: #7957d5 !important;
  cursor: pointer !important;
  text-decoration: none !important;
}
.change-plan .field {
  margin: unset !important;
  width: unset !important;
  text-align: unset !important;
}
.change-plan .field:not(:last-child) {
  margin-bottom: 0.75rem !important;
}

.qrcode-download img {
  width: auto;
  border: unset;
  padding: unset;
}
</style>
