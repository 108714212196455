<template>
  <section class="how-to-attract-customers-with-ads">
    <div id="conversion" class="is-size-4 has-text-weight-bold">Google広告で問合せ数（CV数）を増やす方法</div>

    <!--
    <iframe width="560" height="315" style="width: 560px; height: 315px" class="mt-4" src="https://www.youtube.com/embed/Yj1aOLao0No" title="【動画】インデックスページでCV数を増やす方法" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    -->

    <div class="mt-4">
      <div class="has-text-weight-bold">＜目次＞</div>
      <div><a href="#change-cost">１．広告費の変更</a></div>
      <div><a href="#change-delivery-area">２．広告の配信地域の変更</a></div>
      <div><a href="#improved-page-content">３．広告の着地先ページの内容改善</a></div>
    </div>


    <div class="mt-6 notification is-info is-light">
      <div id="change-cost" class="is-size-4 has-text-weight-bold access-count-font">１．広告費の変更</div>
    </div>

    <div>
      <div>
        広告の費用対効果が合っており、さらに広告からの問合せ数（CV数）を増やすためには、広告費を増やしてクリック数（ページの閲覧数）を増やすことが有効です。
      </div>

      <div class="mt-4">
        現在の広告費の設定<br>
        ・Google広告（検索）： <span :class="{ 'has-text-danger' : !gs_display }" class="has-text-weight-bold">{{ gs_display ? getBudget(chargesData.gs, adsData.gs) : '未契約' }}</span><br>
        ・Google広告（ディスプレイ）： <span :class="{ 'has-text-danger' : !gd_display }" class="has-text-weight-bold">{{ gd_display ? getBudget(chargesData.gd, adsData.gd) : '未契約' }}</span>
      </div>

      <div class="mt-4" v-if="gs_display || gd_display">
        広告費は、下記のボタンをクリックし変更してください。<br>
        <b-button class="mt-4 button is-info is-fullwidth has-text-weight-bold" @click="openModal('adbudgetchange')">
          広告費・決済日の変更
        </b-button>
      </div>

      <div class="mt-4" v-if="gs_display || gd_display">
        ※広告費・決済日の変更方法<br>
        <a href="https://r.ilb.net/ads-payment-change-ja" target="_blank">https://r.ilb.net/ads-payment-change-ja</a>
      </div>
    </div>


    <div class="mt-6 notification is-info is-light">
      <div id="change-delivery-area" class="is-size-4 has-text-weight-bold access-count-font">２．広告の配信地域の変更</div>
    </div>

    <div>
      <div>
        現在の配信地域（半径）の設定が商圏となる範囲より狭くなっている場合、配信地域を拡大することをオススメします。
      </div>

      <div class="mt-4">
        現在の配信地域の設定<br>
        ・Google広告（検索）： <span :class="{ 'has-text-danger' : !gs_display }" class="has-text-weight-bold">{{ gs_display ? getDeliveryArea(chargesData.gs, adsData.gs) : '未契約' }}</span><br>
        ・Google広告（ディスプレイ）： <span :class="{ 'has-text-danger' : !gd_display }" class="has-text-weight-bold">{{ gd_display ? getDeliveryArea(chargesData.gd, adsData.gd) : '未契約' }}</span>
      </div>

      <div class="mt-4" v-if="gs_display || gd_display">
        ※住所を市区町村までしか入力していない場合、該当の市区町村の中心地からの半径範囲の設定となります。
      </div>

      <div class="mt-4" v-if="gs_display || gd_display">
        広告費は、下記のボタンをクリックし変更してください。<br>
        <b-button class="mt-4 button is-info is-fullwidth has-text-weight-bold" @click="openModal('addeliveryrangechange')">
          配信地域の変更
        </b-button>
      </div>

      <div class="mt-4" v-if="gs_display || gd_display">
        ※配信地域の変更方法<br>
        <a href="https://r.ilb.net/ads-area-change-ja" target="_blank">https://r.ilb.net/ads-area-change-ja</a>
      </div>

      <div class="mt-4" v-if="gs_display || gd_display">
        地図上での半径距離は、下記URLよりご確認ください。<br>
        <a href="https://r.ilb.net/ad-area-check-ja" target="_blank">https://r.ilb.net/ad-area-check-ja</a>
      </div>
    </div>


    <div class="mt-6 notification is-info is-light">
      <div id="improved-page-content" class="is-size-4 has-text-weight-bold access-count-font">３．広告の着地先ページの内容改善</div>
    </div>

    <div>
      <div>
        {{ pageData.business_name + pageData.branch_name}} 様の着地先ページ<br>
        https://{{ getValue(adminData, 'service_name.url.country_code') }}.ilb.{{ getValue(adminData, 'service_name.url.tld') }}/{{ pageData.page_id }}
      </div>

      <div class="mt-4">
        ※広告は着地先ページに掲載している画像や文章をもとに、広告枠のサイズや広告を見るユーザーに応じて様々な組み合わせで表示される仕組みとなっています。
      </div>

      <div class="mt-4">
        広告の設定内容と広告の表示イメージは下記のボタンをクリックして確認できます。<br>
        <b-button class="mt-4 button is-info is-fullwidth has-text-weight-bold"  @click="$router.push('/ads_data')">
          広告内容の確認
        </b-button>
      </div>

      <div class="mt-4">
        お客様が着地先ページを見たときの問合せ率（CV率）を高めるためには、予約・問合せをするキッカケとなるオファー（サービスや特典・クーポンなど）を掲載・改善することが重要です。
      </div>

      <div class="mt-4">
        ※オファーの掲載ポイント<br>
        <a href="https://r.ilb.net/offer-ja" target="_blank">https://r.ilb.net/offer-ja</a>
      </div>

      <div class="mt-4">
        その他の問合せ率を高める方法については、下記のページをご確認ください。<br>
        <a
          :href="'https://admin-' + getValue(adminData, 'service_name.url.country_code') + '.ilb.' + getValue(adminData, 'service_name.url.tld') + '/' + pageData.page_id + '/page_report#conversion-rate'"
        >
          https://admin-{{ getValue(adminData, 'service_name.url.country_code') }}.ilb.{{ getValue(adminData, 'service_name.url.tld') }}/{{ pageData.page_id }}/page_report#conversion-rate
        </a>
      </div>
    </div>

    <!-- コンポーネント Modal -->
    <Modal
      class="change-plan qrcode-download"
      v-if="modal"
      @close="modalClose"
      @checkout="changeModal('checkout')"
      :pagedata="pageData"
      :adsdata="adsData"
      :chargesdata="chargesData"
      :type="type" />
  </section>
</template>

<script>
import Util from '../lib/util'

import Modal from '../../Modal.vue'
import Config from '../../../config.js'
import {loadStripe} from '@stripe/stripe-js'

export default {
  components: { Modal },
  props: {
    pageData: Object,
    adminData: Object,
    adsData: Object,
    chargesData: Object,
    itemNames: Object,
  },
  data () {
    return {
      modal: false,
      type:'',
      payment_date: '',
      last_payment_date: ''
    }
  },
  computed : {
    gs_display () {
      console.log(this.chargesData,this.adsData,this.chargesData.gs)
      return this.chargesData && this.adsData && this.chargesData.gs && this.chargesData.gs.payment_date !== ''
    },
    gd_display () {
      return this.chargesData && this.adsData && this.chargesData.gd && this.chargesData.gd.payment_date !== ''
    }
  },
  methods: {
    getDeliveryArea(charge_data, ad_data) {
      if (charge_data && charge_data.payment_date) {
        if (ad_data && ad_data.delivery_range === 0) {
          return '日本全域'
        } else if (ad_data && ad_data.delivery_range > 0) {
          return '半径' + ad_data.delivery_range + 'km'
        }
      }
      return '未契約'
    },
    getBudget(charge_data, ad_data) {
      if (charge_data && charge_data.payment_date && ad_data) {
        return ad_data.budget.toLocaleString() + '円'
      }
      return '未契約'
    },
    getValue(obj, key) {
      return Util.getValue(obj, key)
    },
    openModal(type) {
      // 自分で作るパターン
      this.modal = true
      this.type = type
    },
    modalClose() {
      console.log('HowToAttractCustomersWithAds.vue modalClose()')
      this.modal = false
      this.$emit('close')
    },
    changeModal(type) {
      // いったんモーダル閉じる
      this.modal = true
      this.type = type
    },
  },
  async mounted() {
    this.stripe = await loadStripe(Config.PUBLIC_KEY)
  },
}
</script>

<style>
.how-to-attract-customers-with-ads {
  padding: 0px 5px;
  max-width: 720px;
  margin: 0px auto;
  font-size: 16px;
}

.how-to-attract-customers-with-ads img {
  width: 640px;
}

.external-link {
  color: #7957d5 !important;
  text-decoration: none !important;
}

.change-plan {
  color: #4a4a4a;
}
.change-plan a {
  color: #7957d5 !important;
  cursor: pointer !important;
  text-decoration: none !important;
}
.change-plan .field {
  margin: unset !important;
  width: unset !important;
  text-align: unset !important;
}
.change-plan .field:not(:last-child) {
  margin-bottom: 0.75rem !important;
}

.qrcode-download img {
  width: auto;
  border: unset;
  padding: unset;
}
</style>
