<template>
  <section>
    <div v-if="plan_change === `downgrade`" class="column">
      <h1 class="is-size-4 has-text-weight-bold py-5">決済停止</h1>
      <div class="mb-3">
        <p class="mb-1 is-size-3">{{ pagedata['business_name'] }} {{ pagedata['branch_name'] }}</p>
        <p>{{ destination_url}}/{{ pagedata['page_id'] }} </p>
      </div>
      <p>月額利用料の決済を停止しました。</p>
    </div>
    <div v-else-if="plan_change === `upgrade`" class="column">
      <h1 class="is-size-4 has-text-weight-bold py-0">決済完了</h1>
      <h1 class="is-size-4 has-text-weight-bold py-5">ページが公開されました</h1>
      <p class="py-5">月額利用料の決済が完了しました。</p>
      <div class="mb-3">
        <p class="mb-1 is-size-3">{{ pagedata['business_name'] }} {{ pagedata['branch_name'] }}</p>
        <p>{{destination_url}}/{{ pagedata['page_id'] }} </p>
      </div>

      <h6>月額{{ amount.toLocaleString() }}円（税込）</h6>

      <p>10%対象(税抜)：{{ tax_excluded_price.toLocaleString() }}円</p>
      <p class="pb-5">10%対象消費税：{{ tax.toLocaleString() }}円</p>

      <p>決済完了のメールをお送りしていますので、ご確認ください。</p>
      <br />
      <div v-if="!salesdata || (salesdata && salesdata['page_revision'] === '')">
        <p>ページの代理入力（無料）が依頼できます</p>
        <p class="mb-5">詳細はボタンをクリック</p>
        <!-- <b-button class="btn col s12 #e91e63 pink" type="is-success has-text-weight-bold" @click="openModal('agentrequest')"> ページの代理入力を依頼する </b-button> -->
        <b-button class="btn col s12 #e91e63 pink" type="is-success has-text-weight-bold" @click="$router.push('/agent-request').catch(() => {})"> ページの代理入力を依頼する </b-button>
      </div>
    </div>
    <!-- コンポーネント Modal -->
    <!-- <Modal class="change-plan qrcode-download" v-if="modal" @close="modalClose" @changeModalAgentRequest="changeModal('agentrequest')" :pagedata="pageData" :type="type" /> -->
  </section>
</template>

<script>
import Config from '../../config.js';
// import Modal from '../Modal.vue';

export default {
  name: 'Complete',
  components: {
    // Modal,
  },
  props: {
    plan_change: {
      type: String,
      required: true,
    },
    pagedata: {
      type: Object,
      required: true,
    },
    salesdata: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      amount: Config.AMOUNT,
      // modal: false,
      // type: '',
    };
  },
  computed: {
    destination_url () {
      return Config.DESTINATION_URL;
    },
    tax() {
      return Math.floor(this.amount - this.amount / (1 + Config.TAX_RATE));
    },
    tax_excluded_price() {
      return this.amount - this.tax;
    },
  },
  methods: {
    // openModal(type) {
    //   // 自分で作るパターン
    //   this.modal = true;
    //   this.type = type;
    // },
    // modalClose() {
    //   this.modal = false;
    //   // this.$emit('close');
    //   // TOPページに戻る
    //   this.$router.push('/').catch(() => {});
    // },
  },
};
</script>
