<template>
  <section class="row payment-form has-text-centered">
    <div class="swal2-icon swal2-info swal2-icon-show" style="display: flex">
      <div class="swal2-icon-content">!</div>
    </div>
    <h2><b> 決済を停止する </b></h2>
    <br />
    <div>
      <div class="mb-3">
        <p>{{ pagedata['business_name'] }} {{ pagedata['branch_name'] }}</p>
        <a :href="'https://jp.ilb.net/' + pagedata['page_id']" target="_blank"> https://jp.ilb.net/{{ pagedata['page_id'] }} </a>
      </div>
      <div v-if="adAvailableCheck" class="mb-3 has-text-left">
        <!-- <p><span style="color:red;">ページを無料プランに変更すると、ページ内に他社広告が表示され広告効果が低下する可能性があるため、 Google広告の契約中は有料プランでご利用ください。</span>
        </p> -->
      </div>
      <div class="mb-3 has-text-left">
        <p>転送用URLを設定されている場合、転送用URLは削除となります</p>
        <p>チラシやWebサイト、各種SNSなどに転送用URLをご利用の場合は変更が必要です。また 再度、決済再開時に同じ文字列の設定ができるとは限りませんので、ご了承ください</p>
      </div>
    </div>

    <div class="col s12 place-order-button-block">
      <b-button v-if="success === false" class="btn col s12 #e91e63 pink" type="is-info has-text-weight-bold" @click="save()"> 決済を停止する </b-button>
      <b-button v-if="success === 'loading'" loading>Loading</b-button>
    </div>
    <div class="col s12 place-order-button-block">
      <b-button class="btn col s12 #e91e63" type="has-background-grey-light has-text-weight-bold" @click="modalClose"> キャンセル </b-button>
    </div>
  </section>
</template>

<script>
import Config from '../../config.js';
import moment from 'moment';

export default {
  props: ['pagedata', 'chargesdata'],
  data() {
    return {
      success: false,
      isLoading: false,
    };
  },
  computed: {
    // 広告利用中かのチェック
    adAvailableCheck() {
      if (this.chargesdata) {
        if (this.chargesdata.google && this.chargesdata.google.payment_date) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    // 無料プランへ変更
    async save() {
      this.success = 'loading';
      this.isLoading = true;

      try {
        // chargesデータを変更する
        await Config.CHARGES_COLLECTION.doc(this.$store.state.page_id).set(
          {
            page: {
              payment_date: '',
            },
          },
          { merge: true }
        );
      } catch (e) {
        this.$swal('error', '保存エラー：' + e, 'error');
        return;
      }

      // 無料プランへ戻したことをメールする
      // await this.PlanDowngradeMail()
      await this.PlanDowngradeMailToClient();

      const result = await this.$swal.fire({
        icon: 'success',
        title: '決済停止',
        text: '最終のお支払い日から１ヶ月後に無料プランの掲載内容に変更となります',
        confirmButtonText: '管理画面に戻る',
        allowOutsideClick: false,
      });

      if (result.isConfirmed) {
        this.success = false;
        this.isLoading = false;
        this.modalClose();
        return;
      }
    },
    // async PlanDowngradeMail() {
    //   let data = {
    //     template_id : 'MANUAL_CHANGE_PLAN_FREE_SUCCESS',
    //     business_name: this.pagedata.business_name,
    //     branch_name: this.pagedata.branch_name || '',
    //     status: 'sending',
    //     date: moment().format("YYYY-MM-DD HH:MM:SS"),
    //     page_id: this.$store.state.page_id,
    //     name: this.$store.state.admin_data.service_name.page.name,
    //     short_name: this.$store.state.admin_data.service_name.page.short_name,
    //     system_name: this.$store.state.admin_data.service_name.page.system_name,
    //     country_code: this.$store.state.admin_data.service_name.url.country_code,
    //     tld: this.$store.state.admin_data.service_name.url.tld,
    //   }
    //   await Config.SENDMAIL_TARGET_COLLECTION.add(data);
    // },
    async PlanDowngradeMailToClient() {
      let data = {
        template_id: 'MANUAL_CHANGE_PLAN_FREE_TO_CLIENT_SUCCESS',
        to: this.$store.state.user.email,
        email_auth: this.$store.state.user.email,
        business_name: this.pagedata.business_name,
        branch_name: this.pagedata.branch_name || '',
        category: this.pagedata.category,
        last_payment_date: this.chargesdata.page.last_payment_date,
        status: 'sending',
        date: moment().format('YYYY-MM-DD HH:MM:SS'),
        expiration_date: moment(new Date(this.chargesdata.page.last_payment_date).toISOString()).add(31, 'days').format('YYYY/MM/DD'),
        page_id: this.$store.state.page_id,
        name: this.$store.state.admin_data.service_name.page.name,
        short_name: this.$store.state.admin_data.service_name.page.short_name,
        system_name: this.$store.state.admin_data.service_name.page.system_name,
        country_code: this.$store.state.admin_data.service_name.url.country_code,
        tld: this.$store.state.admin_data.service_name.url.tld,
      };
      await Config.SENDMAIL_TARGET_COLLECTION.add(data);
    },
    modalClose() {
      this.$emit('modalclose');
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 1.875em;
}
.payment-form {
  max-width: 500px;
  margin: 20px auto;
  padding: 12px;
  margin-bottom: 8px;
}
.place-order-button-block {
  margin: 5px 0;
  text-align: center;
}
button {
  background-color: #23d160;
  color: white;
  border-radius: 4px;
  font-size: 20px;
  width: -webkit-fill-available;
  /* font-weight: 600; */
  border: 0;
  box-shadow: none;
  padding: 8px 24px;
}
</style>
