<template>
  <div>
    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="official_url">{{ title.official_url }}</label>
      <p class="pb-1">
        例）http://vegefru.org<br>
        NG例）vegefru.org
      </p>
      <b-input id="official_url" v-model="pageData['official_url']" @blur="trim('official_url')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="recruit_url">{{ title.recruit_url }}</label>
      <p class="pb-1">
        求人を行っている場合、採用情報ページのURLを入力してください。
      </p>
      <b-input id="recruit_url" v-model="pageData['recruit_url']" @blur="trim('recruit_url')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="blog_url">{{ title.blog_url }}</label>
      <b-input id="blog_url" v-model="pageData['blog_url']" @blur="trim('blog_url')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="facebook_page_id">{{ title.facebook_page_id }}</label>
      <p class="pb-1">
        ID（赤丸の部分）を入力してください。<br>
        https://www.facebook.com/<span class="has-text-danger">●●●●●</span>/
      </p>
      <b-input id="facebook_page_id" v-model="pageData['facebook_page_id']" @blur="trim('facebook_page_id')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="instagram_id">{{ title.instagram_id }}</label>
      <p class="pb-1">
        ID（赤丸の部分）を入力してください。<br>
        https://www.instagram.com/<span class="has-text-danger">●●●●●</span>/
      </p>
      <b-input id="instagram_id" v-model="pageData['instagram_id']" @blur="trim('instagram_id')" ></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="tiktok_id">{{ title.tiktok_id }}</label>
      <p class="pb-1">
        ID（赤丸の部分）を入力してください。<br>
        https://www.tiktok.com/@<span class="has-text-danger">●●●●●</span>/
      </p>
      <b-input id="tiktok_id" v-model="pageData['tiktok_id']" @blur="trim('tiktok_id')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="x_id">{{ title.x_id }}</label>
      <p class="pb-1">
        ID（赤丸の部分）を入力してください。<br>
        https://x.com/<span class="has-text-danger">●●●●●</span>
      </p>
      <b-input id="x_id" v-model="pageData['x_id']" @blur="trim('x_id')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="youtube_channel_id">{{ title.youtube_channel_id }}</label>
      <p class="pb-1">
        YouTubeチャンネルのID（赤字の部分）を入力してください。<br>
        https://www.youtube.com/channel/<span class="has-text-danger">UCYXGHivsMPVU-h-M_wd9m3Q</span>
      </p>
      <b-input id="youtube_channel_id" v-model="pageData['youtube_channel_id']" @blur="trim('youtube_channel_id')"></b-input>
    </div>

    <div class="field pb-1">
      <label class="label has-text-weight-bold" for="link_url">{{ title.link_url }}</label>

      <img class="pb-3" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/link_url.png">
      <p class="pb-1">
        表示したいリンクのURLを入力してください。<br>
        ※リンクを表示するためには「リンクの表示名」の入力も必要です。
      </p>
      <b-input id="link_url" v-model="pageData['link_url']" @blur="trim('link_url')"></b-input>
    </div>
    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="link_text">{{ title.link_text }}</label>
      <p class="pb-1">
        例１）UberEats<br>
        例２）ショッピングサイト<br>
        ※「リンクのURL」の入力も必要です。
      </p>
      <b-input id="link_text" v-model="pageData['link_text']" maxlength="15" @blur="trim('link_text')"></b-input>

      <div v-if="pageData['link_url'] && pageData['link_text'] " class="pt-3">
        下記のリンクをクリックし、入力したリンクが正しいか？確認できます。<br>
        <a :href="pageData['link_url']" target="_blank">
          {{ pageData['link_text'] }}
        </a>
      </div>

    </div>

    <hr>
    <!--ここから-->
    <div v-if="updateFlg">

      <div class="field pb-3">
        <label class="label has-text-weight-bold" for="新着情報">新着情報</label>
        <p class="pb-1">
          FacebookページとXでの投稿内容を新着情報として自動表示できます。
        </p>
        <img class="" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/feed.png">
      </div>

      <div class="content" v-for="key of 2" :key="key">
        <div class="move-panel">
          <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
          <template #trigger>
            <b-icon icon="dots-vertical"></b-icon>
          </template>
            <b-dropdown-item aria-role="listitem" @click="menuMove('feed', (key-1),'up', true)" :disabled="(key-1) === 0">一番上に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMove('feed',(key-1),'up')" :disabled="(key-1) === 0">一つ上に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMove('feed',(key-1),'down')" :disabled="(key-1) === (pageData['feed'].length - 1)">一つ下に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMove('feed',(key-1),'down', true)" :disabled="(key-1) === (pageData['feed'].length - 1)">一番下に移動</b-dropdown-item>
          </b-dropdown>
        </div>
        <b-collapse
          aria-id="contentIdForA13y5"
          class=""
          animation="slide"
          v-model="isOpen">
          <template #trigger>
            <div
              class="panel-heading mb-3 feed"
              role="button"
              aria-controls="contentIdForA13y5">
              <strong>{{ feedType(pageData['feed'][key-1]['type'])["type"] }}の新着情報</strong>
            </div>
          </template>
          <div v-if="!pageData[pageData['feed'][key-1]['type'] + '_id']" class="field pb-3">
            <label class="label has-text-weight-bold" :for="pageData['feed'][key-1]['type'] + '_id'">{{ title[pageData['feed'][key-1]['type'] + '_id'] }}</label>
            <p class="pb-1">
              ID（赤丸の部分）を入力してください。<br>
              {{ feedType(pageData['feed'][key-1]['type'])["url"] }}<span class="has-text-danger">●●●●●</span>/
            </p>
            <b-input
              :id="pageData['feed'][key-1]['type'] + '_id'"
              v-model="pageData[pageData['feed'][key-1]['type'] + '_id']"
              @blur="trim(pageData['feed'][key-1]['type'] + '_id')"
              >
              </b-input>
          </div>
          <div v-else class="control">
            <b-switch v-model="pageData['feed'][key-1]['display']" @click="pageData['feed'][key-1]['display']=!pageData['feed'][key-1]['display']">
              <strong>{{ pageData['feed'][key-1]['display'] ? `表示する` : `表示しない`}}</strong>
            </b-switch>
          </div>
        </b-collapse>
      </div>
    </div>
    <!-- ここまで  -->
  </div>
</template>

<script>
import 'bulma/css/bulma.css'

export default {
  props: ['doc', 'title'],
  components: {
  },
  computed: {
    pageData: {
      get() {
        return this.doc;
      },
      set(data) {
        this.$emit('doc', data);
      }
    },
  },
  methods: {
    feedType : function (feed_type) {
      if(feed_type === 'facebook_page') {
        return {
          type : 'Facebook',
          url  : 'https://www.facebook.com/'
        }
      } else if (feed_type === 'x') {
        return {
          type : 'X',
          url  : 'https://x.com/'
        }
      } else {
        return {};
      }
    },
  },
  data () {
    return {
      isOpen: true,
      updateFlg: true // 再描画用
    }
  },
}
</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
.move-panel {
  position:relative;
  top:12px;
  left: -1%;
}
.feed {
  margin-bottom: 0.5em;
}
</style>
