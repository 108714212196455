export default class Util {
  static dateFormatter(date, format){
    let dayOfWeek = [ "日", "月", "火", "水", "木", "金", "土" ]
  
    format = format.replace(/YYYY/, date.getFullYear());
    format = format.replace(/MM/, ("0" + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/, ("0" + date.getDate()).slice(-2));
    format = format.replace(/DAY/, dayOfWeek[date.getDay()]);

    format = format.replace(/hh/, ("0" + date.getHours()).slice(-2));
    format = format.replace(/mm/, ("0" + date.getMinutes()).slice(-2));
  
    return format;
  }
  
  static yenFormatter(price) {
    return !this.isEmpty(price) ? '¥' + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
  }

  static numberFormatter(number) {
    return !this.isEmpty(number) ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
  }

  static dayFormatter(day) {
    return !this.isEmpty(day) ? day.toString() + '日' : ''
  }

  static isEmpty(value) {
    return typeof value === "undefined" || value === null || value === '' || isNaN(value)
  }

  static getMousePositionWithinElement(e, element) {
    if (!e) e = window.event;
    var x, y;
    var clientRect = element.getBoundingClientRect()
    if (e.targetTouches) {
      x = e.targetTouches[0].pageX - (clientRect.left + window.pageXOffset);
      y = e.targetTouches[0].pageY - (clientRect.top + window.pageYOffset);
    }else if (element){
      x = e.pageX - (clientRect.left + window.pageXOffset);
      y = e.pageY - (clientRect.top + window.pageYOffset);
    }
    return { x:x, y:y };
  }

  static isScrollable(element, direction) {
    if (direction === 'right') {
      return element.scrollLeft < element.scrollWidth - element.clientWidth
    }
    if (direction === 'left') {
      return element.scrollLeft > 0
    }
  }

  static switchCalss(element, addClassName, removeClassName) {
    element.classList.add( addClassName )
    element.classList.remove( removeClassName )
  }

  static scrollElement(element, direction, moveAmount, maxScrollAmount) {
    let value = 0
    if (direction === 'right') {
      value = 1
    } else if (direction === 'left') {
      value = -1
    }

    let scrollAmount = 0
    const timer = setInterval(() => {
      element.scrollLeft += value * moveAmount
      scrollAmount += moveAmount
      if(scrollAmount >= maxScrollAmount || !Util.isScrollable(element, direction)) {
        clearInterval(timer);
      }
    }, 1);
  }

  static getPositiveNumber(number) {
    return number < 0 ? 0 : number
  }

  static isNumber(number) {
    // チェック条件パターン
    var pattern = /^[-]?([1-9]\d*|0)(\.\d+)?$/;

    // 数値チェック
    return pattern.test(number);
  }

  // 要素の位置を取得
  static getElementPos(id) {
    const elem = document.getElementById(id)
    const rect = elem.getBoundingClientRect()

    return {
      x: parseInt(rect.left + window.pageXOffset),
      y: parseInt(rect.top + window.pageYOffset),
      left: parseInt(rect.left + window.pageXOffset + elem.clientWidth),
      bottom: parseInt(rect.top + window.pageYOffset + elem.clientHeight),
    }
  }

  static getValue(obj, key) {
    if (obj !== null && typeof obj === 'object') {
      var k = key.split('.')
      for (var i = 0, v = obj; i < k.length; i++) {
          if (!(k[i] in v)) { return void 0 }
          v = v[k[i]];
      }
      return v
    }
    return void 0
  }
}
