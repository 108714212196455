import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'
import firebase from './firebase/firebaseInit'

export default Object.freeze({
  COLLECTION: firebase.firestore().collection('page_data'),
  REFERRAL_COLLECTION: firebase.firestore().collection('referral_data'),
  CHARGES_COLLECTION: firebase.firestore().collection('charges_data'),
  ADS_COLLECTION: firebase.firestore().collection('ads_data'),
  SENDMAIL_TARGET_COLLECTION: firebase.firestore().collection('sendmail_target'),
  SALES_COLLECTION: firebase.firestore().collection('sales_data'),
  ADMIN_COLLECTION: firebase.firestore().collection('admin_data'),
  MAIL_HISTORY_COLLECTION: firebase.firestore().collection('mail_history'),
  FIREBASE_STORAGE: firebase.storage(),
  FIREBASE_AUTH:firebase.auth(),
  FIREBASE_STORE:firebase.firestore(),
  FIREBASE_FUNCTIONS:firebase.functions(),
  ZIPCODE_API_URL: 'https://zip-cloud.appspot.com/',
  STORAGE_DIR: 'prd',
  // これらの項目は別コレクションうつったが古い画面キャッシュ対策
  EXCEPT_KEYS: ['last_payment_date', 'payment_date', 'price', 'original_id'],
  AMOUNT: 4980,
  //PUBLIC_KEY: 'pk_test_51Gt6tVH5YFQWvXGvna0vLAlvdP5CSxrsom6iYF5v5UUM8FtU0rQbhKzKIYT3WF7DIPnSIACHITF1UW3aEEjuYr6c00nWOcJeeL',
  PUBLIC_KEY: 'pk_live_8Yq4ekgb5u2EQiTbmDDYdlyZ00NBCdn5lW',
  // SERVICE_NAMES: { page: 'インデックスページ作成サービス', gs:'Google広告（検索）', gd:'Google広告（ディスプレイ）', gdp:'Google（有料プラン特典）'},
  GOOGLE_MAP_APIKEY:'AIzaSyCcvOWzd0v8ixg1utafrgk5hlwsNELQghk',
  // GOOGLE_MAP_APIKEY:'AIzaSyC9vvllFPz1TWEI7HCD14mFC9s7oE1uGDs',
  GOOGLE_PROJECT_ID: 'admin-ilb',
  FEE_RATE:0.2,
  TAX_RATE:0.1,
  DELIVERY_RANGE: 5, // 初回決済時は固定
  DESTINATION_URL: 'https://jp.ilb.net',
  MANAGE_URL: 'https://admin-jp.ilb.net'
})
