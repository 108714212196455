<template>
  <section class="section container">
    <div class="has-text-centered pb-5">
      <h2 class="title">
        作成依頼完了
      </h2>
      <p class="pb-1">
        <span>以下の内容でページの作成依頼が完了しました</span>
      </p>
      <div>
      <p>{{ business_name }}</p>
      </div>
      <div>
        <p>{{ phone }}</p>
      </div>
      <div>
        <p>{{ email }}</p>
      </div>

      <hr>

      <div class="">
        ページの作成が完成しましたら、メールにてご報告します
      </div>
      <div class="pt-3">
        <button
          class="button is-info has-text-weight-bold"
          @click="closeWindow()"
        >
        ウィンドウを閉じる
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import Config from '../config.js'

export default {
  components: {
  },
  created () {
  },
  data: () => ({
    referral_data: {},
    business_name:'',
    phone: '',
    email: '',
  }),
  methods : {
    closeWindow() {
      window.open('about:_blank', '_self').close()
    }
  },
  async mounted () {
    this.referral_data = await this.$store.state.referral_data
    this.business_name = await this.referral_data.business_name
    this.phone = await this.referral_data.phone
    this.email = await this.$store.state.user.email

    // // referral_dataの作成（referral_codeの挿入）
    // if(Object.keys(this.referral_data).length) {
    //   await Config.REFERRAL_COLLECTION.doc(this.$store.state.user.uid).set(this.referral_data, { merge: true })
    // }

    // storeの削除とauthのサインアウト（そのままだとログイン状態のため）
    await this.$store.commit('referral_data', '')
    await this.$store.commit('user', '')
    await Config.FIREBASE_AUTH.signOut()
  }
}
</script>

<style scoped>
</style>
