<template v-if='$store.state.user'>
  <div class="column is-4 is-offset-4">

    <h1 class="is-size-4 has-text-weight-bold has-text-centered">パスワードの変更</h1>
    <p class="has-text-centered pb-3">ローカルマーケティングマネージャー</p>

    <div class="field">
      <p class="control">
        <input class="input" type="password" placeholder="現在のパスワード" v-model="currentpassword">
      </p>
    </div>

    <hr>

    <div class="field">
      <p class="control">
        <input class="input" type="password" placeholder="新しいパスワード" v-model="newpassword">
      </p>
    </div>
    <div class="field">
      <p class="control">
        <input class="input" type="password" placeholder="新しいパスワードをもう一度入力" v-model="newconfirmpassword">
      </p>
    </div>
    <div class="field">
      <p class="control">
        <button :disabled="processing" class="button is-success" type="button" @click="passwordchange">変更を保存</button>
        <button :disabled="processing" class="button is-primary is-pulled-right" type="button" @click="toHome">キャンセル</button>
      </p>
    </div>
  </div>
</template>
<script>
import Config from '../config.js'
import firebase from 'firebase/app'

export default {
  data () {
    return {
      currentpassword:'',
      newpassword: '',
      newconfirmpassword: '',
      processing: false,
    }
  },
  methods: {
    toHome () {
      location.href = './'
    },
    async passwordchange () {
      if (this.processing) return
      this.processing = true
      let swal = this.$swal
      let user = await Config.FIREBASE_AUTH.currentUser

      // パスワード確認用チェック
      if ( this.newpassword !== this.newconfirmpassword) {
        swal('エラー', "異なるパスワードが入力されています", 'error')
        this.processing = false
        return
      } else if (this.newpassword === '' || this.newconfirmpassword === '') {
        swal('エラー', "パスワードを入力してください", 'error')
        this.processing = false
        return
      }

      // 再認証のパラメーター
      const credential = await firebase.auth.EmailAuthProvider.credential(
          user.email,
          this.currentpassword
      )

      // credentialをreauthenticateWithCredentialに渡す
      try {
        await user.reauthenticateWithCredential(credential)

      } catch(error) {
        this.processing = false
        swal.fire({
          title: 'エラー',
          text: '入力された「現在のパスワード」が間違っています',
          type: 'error',
        })
        this.processing = false
        return
      }

      try {
        await user.updatePassword(this.newpassword)
      } catch (error) {
        this.processing = false

        let error_msg = error.message
        console.debug(error.message)
        switch (error.message) {
          case 'Password should be at least 6 characters':
            error_msg = 'パスワードは少なくと6文字以上で入力ください。';
            break;

          default:
            break;
        }

        swal.fire({
          icon: 'error',
          title: 'パスワード更新エラー',
          text: error_msg,
          type: 'error'
        })
        return
      }

      swal.fire({
        title: 'パスワード',
        text: '変更しました',
        type: 'success',
        allowOutsideClick: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'ログイン画面へ戻る'
      }).then(async function(result) {
        if (result.value) {
          // this.processing = false
          // ログイン画面へ
          await Config.FIREBASE_AUTH.signOut()
          location.href = './login'
        }
      })

    }
  }
}
</script>
