import firebase from '@/firebase/firebaseInit'
import Util from './util'

export default class GoogleReport {
  static getReportData(collection, targetDate) {
//console.log("GoogleReport.getReportData")
    let reportData = []

    // 日ごと（当月分）------------------------------------------------
    reportData.push(firebase.firestore().collection(collection + '/google/campaign/daily')
            .where('date', '>=', Util.dateFormatter(targetDate, 'YYYY-MM-01'))
            .where('date', '<=', Util.dateFormatter(new Date(targetDate.getFullYear(), targetDate.getMonth() + 1, 0), 'YYYY-MM-DD'))
            .orderBy('date').get())

    // 合計（当月分）--------------------------------------------------
    reportData.push(firebase.firestore().collection(collection + '/google/campaign/total')
            .doc(Util.dateFormatter(targetDate, 'YYYY-MM')).get())

    // 合計（全期間）--------------------------------------------------
    reportData.push(firebase.firestore().collection(collection + '/google/campaign/total')
            .doc('all_periods').get())

    return reportData
  }

  static createReportData(dailyDatas, monthData, allPeriods, adsData, adminData, chargesData, targetDate) {
    let reportData = {
      'total': {},
      'daily': [],
      'status': '',
      'status_detail': '',
    }
    
    // 広告ステータス
    if (!chargesData || !chargesData['payment_date']) {
      reportData['status'] = '未契約'
      reportData['status_detail'] = ''
    } else {
      if (adsData) {
        switch (adsData['status']) {
          case 'enable':
            reportData['status'] = '運用中'
            reportData['status_detail'] = ''
            break;
          case 'suspend':
            reportData['status'] = '停止中'
            reportData['status_detail'] = '一時停止'
            break;
          case 'spent':
            reportData['status'] = '停止中'
            reportData['status_detail'] = '予算切れ'
            break;
          case 'required_items_not_entered':
            reportData['status'] = '停止中'
            reportData['status_detail'] = '必須項目が未入力'
            break;
          default:
            reportData['status'] = '停止中'
            reportData['status_detail'] = ''
            break;
        }
      }  
    }

    // 広告状況
    let isDailyData = false
    dailyDatas.forEach(doc => {
      if (parseInt(doc.data()['impressions']) > 0) {
        isDailyData = true
      }
    })

    if ((chargesData && chargesData['payment_date'] && isDailyData) || isDailyData) {
      if (adsData && chargesData) {
        // 広告予算
        reportData['total']['adBudget'] = adsData['budget']

        // 1日の上限予算
        reportData['total']['maximumDailyBudget'] = adsData['daily_budget']

        // 未使用の広告費
        reportData['total']['unusedAdExpenses'] = adsData['total_amount']
        if (allPeriods.data()) {
          reportData['total']['unusedAdExpenses'] -= Math.round(parseInt(allPeriods.data()['cost']) * 0.000001)
          reportData['total']['unusedAdExpenses'] = reportData['total']['unusedAdExpenses'] > 0 ? reportData['total']['unusedAdExpenses'] : 0  
        }

        if (chargesData['payment_date']) {
            // 次回の広告予算
          reportData['total']['nextAdBudget'] = Math.round((chargesData['price'] / adminData['charges']['tax_rate'] / adminData['charges']['ads_fee_rate']) * 10)
          reportData['total']['nextAdBudget'] = reportData['total']['nextAdBudget'] / 10

          // 次回の決済日
          reportData['total']['payment_date'] = parseInt(chargesData['payment_date'])
        } else {
          // 次回の広告予算
          reportData['total']['nextAdBudget'] = ''

          // 次回の決済日
          reportData['total']['payment_date'] = ''
        }
      }

      // 集計処理（当月分）------------------------------------------------
      reportData['total']['clicks'] = 0       // クリック数
      reportData['total']['cost'] = 0         // 使用済みの広告費（今月）
      reportData['total']['conversions'] = 0  // 問合せ数（CV数）
      reportData['total']['impressions'] = 0  // 表示回数
      reportData['total']['webCv'] = 0        // ウェブコンバージョン
      reportData['total']['telCv'] = 0        // 電話コンバージョン
      reportData['total']['lineCv'] = 0       // LINEコンバージョン
      reportData['total']['linkCv'] = 0       // リンクコンバージョン

      let toDate = Util.dateFormatter(new Date(), 'YYYY-MM-DD')
      let lastDate = new Date(targetDate.getFullYear(), targetDate.getMonth() + 1, 0)
      for (let i = 1; i <= lastDate.getDate(); i++) {
        let dailyDate = Util.dateFormatter(lastDate, 'YYYY-MM-' + ('0' + i).slice(-2))

        let dailyData = null
        dailyDatas.forEach(doc => {
          if (doc.data()['date'] == dailyDate) {
            dailyData = doc.data();
          }
        });

        let data = {}
        if (dailyData !== null) {
          let webCv = 0
          let telCv = 0
          let lineCv = 0
          let linkCv = 0
          if (dailyData['conversion_actions']) {
            let counts = dailyData['conversion_actions'].reduce((acc, data) => {
              let name = data.segments_conversion_action_name;
              acc[name] = (acc[name] || 0) + data.metrics_conversions;
              return acc;
            }, {});
            
            webCv = counts['ウェブコンバージョン'] || 0
            telCv = counts['電話コンバージョン'] || 0
            lineCv = counts['LINEコンバージョン'] || 0
            linkCv = counts['リンクコンバージョン'] || 0
          }

          data = {
            'date': dailyData['date'],                                    // 日付
            'clicks': dailyData['clicks'],                                // クリック数
            'cost': Math.round(parseInt(dailyData['cost']) * 0.000001),   // 広告費
            'conversions': Math.ceil(dailyData['conversions']),           // コンバージョン数
            'impressions': parseInt(dailyData['impressions']),            // 表示回数
            'webCv': Math.ceil(webCv),                                    // ウェブコンバージョン
            'telCv': Math.ceil(telCv),                                    // 電話コンバージョン
            'lineCv': Math.ceil(lineCv),                                  // LINEコンバージョン
            'linkCv': Math.ceil(linkCv)                                   // リンクコンバージョン
          }

          reportData['total']['clicks'] += data['clicks']
          reportData['total']['cost'] += data['cost']
          reportData['total']['conversions'] += Math.ceil(data['conversions'])
          reportData['total']['impressions'] += data['impressions']
          reportData['total']['webCv'] += Math.ceil(webCv)
          reportData['total']['telCv'] += Math.ceil(telCv)
          reportData['total']['lineCv'] += Math.ceil(lineCv)
          reportData['total']['linkCv'] += Math.ceil(linkCv)
        } else {
          if (dailyDate < toDate) {
            data = {
              'date': dailyDate,    // 日付
              'clicks': 0,          // クリック数
              'cost': 0,            // 広告費
              'conversions': 0,     // コンバージョン数
              'impressions': 0,     // 表示回数
              'webCv': 0,           // ウェブコンバージョン
              'telCv': 0,           // 電話コンバージョン
              'lineCv': 0,          // LINEコンバージョン
              'linkCv': 0           // リンクコンバージョン
            }
          }
        }

        reportData['daily'].push(data)
      }

      // 合計（当月分）--------------------------------------------------
      // クリック率
      reportData['total']['ctr'] = reportData['total']['impressions'] > 0 ? reportData['total']['clicks'] / reportData['total']['impressions'] : 0
      reportData['total']['ctr'] = (Math.round(reportData['total']['ctr'] * 100 * 100) / 100) + '%'

      // クリック単価
      reportData['total']['average_cpc'] = reportData['total']['clicks'] > 0 ? reportData['total']['cost'] / reportData['total']['clicks'] : 0
      reportData['total']['average_cpc'] = Math.round(reportData['total']['average_cpc'])

      // 問合せ率（CV率）
      reportData['total']['conversion_rate'] = reportData['total']['clicks'] > 0 ? reportData['total']['conversions'] / reportData['total']['clicks'] : 0
      reportData['total']['conversion_rate'] = (Math.round(reportData['total']['conversion_rate'] * 100 * 100) / 100) + '%'

      // 問合せ単価（CPA）
      reportData['total']['cpa'] = reportData['total']['conversions'] > 0 ? reportData['total']['cost'] / reportData['total']['conversions'] : 0
      reportData['total']['cpa'] = Math.round(reportData['total']['cpa'])
    } else {
      // 使用済みの広告費（今月）
      reportData['total']['cost'] = 0

      // 広告予算
      reportData['total']['adBudget'] = ''

      // 1日の上限予算
      reportData['total']['maximumDailyBudget'] = ''

      // 未使用の広告費
      reportData['total']['unusedAdExpenses'] = ''

      // 次回の広告予算
      reportData['total']['nextAdBudget'] = ''

      // 次回の決済日
      reportData['total']['payment_date'] = ''
    }

    return reportData
  }
}
