<template>
  <div class="columns">
    <div class="column">
      <canvas
        ref="thumbnail"
        :width="0"
        :height="0"
        style="text-align: left"
      ></canvas>
    </div>
    <div class="column">
      <input
        type="file"
        v-on:change="resize"
        accept=".jpg, .png"
        ref="input"
        style="margin: 10px"
      />
      <button
        v-if="src && removeButton"
        v-on:click="remove"
        class="button is-danger"
        style="margin: 10px"
      >
        画像削除
      </button>
      <button
        v-if="cancelButton"
        v-on:click="reset"
        class="button is-danger"
        style="margin: 10px"
      >
        キャンセル
      </button>
    </div>
  </div>
</template>

<script>
import "bulma/css/bulma.css";

export default {
  props: ["imageTitle", "src", "arrayNum", "docFieldTitle", "docFieldImageTitle"],
  data() {
    return {
      newImage: "",
      imageFile: "",
      ext: "jpg",
      type: "image/jpeg",
      cancelButton: false,
      removeButton : true
    };
  },
  methods: {
    /**
     * ファイル選択ボタン押した時の処理
     * リサイズしてサムネイルを画面上に出す
     * 保存用の圧縮はcompressor（Main.vue）で行う
     */
    resize(e) {
      const file = e.target.files[0];

      var swal = this.$swal;
      if (file !== undefined) {
        this.imageName = file.name;
        if (this.imageName.lastIndexOf(".") <= 0) {
          return;
        }

        this.type = file.type;

        //拡張子は .jpg .gif .pngのみ許可
        if (
          this.type != "image/jpeg" &&
          this.type != "image/gif" &&
          this.type != "image/png"
        ) {
          swal(
            ".jpg、.gif、.pngのいずれかのファイルのみ許可されています",
            "画像アップロード",
            "error"
          );
          return;
        }

        const pos = this.imageName.lastIndexOf('.');
        this.ext = this.imageName.slice(pos + 1);

        const fr = new FileReader();

        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          this.imageUrl = fr.result;
          this.imageFile = file; // this is an image file that can be sent to server...
          vm.makeTumbnail(image);
        });
      } else {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
      }

      const image = new Image();
      const reader = new FileReader();
      const vm = this;

      reader.readAsDataURL(file);
      reader.onload = (e) => {
        image.src = e.target.result;
        image.onload = () => {
          // リサイズしない
          // vm.newImage = this.width < 1280 ? this.src : vm.makeImage(image)
          // サムネイルは作る
          vm.makeTumbnail(image);
        }
      }

      // キャンセルボタン
      this.cancelButton = true;

      // **** Main.vueにて一括保存に仕様変更のためここでは親にemitのみ **** //
      // emitする際に共通のプロパティ名で利用するために 例）case_image1 → caseimage
      // 画像の追加or削除はisDeletedで判断させる
      this.$emit(this.imageTitle.replace(/_/g, "").replace(/[0-9]/g, ""),
        {
          file : file,
          imageTitle: this.imageTitle,
          src: this.src,
          arrayNum: this.arrayNum,
          docFieldTitle: this.docFieldTitle,
          docFieldImageTitle: this.docFieldImageTitle,
          isDeleted: false // 画像追加
        }
      )
    },
    /**
     * サムネ画像作成
     */
    makeTumbnail(image) {
      const canvas = this.$refs.thumbnail;
      const ctx = canvas.getContext("2d");
      const ratio = image.width / image.height;
      const height = 120;
      const width = height * ratio;
      canvas.height = height;
      canvas.width = width;
      ctx.drawImage(
        image,
        0,
        0,
        image.width,
        image.height,
        0,
        0,
        width,
        height
      );
    },
    /**
     * サムネイルが表示されているキャンバスをリセット
     */
    reset() {
      const canvas = this.$refs.thumbnail;
      this.newImage = "";
      canvas.height = 0;
      canvas.width = 0;
      this.$refs.input.value = "";
      // キャンセルボタン
      this.cancelButton = false
      // 削除ボタン
      this.removeButton = true
    },
    /**
     * 画像削除ボタン押した際の処理
     * Main.vueで一括保存へ仕様変更したため
     * ここではキャンバス削除と親へのemitのみ
     */
    async remove() {
      // 削除対象のURLがあれば
      if (this.src) {
        this.reset();
        // 削除ボタンを消す
        this.removeButton = false

        // **** Main.vueにて一括保存に仕様変更のためここでは親にemitのみ **** //
        // emitする際に共通のプロパティ名で利用するために 例）case_image1 → caseimage
        // 画像の追加or削除はisDeletedで判断させる
        this.$emit(this.imageTitle.replace(/_/g, "").replace(/[0-9]/g, ""),
          {
            imageTitle: this.imageTitle,
            src: this.src,
            arrayNum: this.arrayNum,
            docFieldTitle: this.docFieldTitle,
            docFieldData: this.docFieldData,
            docFieldImageTitle: this.docFieldImageTitle,
            isDeleted: true // 画像削除
          }
        )
      }
    }
  }
}
</script>
