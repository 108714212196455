<template>
  <div>
    <b-notification aria-close-label="Close notification">
      ページを見た方が予約・問合せをするきっかけとなるサービスや特典を掲載できます。<br>
      <a href="https://docs.google.com/document/d/1m4pLjtRQkCqu3-7LLUfgoEWgSEtbYLSy-woBkw0NYpg/edit?usp=sharing" target="_blanc">オファーの掲載ポイントを見る&nbsp;<span class="icon-external-link"></span></a>
    </b-notification>

    <img class="pb-1" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/offer.png">
    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="offer_catchphrase">
        {{ title.offer_catchphrase }}
      </label>
      <p class="pb-1">
        オファーのサービスをオススメしたい対象者や、オファーのサービスの特長が伝わるフレーズを入力してください。<br>
        例１）いくら頑張っても痩せなかった方へ<br>
        例２）がんこな腰痛でお困りの方へ<br>
        例３）目立たない歯列矯正
      </p>
      <input
        id="offer_catchphrase"
        class="input"
        v-model="pageData['offer_catchphrase']"
        maxlength="20"
        @focus="onFocus('offer_catchphrase')"
        @blur="trim('offer_catchphrase')"
        @input="removeForbiddenString('offer_catchphrase')"
        >
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="offer_limited">{{ title.offer_limited }}</label>
      <p class="pb-1">
        オファーの提供にあたっての条件や補足事項があれば入力してください。<br>
        例１）先着3名様限定<br>
        例２）完全返金保証つき<br>
        例３）初めての方
      </p>
      <b-input id="offer_limited" v-model="pageData['offer_limited']" maxlength="10" @blur="trim('offer_limited')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="offer_title">{{ title.offer_title }}</label>
      <p class="pb-1">
        オファーのサービス名を入力してください。<br>
        例１）ダイエット 無料カウンセリング<br>
        例２）腰痛・ギックリ腰 解消コース（30分）<br>
        例３）歯並びのお悩み 無料相談<br>
        <span class="has-text-danger">※未入力の場合、オファーは表示されません</span>
      </p>
      <input
        id="offer_title"
        class="input"
        v-model="pageData['offer_title']"
        maxlength="30"
        @focus="onFocus('offer_title')"
        @blur="trim('offer_title')"
        @input="removeForbiddenString('offer_title')"
      >
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="offer_price">{{ title.offer_price }}</label>
      <p class="pb-1">
        オファーのサービスの価格などを入力してください。<br>
        例１）2,500円<br>
        例２）通常価格 2,500円 → 初回価格 1,950円（税込）<br>
        例３）当日の施術も予約可能
      </p>
      <b-input id="offer_price" v-model="pageData['offer_price']" maxlength="30" @blur="trim('offer_price')"></b-input>
    </div>

    <div v-if="updateFlg" class="field pb-3">
      <label class="label has-text-weight-bold">{{ title.offer_image }}</label>
      <p class="pb-1">
        どのようなサービスなのかが分かりやすい画像を登録してください。<br>
        ※スマホからでも見やすいように横長のサイズ（横幅4：縦幅3 程度の比率）で文字が入っていない画像がオススメです。
      </p>
      <div class="columns">
        <div v-if="!imageFile.isDeleted" class="column is-one-quarter">
          <img :src="pageData['offer_image']" width="100px">
        </div>
        <div class="column">
          <photo-resize  :image-title="'offer_image'" :src="pageData['offer_image']" @offerimage="imgUpdate" ref="offerimage"/>
        </div>
      </div>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="offer_detail">{{ title.offer_detail }}</label>
      <p class="pb-1">
        オファーのサービスの具体的な説明、サービス提供の流れ、どういう方にオススメか？などを入力してください。<br>
        <br>
        例）健康ダイエットプログラムとは、10年以上の実績があるダイエット法で、少ない食事量で満足感が得られるようになります。最初のカウンセリングで、ダイエットと健康維持の両立に必要な食事指導があり、当日からスタートが可能です。激しい運動やつらい断食などは苦手、でも痩せたいという方に特にオススメです。
      </p>
      <b-input id="offer_detail" v-model="pageData['offer_detail']" maxlength="150" type="textarea" @blur="trim('offer_detail')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="offer_movie">{{ title.offer_movie }}</label>
      <p class="pb-1">
        オファーの内容を説明する動画（YouTubeの動画）のID（赤字の部分）を入力してください。<br>
        ※下記のように IDの後ろに&がついている場合は、&の前までがIDとなります。<br>
        https://www.youtube.com/watch?v=<span class="has-text-danger">z3foLsvz_kg</span>&t=35
      </p>
      <p class="pb-1">
        <a href="https://docs.google.com/document/d/1TmJ2i0Cqj3fC_frtOcyLo7FkmlPcQEJx9cctTPdpGYw/edit?usp=sharing" target="_blank">YouTube動画のIDを確認する方法を見る&nbsp;<span class="icon-external-link"></span></a>
      </p>
      <b-input id="offer_movie" v-model="pageData['offer_movie']" maxlength="11" @blur="trim('offer_movie')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="offer_notice">{{ title.offer_notice }}</label>
      <p class="pb-1">
        予約時やサービスを受ける際の注意事項、顧客のリスクを軽減できる内容（返金保証、アフターフォローなど）、安心して予約、問合せができる後押しになる内容を入力してください。<br>
        <br>
        例）○○コースのご予約は1日3名様限定とさせていただいております。まずは、ご希望の日時をお知らせください。
      </p>
      <b-input id="offer_notice" v-model="pageData['offer_notice']" maxlength="150" type="textarea" @blur="trim('offer_notice')"></b-input>
    </div>

    <div class="field pb-1">
      <label class="label has-text-weight-bold" for="offer_link_url">{{ title.offer_link_url }}</label>

<!--
      <img class="pb-3" src="https://admin-ilb.imgix.net/ilbnet-jp/link_url.png?auto=format&fit=clip&w=640&border=1,888888">
-->

      <p class="pb-1">
        オファーからリンクさせたいURLを入力してください。<br>
        ※リンクを表示するためには「オファーのリンク表示名」の入力も必要です。
      </p>
      <b-input id="offer_link_url" v-model="pageData['offer_link_url']" @blur="trim('offer_link_url')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="offer_link_text">{{ title.offer_link_text }}</label>
      <p class="pb-1">
        例１）オンライン予約する<br>
        例２）UberEatsで注文する<br>
        例３）オンラインショップを見る<br>
        ※「オファーのリンクURL」の入力も必要です。
      </p>
      <b-input id="offer_link_text" v-model="pageData['offer_link_text']" maxlength="15" @blur="trim('offer_link_text')"></b-input>

      <div v-if="pageData['offer_link_url'] && pageData['offer_link_text'] ">
        下記のリンクをクリックし、入力したオファーのリンクが正しいか？確認できます。<br>
        <a :href="pageData['offer_link_url']" target="_blank">
          {{ pageData['offer_link_text'] }}
        </a>
      </div>
    </div>

  </div>
</template>

<script>
import 'bulma/css/bulma.css'
import PhotoResize from '@/components/PhotoResize.vue'

export default {
  props: ['doc','title'],
  computed: {
    pageData: {
      get() {
        return this.doc;
      },
      set(data) {
        this.$emit('doc', data);
      }
    },
  },
  components: {
    'PhotoResize': PhotoResize,
  },
  methods: {
    onFocus : function(field) {
      this[field + '_focus'] = true
    },
    // photoResize→親（Main.vue）へ渡す画像用配列：imageFileData
    imgUpdate: function (imageFileData) {
      this.imageFile = imageFileData
      // 子コンポーネント画面再描画用
      this.updateFlg = Math.random()
      this.$emit('imgUpdate', imageFileData);
    },
    // 親（Main.vue）→photoResize.reset(画面上の画像表示削除させる)
    reset : function() {
      this.$refs.offerimage.reset()
    }
  },
  data () {
    return {
      isOpen : true,
      imageFile: {},
      updateFlg: true // 再描画用
    }
  }
}

</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
.is-clearfix::after {
  display: contents !important;
}

</style>
