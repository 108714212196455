<template>
  <section class="row payment-form has-text-centered">
    <h6><b> 決済を再開する </b></h6>
    <h6>{{ admindata.service_name.page.name }}</h6>
    <h6>月額{{ chargesdata.page.price.toLocaleString() }}円（税込）</h6>
    <div class="has-text-centered">
      <p class="help">10%対象(税抜)：{{ tax_excluded_price.toLocaleString() }}円</p>
      <p class="help">10%対象消費税：{{ tax.toLocaleString() }}円</p>
    </div>
    <br />
    <div>
      <p>{{ pagedata['business_name'] }} {{ pagedata['branch_name'] }}</p>
      <a :href="'https://jp.ilb.net/' + pagedata['page_id']" target="_blank"> https://jp.ilb.net/{{ pagedata['page_id'] }} </a>
      <p>決済を再開すると次回以降のご利用料金の決済が再開されます。</p>
    </div>

    <div class="col s12 place-order-button-block">
      <b-button v-if="success === false" class="btn col s12 #e91e63 pink" type="is-success has-text-weight-bold" @click="save()"> 決済を再開する </b-button>
      <b-button v-if="success === 'loading'" loading>Loading</b-button>
    </div>
    <br />
    <p class="help has-text-centered mt-0">登録番号：T6010001124948</p>
    <p class="help has-text-centered mt-0">株式会社ILB</p>
    <p class="mt-2 has-text-centered">
      <a href="#" @click="modalClose">管理画面に戻る</a>
    </p>
  </section>
</template>

<script>
import Config from '../../config.js';

export default {
  props: ['pagedata', 'chargesdata', 'admindata'],
  data() {
    return {
      success: false,
      isLoading: false,
    };
  },
  computed: {
    tax() {
      return Math.floor(this.chargesdata.page.price ? this.chargesdata.page.price - this.chargesdata.page.price / (1 + Config.TAX_RATE) : 0);
    },
    tax_excluded_price() {
      return this.chargesdata.page.price ? this.chargesdata.page.price - this.tax : 0;
    },
  },
  methods: {
    async save() {
      this.success = 'loading';
      this.isLoading = true;

      const payment_date = this.chargesdata.page && this.chargesdata.page.last_payment_date ? this.chargesdata.page.last_payment_date.split('/')[2] : '';

      // last_payment_dateの日付をpayment_dateに入れる
      try {
        await Config.CHARGES_COLLECTION.doc(this.pagedata.page_id).set(
          {
            page: {
              payment_date: payment_date,
            },
          },
          { merge: true }
        );
      } catch (e) {
        this.$swal('error', '保存エラー：' + e, 'error');
        return;
      }

      const result = await this.$swal.fire({
        icon: 'success',
        title: '決済が再開されました',
        html: this.$store.state.admin_data.service_name['page'].name + '<br>月額' + this.chargesdata.page.price.toLocaleString() + '円（税込）' + '<br>決済日：' + payment_date + '日',
        confirmButtonText: '管理画面に戻る',
        allowOutsideClick: false,
      });

      if (result.isConfirmed) {
        this.success = false;
        this.isLoading = false;
        this.modalClose();
        return;
      }
    },
    modalClose() {
      this.$emit('modalclose');
    },
  },
};
</script>

<style scoped>
.payment-form {
  max-width: 400px;
  margin: 20px auto;
  border: 2px solid #ececec;
  padding: 12px;
  margin-bottom: 8px;
}
.place-order-button-block {
  margin: 5px 0;
  text-align: center;
}
button {
  background-color: #23d160;
  color: white;
  border-radius: 4px;
  font-size: 20px;
  /* font-weight: 600; */
  border: 0;
  box-shadow: none;
  padding: 8px 24px;
}
</style>
