<template>
  <section>
    <div v-if="plan_change">
      <Complete :plan_change="plan_change" :pagedata="pagedata" />
    </div>
    <div v-else>
      <h1 class="is-size-4 has-text-weight-bold py-5">決済を停止する</h1>

      <div class="mb-3">
        <p class="mb-1 is-size-3">{{ pagedata['business_name'] }} {{ pagedata['branch_name'] }}</p>
        <p>{{ destination_url }}/{{ pagedata['page_id'] }}</p>
      </div>


      <div v-if="chargesdata && chargesdata.page.payment_date" class="has-text-danger">
        <p>{{ expired(chargesdata.page.last_payment_date) }}を過ぎると、ページが非公開となります。</p>
        <br />
        <p>転送用URLも削除となります。</p>
        <p>チラシやWebサイト、各種SNSなどにページURLをご利用の場合は、リンク切れになるのでご注意ください。</p>
      </div>

      <div class="col s12 place-order-button-block">
        <b-button v-if="success === false" class="btn col s12 #e91e63 pink" type="is-success has-text-weight-bold" @click="save()"> 決済を停止する </b-button>
        <b-button v-if="success === 'loading'" loading>Loading</b-button>
        <b-loading :is-full-page="isFullPage" :active.sync="isLoading"></b-loading>
      </div>
    </div>
  </section>
</template>

<script>
import Config from '../../config.js';
import moment from 'moment';
import Complete from '@/components/payment/Complete.vue';

export default {
  props: ['pagedata', 'chargesdata'],
  components: {
    Complete,
  },
  data() {
    return {
      success: false,
      isLoading: false,
      isFullPage: true,
      plan_change: '',
    };
  },
  computed: {
    destination_url () {
      return Config.DESTINATION_URL;
    },
  },
  methods: {
    // 入力された日付に31日を加算して返してYMD形式に変換する
    expired(date) {
      return date ? moment(new Date(date).toISOString()).add(31, 'days').format('YYYY-MM-DD') : '';
    },
    // 無料プランへ変更
    async save() {
      this.success = 'loading';
      this.isLoading = true;

      try {
        // chargesデータを変更する
        await Config.CHARGES_COLLECTION.doc(this.$store.state.page_id).set(
          {
            page: {
              payment_date: '',
            },
          },
          { merge: true }
        );
      } catch (e) {
        this.$swal('error', '保存エラー：' + e, 'error');
        return;
      }

      // 完了画面を表示させる
      this.plan_change = 'downgrade';

      this.success = false;
      this.isLoading = false;

      // 無料プランへ戻したことをメールする
      // await this.PlanDowngradeMail()
      await this.PlanDowngradeMailToClient();

      // Menu上の表記を変更するためにemit
      this.$emit('close');

      // const result = await this.$swal.fire(
      //   {
      //     icon: "success",
      //     title: '決済停止完了',
      //     text: '最終のお支払い日から１ヶ月後に無料プランの掲載内容に変更となります',
      //     confirmButtonText: '管理画面に戻る',
      //     allowOutsideClick: false
      //   })

      // if (result.isConfirmed) {
      //   this.success = false
      //   this.isLoading = false
      //   return
      // }
    },
    // async PlanDowngradeMail() {
    //   let data = {
    //     template_id : 'MANUAL_CHANGE_PLAN_FREE_SUCCESS',
    //     business_name: this.pagedata.business_name,
    //     branch_name: this.pagedata.branch_name || '',
    //     status: 'sending',
    //     date: moment().format("YYYY-MM-DD HH:MM:SS"),
    //     page_id: this.$store.state.page_id,
    //     name: this.$store.state.admin_data.service_name.page.name,
    //     short_name: this.$store.state.admin_data.service_name.page.short_name,
    //     system_name: this.$store.state.admin_data.service_name.page.system_name,
    //     country_code: this.$store.state.admin_data.service_name.url.country_code,
    //     tld: this.$store.state.admin_data.service_name.url.tld,
    //   }
    //   await Config.SENDMAIL_TARGET_COLLECTION.add(data);
    // },
    async PlanDowngradeMailToClient() {
      let data = {
        template_id: 'MANUAL_CHANGE_PLAN_FREE_TO_CLIENT_SUCCESS',
        to: this.$store.state.user.email,
        email_auth: this.$store.state.user.email,
        business_name: this.pagedata.business_name,
        branch_name: this.pagedata.branch_name || '',
        category: this.pagedata.category,
        page_keyword: this.pagedata.page_keyword,
        last_payment_date: this.chargesdata.page.last_payment_date,
        status: 'sending',
        date: moment().format('YYYY-MM-DD HH:MM:SS'),
        expiration_date: moment(new Date(this.chargesdata.page.last_payment_date).toISOString()).add(31, 'days').format('YYYY/MM/DD'),
        page_id: this.$store.state.page_id,
        name: this.$store.state.admin_data.service_name.page.name,
        short_name: this.$store.state.admin_data.service_name.page.short_name,
        system_name: this.$store.state.admin_data.service_name.page.system_name,
        country_code: this.$store.state.admin_data.service_name.url.country_code,
        tld: this.$store.state.admin_data.service_name.url.tld,
      };
      await Config.SENDMAIL_TARGET_COLLECTION.add(data);
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 1.875em;
}

.payment-form {
  max-width: 500px;
  margin: 20px auto;
  padding: 12px;
  margin-bottom: 8px;
}

.place-order-button-block {
  margin: 5px 0;
  text-align: center;
}

button {
  background-color: #23d160;
  color: white;
  border-radius: 4px;
  font-size: 20px;
  width: -webkit-fill-available;
  /* font-weight: 600; */
  border: 0;
  box-shadow: none;
  padding: 8px 24px;
}
</style>
