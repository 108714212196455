<template>
  <section class="section container">

    <div class="columns">
      <div class="column is-3">
        <Menu @getdoc='getPageId' @gettitle='getPageTitle' />
      </div>

      <div class="column">
        <HowToAttractCustomers
          :page-data="doc"
          :ads-data="ads_doc"
          :charges-data="charges_doc"
          :item-names="itemNames"
          @close="modalClose"
          />
      </div>
    </div>
  </section>
</template>

<script>

import HowToAttractCustomers from './report_board/modules/HowToAttractCustomers'
import Menu from '@/components/Menu.vue'

export default {
  mixins: [Menu],
  props: {
  },
  components: {
    HowToAttractCustomers,
    Menu,
  },
  data() {
    return {
      isLoading: false,
      targetDate: new Date(),
      itemNames: {},
      doc: {},
    }
  },
  methods: {
    getPageId (value) {
      this.doc = value
    },
    getPageTitle (value) {
      this.itemNames = value
    },
    modalClose () {
      console.log('PageConversion.vue modalClose()')
      this.listenData(this.doc.page_id)
      this.closeModal()
    }
  },
}
</script>

<style>
</style>
