export const category_group ={
    '美容サロン': '美容サロン',
    '健康サロン': '健康サロン',
    '医療機関': '医療機関',
    '専門サービス': '専門サービス',
    '教室・習い事': '教室・習い事',
    '幼児教育': '幼児教育',
    'スポーツ教室': 'スポーツ教室',
    '生活関連サービス': '生活関連サービス',
    '飲食店': '飲食店',
    '食料品店': '食料品店',
    'ショッピング': 'ショッピング',
    'ファッション': 'ファッション',
    'スポーツ用品店': 'スポーツ用品店',
    'ペット': 'ペット',
    '冠婚葬祭': '冠婚葬祭',
    '宿泊施設': '宿泊施設',
    'レジャー': 'レジャー',
    '自動車・バイク': '自動車・バイク',
    '福祉・介護': '福祉・介護',
    '団体': '団体',
    'その他': 'その他',
    }
    
  export const category ={
    '美容サロン':{
      '美容サロン':'美容サロン',
      '理容室':'理容室',
      '美容室':'美容室',
      'ネイルサロン':'ネイルサロン',
      'まつげエクステサロン':'まつげエクステサロン',
      'まつげパーマサロン':'まつげパーマサロン',
      'リラクゼーションサロン':'リラクゼーションサロン',
      'アロマテラピーサロン':'アロマテラピーサロン',
      'エステティックサロン':'エステティックサロン',
      'ダイエットサロン':'ダイエットサロン',
      '脱毛サロン':'脱毛サロン',
      '日焼けサロン':'日焼けサロン',
      '小顔矯正サロン':'小顔矯正サロン',
      '育毛サロン':'育毛サロン',
      '発毛サロン':'発毛サロン',
      '増毛サロン':'増毛サロン'
    },
    '健康サロン':{
      '健康サロン':'健康サロン',
      '整骨院':'整骨院',
      '整体院':'整体院',
      '鍼灸院':'鍼灸院',
      'カイロプラクティック':'カイロプラクティック'
    },
    '医療機関':{
      '総合病院':'総合病院',
      '医院':'医院',
      '歯科医院':'歯科医院',
      '矯正歯科':'矯正歯科',
      '美容外科':'美容外科',
      '美容皮膚科':'美容皮膚科',
      '美容クリニック':'美容クリニック',
      '医療脱毛':'医療脱毛',
      '内科':'内科',
      '外科':'外科',
      '小児科':'小児科',
      '産婦人科':'産婦人科',
      '眼科':'眼科',
      '耳鼻咽喉科':'耳鼻咽喉科',
      'アレルギー科':'アレルギー科',
      '皮膚科':'皮膚科',
      '整形外科':'整形外科',
      '形成外科':'形成外科',
      '消化器科':'消化器科',
      '泌尿器科':'泌尿器科',
      '呼吸器科':'呼吸器科',
      '循環器科':'循環器科',
      '肛門科':'肛門科',
      '心臓血管外科':'心臓血管外科',
      '脳神経外科':'脳神経外科',
      '心療内科':'心療内科',
      '神経内科':'神経内科',
      '精神神経科':'精神神経科',
      '放射線科':'放射線科',
      '麻酔科':'麻酔科',
      'リウマチ科':'リウマチ科',
      '性病科':'性病科',
      'リハビリテーション科':'リハビリテーション科',
      '人間ドック':'人間ドック'
    },
    '専門サービス':{
      '弁護士事務所':'弁護士事務所',
      '弁理士事務所':'弁理士事務所',
      '司法書士事務所':'司法書士事務所',
      '公認会計士事務所':'公認会計士事務所',
      '税理士事務所':'税理士事務所',
      '社会保険労務士事務所':'社会保険労務士事務所',
      '行政書士事務所':'行政書士事務所',
      '土地家屋調査士事務所':'土地家屋調査士事務所',
      '不動産鑑定士事務所':'不動産鑑定士事務所',
      '経営コンサルタント':'経営コンサルタント',
      'コンサルティング会社':'コンサルティング会社',
      'カウンセリング':'カウンセリング',
      '広告代理店':'広告代理店',
      'ウェブ制作会社':'ウェブ制作会社',
      'デザイン会社':'デザイン会社',
      'ビジネスセミナー':'ビジネスセミナー',
      'ビジネススクール':'ビジネススクール',
      '探偵事務所':'探偵事務所'
    },
    '教室・習い事':{
      'スクール':'スクール',
      'ネイルスクール':'ネイルスクール',
      'まつ毛スクール':'まつ毛スクール',
      'エステスクール':'エステスクール',
      'アロマスクール':'アロマスクール',
      'ヨガ講師スクール':'ヨガ講師スクール',
      'ピラティス講師スクール':'ピラティス講師スクール',
      'セラピストスクール':'セラピストスクール',
      '学習塾':'学習塾',
      '予備校':'予備校',
      '家庭教師':'家庭教師',
      'パソコン教室':'パソコン教室',
      '外国語教室':'外国語教室',
      '英語教室':'英語教室',
      'そろばん教室':'そろばん教室',
      '書道教室':'書道教室',
      '華道教室':'華道教室',
      '茶道教室':'茶道教室',
      '着付け教室':'着付け教室',
      '和裁教室':'和裁教室',
      '洋裁教室':'洋裁教室',
      '料理教室':'料理教室',
      'お菓子教室':'お菓子教室',
      'パン教室':'パン教室',
      '音楽教室':'音楽教室',
      'ピアノ教室':'ピアノ教室',
      'ボイストレーニング':'ボイストレーニング',
      'ギター教室':'ギター教室',
      '絵画教室':'絵画教室',
      '手芸教室':'手芸教室',
      'フラワー教室':'フラワー教室',
      '工芸教室':'工芸教室',
      '陶芸教室':'陶芸教室',
      'カルチャースクール':'カルチャースクール',
      'ベビーマッサージ':'ベビーマッサージ',
      '心理学':'心理学',
      '日本舞踊':'日本舞踊'
    },
    '幼児教育':{
      '託児所':'託児所',
      '保育園':'保育園',
      '幼稚園':'幼稚園',
      'こども園':'こども園',
      '幼児教室':'幼児教室',
      'インターナショナルスクール':'インターナショナルスクール'
    },
    'スポーツ教室':{
      'テニススクール':'テニススクール',
      '野球スクール':'野球スクール',
      'スイミングスクール':'スイミングスクール',
      'サッカースクール':'サッカースクール',
      'ヨガ教室':'ヨガ教室',
      'ピラティス教室':'ピラティス教室',
      'ダンス教室':'ダンス教室',
      'バレエ教室':'バレエ教室',
      'フィットネスジム':'フィットネスジム',
      'スポーツクラブ':'スポーツクラブ',
      '加圧トレーニング':'加圧トレーニング',
      'ボクシングジム':'ボクシングジム',
      'キックボクシングジム':'キックボクシングジム',
      '卓球教室':'卓球教室',
      '健康教室':'健康教室',
      '体操教室':'体操教室',
      '空手教室':'空手教室',
      '柔道教室':'柔道教室',
      '乗馬教室':'乗馬教室',
      'ゴルフスクール':'ゴルフスクール',
      'ヨットスクール':'ヨットスクール',
      'ダイビングスクール':'ダイビングスクール'
    },
    '生活関連サービス':{
      '不動産会社':'不動産会社',
      'リフォーム会社':'リフォーム会社',
      '工務店':'工務店',
      '設計事務所':'設計事務所',
      'ハウスクリーニング':'ハウスクリーニング',
      '害虫駆除':'害虫駆除',
      '鍵屋':'鍵屋',
      '水道修理':'水道修理',
      '不用品回収':'不用品回収',
      '家事代行サービス':'家事代行サービス',
      '便利屋':'便利屋',
      '引越しサービス':'引越しサービス',
      'パソコン修理':'パソコン修理',
      'スマートフォン修理':'スマートフォン修理',
      'クリーニング':'クリーニング',
      'コインランドリー':'コインランドリー',
      'フォトスタジオ':'フォトスタジオ',
      'レンタルスペース':'レンタルスペース',
      'レンタルサービス':'レンタルサービス',
      '旅行代理店':'旅行代理店'
    },
    '飲食店': {
      'イタリアン':'イタリアン',
      'フレンチ':'フレンチ',
      '洋食':'洋食',
      '西洋料理':'西洋料理',
      '中華料理':'中華料理',
      'アジア料理':'アジア料理',
      'エスニック料理':'エスニック料理',
      'カレー':'カレー',
      '各国料理':'各国料理',
      '和食':'和食',
      '郷土料理':'郷土料理',
      '鍋':'鍋',
      'しゃぶしゃぶ':'しゃぶしゃぶ',
      'すし':'すし',
      'そば':'そば',
      'うどん':'うどん',
      'お好み焼き':'お好み焼き',
      'もんじゃ焼き':'もんじゃ焼き',
      'たこ焼き':'たこ焼き',
      'とんかつ':'とんかつ',
      'ラーメン':'ラーメン',
      '焼肉':'焼肉',
      '鉄板焼き':'鉄板焼き',
      'ステーキ':'ステーキ',
      '焼鳥':'焼鳥',
      '居酒屋':'居酒屋',
      'バー':'バー',
      'ダイニングバー':'ダイニングバー',
      'スイーツ':'スイーツ',
      'パン':'パン',
      'カフェ':'カフェ',
      '喫茶店':'喫茶店',
      '弁当':'弁当',
      'ファーストフード':'ファーストフード'
    },
    '食料品店':{
      '米屋':'米屋',
      '魚屋':'魚屋',
      '肉屋':'肉屋',
      '八百屋':'八百屋',
      '乾物屋':'乾物屋',
      '食料品店':'食料品店',
      '乳製品':'乳製品',
      'お茶屋':'お茶屋',
      '酒屋':'酒屋',
      'スイーツ':'スイーツ',
      '和菓子':'和菓子'
    },
    'ショッピング':{
      'スーパー':'スーパー',
      'コンビニエンスストア':'コンビニエンスストア',
      'デパート':'デパート',
      'ショッピングセンター':'ショッピングセンター',
      'ホームセンター':'ホームセンター',
      'ドラッグストア':'ドラッグストア',
      'ディスカウントショップ':'ディスカウントショップ',
      'リサイクルショップ':'リサイクルショップ',
      'チケット店':'チケット店',
      '買取専門店':'買取専門店',
      '本屋':'本屋',
      '古本屋':'古本屋',
      '携帯ショップ':'携帯ショップ',
      '時計屋':'時計屋',
      'メガネ屋':'メガネ屋',
      '化粧品店':'化粧品店',
      '自転車屋':'自転車屋',
      'おもちゃ屋':'おもちゃ屋',
      'ベビー用品店':'ベビー用品店',
      '文房具店':'文房具店',
      '雑貨店':'雑貨店',
      '家具屋':'家具屋',
      '花屋':'花屋',
      '包装資材店':'包装資材店',
      '質店':'質店',
      '楽器店':'楽器店'
    },
    'ファッション':{
      'ファッション':'ファッション',
      'バッグ':'バッグ',
      'アクセサリー':'アクセサリー',
      '靴販売':'靴販売',
      '衣料品':'衣料品',
      '紳士服':'紳士服',
      '婦人服':'婦人服',
      '呉服':'呉服',
      '貸衣装':'貸衣装',
      '古着屋':'古着屋'
    },
    'スポーツ用品店':{
      'スポーツショップ':'スポーツショップ',
      'サーフショップ':'サーフショップ',
      'ダイビングショップ':'ダイビングショップ',
      'スキー用品店':'スキー用品店',
      '登山用品店':'登山用品店',
      '釣具店':'釣具店',
      'アウトドア用品店':'アウトドア用品店',
      'ゴルフ用品店':'ゴルフ用品店',
      'テニス用品店':'テニス用品店',
      '武道用品店':'武道用品店'
    },
    'ペット':{
      '動物病院':'動物病院',
      'ペットショップ':'ペットショップ',
      'トリミングサロン':'トリミングサロン',
      'ペットホテル':'ペットホテル',
      'ドッグラン':'ドッグラン',
      'ペットサービス':'ペットサービス'
    },
    '冠婚葬祭':{
      '結婚式場':'結婚式場',
      '結婚相談所':'結婚相談所',
      'ブライダルサービス':'ブライダルサービス',
      '葬儀場':'葬儀場',
      '葬祭業':'葬祭業'
    },
    '宿泊施設':{
      'ホテル':'ホテル',
      '旅館':'旅館',
      '民宿':'民宿',
      'ペンション':'ペンション'
    },
    'レジャー':{
      'レジャー施設':'レジャー施設',
      '映画館':'映画館',
      '劇場':'劇場',
      '寄席':'寄席',
      'ライブハウス':'ライブハウス',
      'クラブ':'クラブ',
      'カラオケ':'カラオケ',
      'ゲームセンター':'ゲームセンター',
      'ビリヤード':'ビリヤード',
      'ボウリング場':'ボウリング場',
      'インターネットカフェ':'インターネットカフェ',
      'テーマパーク':'テーマパーク',
      '遊園地':'遊園地',
      '天文台':'天文台',
      'プラネタリウム':'プラネタリウム',
      'キャンプ場':'キャンプ場',
      '動物園':'動物園',
      '植物園':'植物園',
      '水族館':'水族館',
      '道の駅':'道の駅',
      '温泉':'温泉',
      '銭湯':'銭湯',
      'シミュレーションゴルフ':'シミュレーションゴルフ',
      'ゴルフ場':'ゴルフ場',
      'アウトドア':'アウトドア',
      'フットサルコート':'フットサルコート'
    },
    '自動車・バイク':{
      '自動車販売':'自動車販売',
      '中古車販売':'中古車販売',
      'バイク販売':'バイク販売',
      '中古バイク販売':'中古バイク販売',
      'レンタカー':'レンタカー',
      'レンタルバイク':'レンタルバイク',
      'ガソリンスタンド':'ガソリンスタンド',
      '自動車教習所':'自動車教習所',
      '自動車修理':'自動車修理',
    },
    '福祉・介護':{
      '児童福祉施設':'児童福祉施設',
      '児童福祉サービス':'児童福祉サービス',
      '介護施設':'介護施設',
      '介護サービス':'介護サービス',
      '福祉施設・サービス':'福祉施設・サービス'
    },
    '団体':{
      '政治団体':'政治団体',
      '宗教団体':'宗教団体',
      '地域団体':'地域団体',
      '神社':'神社',
      '寺院':'寺院',
      '教会':'教会',
      'NGO':'NGO',
      'NPO':'NPO',
      'イベント業':'イベント業'
    }
}