<template>
<section class="section container">

  <div class="columns">
    <div class="column is-3">
      <Menu @getdoc="getPageId" :modalstatus="modal" />
    </div>

    <div class="column table-scroll">

      <h1 class="is-size-4 has-text-weight-bold py-1">レポート</h1>
      <div class="report-title">
        ページの閲覧数（PV数）、反応数（CV数）、反応率（CV率）、Google広告の広告費（利用金額）などを確認できます。<br />
      </div>
      <hr>

      <ReportBoard :page-data="doc" @close="modalClose()"/>
    </div>
  </div>

</section>
</template>

<script>
import 'bulma/css/bulma.css'
import ReportBoard from '@/components/report_board/ReportBoard';
import Menu from '@/components/Menu.vue'

export default {
  mixins: [Menu],
  components: {
    ReportBoard,
    Menu,
  },
  data () {
    return {
      doc: {},
      modal: true
    }
  },
  methods: {
    getPageId (value) {
      this.doc = value
    },
    modalClose () {
      console.log('Report.vue modalClose()')
      this.listenData(this.doc.page_id)
      this.closeModal()
    }
  },
}
</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
</style>
