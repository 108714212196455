<template>
  <div class="column is-4 is-offset-4">

    <h1 class="is-size-4 has-text-weight-bold has-text-centered">ログイン画面</h1>
    <p class="has-text-centered pb-3">ローカルマーケティングマネージャー</p>

    <div class="field">
      <p class="control">
        <input class="input" type="email" placeholder="メールアドレス" v-model="email">
      </p>
    </div>
    <div class="field">
      <p class="control">
        <input class="input" type="password" placeholder="パスワード" v-model="password">
      </p>
    </div>
    <div class="field">
      <p class="control">
        <button class="button is-success" type="button" @click="login">ログイン</button>
<!--
        <button class="button is-primary" type="button" @click="signup">新規登録</button>
-->
      </p>
      <div>
        <div id="firebaseui-auth-container"></div>
        <div id="loader">Now Loading...</div>
      </div>
    </div>
    <div class="field pt-2">
      <p class="control">
        <a href="passwordreset">パスワードを忘れた場合はコチラ</a>
      </p>
      <p class="control pb-2">
        <a href="https://docs.google.com/document/d/1n1Rx-msKimxd2hnjbK781Ucec3odvu6WpTKQuGpZ8yM/edit">ログインメールアドレスを忘れた場合はコチラ</a>
      </p>
      <p class="control">
        <a href="https://jp.ilb.net/000000003#contact">その他の操作でお困りの場合はコチラ</a>
      </p>
    </div>

    <hr>

    <div class="help has-text-centered">
      <a href="https://docs.google.com/document/d/1dDwiiCIn9qtttsSwraAFYzHQCZGsuHQhupXCSYfCF4g/edit" target="_blank">利用規約</a>&nbsp;/&nbsp;
      <a href="https://docs.google.com/document/d/1wZGf8UOpquTPE3WydR2EhkN2Usju1fd9Ei_CCAaFG9A/edit" target="_blank">サイトポリシー</a>&nbsp;/&nbsp;
      <a href="https://docs.google.com/document/d/1iDNBNN-hncr_Jo09e8TCumFR8w9UBX6a8rJBtThxHCM/edit" target="_blank">プライバシーポリシー</a>&nbsp;/&nbsp;
      <a href="https://www.ilb.net/" target="_blank">運営会社</a>
    </div>

  </div>
</template>
<script>
import firebaseui from 'firebaseui-ja'
import 'firebaseui-ja/dist/firebaseui.css'
import Config from '../config.js'
import firebase from 'firebase/app'

export default {
  mounted () {
    const vm = this
    let ui = firebaseui.auth.AuthUI.getInstance()
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(Config.FIREBASE_AUTH)
    }
    ui.start('#firebaseui-auth-container', {
      callbacks: {
        uiShown: function () {
          document.getElementById('loader').style.display = 'none'
        },
        signInSuccessWithAuthResult: function (authResult) {
          const isNewUser = authResult.additionalUserInfo.isNewUser

          // redirectUrl

          // SNSログイン＆で登録済みであればスキップ
          if (!isNewUser) {
            return true
          }
        },
        signInFailure: function (error) {
          //          console.log("signInFailure", error)
          alert(error.message)
          vm.$router.go()
        }
      },
      autoUpgradeAnonymousUsers: true,
      signInFlow: 'redirect',
      signInSuccessUrl: '/',
      signInOptions: [
/*
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        firebase.auth.GithubAuthProvider.PROVIDER_ID,
        firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
*/
      ]
    })
  },
  data () {
    return {
      email: '',
      password: '',
    }
  },
  methods: {
    login () {
      let swal = this.$swal
      const instance = this
      Config.FIREBASE_AUTH
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          // New sign-in will be persisted with session persistence.
          return firebase.auth().signInWithEmailAndPassword(this.email, this.password);
        })
          // .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
            let redirect_uri = this.$route.query.redirect ? this.$route.query.redirect : '/main'
            if (this.$route.query.redirect === '/') {
                redirect_uri = '/main'
            }
            this.$router.push(redirect_uri)
        })
        .catch(e => {
          if (e.message === 'The password is invalid or the user does not have a password.') {
            swal('warning','正しいパスワードを入力してください', 'warning')
          } else if (e.message === 'There is no user record corresponding to this identifier. The user may have been deleted.') {
            swal('warning', '入力されたメールアドレスは登録されていません', 'warning')
          } else if (e.message === 'The user account has been disabled by an administrator.') {
            swal.fire({
              title: '',
              text: 'すでに削除されたアカウントです。別のメールアドレスで新規登録してください。',
              icon: 'warning',
              confirmButtonText: '新規登録する',
            }).then(function(val) {
              if (val) {
                  instance.$router.push('/signup').catch(err => { console.log(err)})
              }
            })
          } else {
            swal('warning', e, 'warning')
          }
        })
    },
  }
}
</script>
