<template>
  <div>
    <b-notification aria-close-label="Close notification">
      御社スタッフのプロフィールを掲載できます。
    </b-notification>

    <img class="pb-3" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbnet-jp/staff.png">

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="staff_position" >{{ title.staff_position }}</label>
      <p class="pb-1">
        例１）代表<br>
        例２）店長（院長、支店長、主任など）<br>
        例３）カウンセラー
      </p>
      <b-input id="staff_position" v-model="pageData['staff_position']" maxlength="20" @blur="trim('staff_position')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="staff_name">{{ title.staff_name }}</label>
      <p class="pb-1">
        例）山田 花子<br>
        <span class="has-text-danger">※未入力の場合、スタッフ紹介は表示されません</span>
      </p>
      <b-input id="staff_name" v-model="pageData['staff_name']" @blur="trim('staff_name')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="staff_name_ja">{{ title.staff_name_ja }}</label>
      <p class="pb-1">
        例）ヤマダ ハナコ<br>
      </p>
      <b-input id="staff_name_ja" v-model="pageData['staff_name_ja']" @blur="trim('staff_name_ja')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="staff_qualification">{{ title.staff_qualification }}</label>
      <p class="pb-1">
        例１）管理栄養士、認定エステティシャン<br>
        例２）柔道整復師、はり師、きゅう師<br>
        例３）歯科医師
      </p>
      <b-input id="staff_qualification" v-model="pageData['staff_qualification']" maxlength="100" @blur="trim('staff_qualification')"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="staff_career">{{ title.staff_career }}</label>
      <p class="pb-1">
        例）1958年福岡県生まれ、福岡柔整学園を卒業後、都内の整形外科に5年間勤務。1995年、アイエルビー整骨院をオープン。
      </p>
      <b-input id="staff_career" v-model="pageData['staff_career']" maxlength="100" type="textarea" @blur="trim('staff_career')"></b-input>
    </div>

    <div v-if="updateFlg" class="field pb-3">
      <label class="label has-text-weight-bold">{{ title.staff_image }}</label>
      <p class="pb-1">
        スタッフの写真を登録してください。<br>
        ※スマホからでも見やすいように横長のサイズ（横幅4：縦幅3 程度の比率）で文字が入っていない画像がオススメです。
      </p>
      <div class="columns">
        <div v-if="!imageFile.isDeleted" class="column is-one-quarter">
          <img :src="pageData['staff_image']" width="100px">
        </div>
        <div class="column">
          <photo-resize :image-title="'staff_image'" :src="pageData['staff_image']" @staffimage="imgUpdate" ref="staffimage"/>
        </div>
      </div>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="staff_message">{{ title.staff_message }}</label>
      <p class="pb-1">
        初めてページを見た方へのメッセージを入力してください。<br>
        入力しておきたい内容は、店舗（会社）をはじめた「経緯」や「コンセプト」、「提供サービスの簡単な紹介」などです。<br>
        <br>
        例）院長の山田です。整形外科勤務時代に、最も多かったお悩みである「腰痛」に特化した施術を行っています。腰痛・ぎっくり腰に関するお悩みを解消したい方は、「腰痛・ギックリ腰 解消コース」を一度お試しください。
      </p>
      <b-input id="staff_message" v-model="pageData['staff_message']" maxlength="100" type="textarea" @blur="trim('staff_message')"></b-input>
    </div>

    <div class="field">
      <label class="label has-text-weight-bold" for="staff_viewmore">{{ title.staff_viewmore }}</label>
      <p class="pb-1">
        スタッフの詳細が掲載されているページのURLを入力してください。
      </p>
      <b-input id="staff_viewmore" v-model="pageData['staff_viewmore']" @blur="trim('staff_viewmore')"></b-input>
    </div>
  </div>

</template>

<script>
import 'bulma/css/bulma.css'
import PhotoResize from '@/components/PhotoResize.vue'

export default {
  props: ['doc','title'],
  computed: {
    pageData: {
      get() {
        return this.doc;
      },
      set(data) {
        this.$emit('doc', data);
      }
    }
  },
  components: {
    'PhotoResize': PhotoResize
  },
  methods: {
    // photoResize→親（Main.vue）へ渡す画像用配列：imageFileData
    imgUpdate: function (imageFileData) {
      this.imageFile = imageFileData
      // 子コンポーネント画面再描画用
      this.updateFlg = Math.random()
      this.$emit('imgUpdate', imageFileData);
    },
    // 親（Main.vue）→photoResize.reset(画面上の画像表示削除させる)
    reset : function(){
      this.$refs.staffimage.reset()
    }
  },
  data () {
    return {
      imageFile:{},
      updateFlg:true // 再描画用
    }
  }
}
</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
</style>
