<template>
    <section>
      <div class="opening-component">
        <div v-for="(day_data, day_num) in opening" :key="day_num">
          <b-field grouped group-multiline>
            <div class="control">
              {{ ['日曜','月曜','火曜','水曜','木曜','金曜','土曜','祝'][day_num.replace('day','')] }}日
            </div>
            <div class="control">
              <b-switch v-model= "day_data['open_day']" @click="day_data['open_day']=!day_data['open_day']" aria-controls="openclosetimearea" @input='onBlur'>
                <strong v-if="day_data['open_day']">営業日</strong>
                <strong v-else>定休日</strong>
              </b-switch>
            </div>
            <b-collapse :open.sync="day_data['open_day']" animation="slide" aria-id="openclosetimearea">
              <div class="notification">
                <div class="content" v-for="(hours, key) in day_data['open_hours']" :key="key">
                  <div class="valid-check">{{ valid_message[day_num] ? valid_message[day_num][key] : ''}}</div>
                  <b-field grouped group-multiline> 
                    <div class="control">
                      <b-autocomplete
                        v-model="hours['opens']"
                        :data="filteredDataArray(hours['opens'])"
                        :keep-first="keepFirst"
                        :open-on-focus="openOnFocus"
                        placeholder="12:00"
                        maxlength=5
                        clearable
                        @select="option => selected = option"
                        @input="hours['opens']=opt(day_num, key, hours['opens'])">
                        <template slot="empty">No results found</template>
                      </b-autocomplete>
                    </div>
                    <div class="control">
                    〜
                    </div>
                    <b-field :type="errors.has('close' + key + day_num) ? 'is-danger': ''"
                      :message="errors.has('close' + key + day_num) ? '終了時間に00:00は入力できません' : ''">
                      <div class="control">
                        <b-autocomplete
                          :name="`close` + key + day_num"
                          v-validate.immediate="{ regex: /^(?!00:00$).+$/ }"
                          v-model="hours['closes']"
                          :data="filteredDataArray(hours['closes'])"
                          :keep-first="keepFirst"
                          :open-on-focus="openOnFocus"
                          placeholder="18:00"
                          maxlength=5
                          clearable
                          @select="option => selected = option"
                          @input="hours['closes']=opt(day_num, key, hours['closes'])"
                          >
                          <template slot="empty">No results found</template>
                        </b-autocomplete>
                      </div>
                      <div v-if="day_data['open_hours'].length >= 2" class="control">
                        <b-button type="is-danger" id="delButton" @click="remove(day_data['open_hours'], key)" >×</b-button>
                      </div>
                    </b-field>

                    <div v-if="day_data['open_hours'].length < 2" class="control">
                      <div>
                        <a type="is-primary" id="addButton" @click="apend(day_data)">時間帯を追加</a>
                      </div>
                    </div>

                  </b-field>
                </div>
              </div>
            </b-collapse>
          </b-field>
          <br>
        </div>
      </div>
    </section>
</template>

<script>
import Config from '../config.js'
import moment from 'moment'

export default {
  props: ['pagedata'],
  data () {
    return {
        timerange: [
            '00:00','00:30','01:00','01:30','02:00','02:30','03:00','03:30','04:00','04:30','05:00','05:30','06:00','06:30','07:00','07:30','08:00','08:30','09:00','09:30','10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00','18:30','19:00','19:30','20:00','20:30','21:00','21:30','22:00','22:30','23:00','23:30','24:00','24:30','25:00','25:30','26:00','26:30','27:00','27:30','28:00','28:30','29:00','29:30','30:00',
        ],
        name: '',
        keepFirst: true,
        openOnFocus: true,
        pattern:'^(([0-2][0-9]):([0-5][0-9])|30:00)$',
        valid_message:{},
        draft: {
          opening: {
            day0: {
              open_hours :[
                { opens:'', closes:''},
              ],
              open_day: false,
            },
            day1: {
              open_day: false,
              open_hours :[
                { opens:'', closes:''},
              ],
            },
            day2: {
              open_day: false,
              open_hours :[
                { opens:'', closes:''},
              ],
            },
            day3: {
              open_day: false,
              open_hours :[
                { opens:'', closes:''},
              ],
            },
            day4: {
              open_day: false,
              open_hours :[
                { opens:'', closes:''},
              ],
            },
            day5: {
              open_day: false,
              open_hours :[
                { opens:'', closes:''},
              ],
            },
            day6: {
              open_day: false,
              open_hours :[
                { opens:'', closes:''},
              ],
            },
            day7: {
              open_day: false,
              open_hours :[
                { opens:'', closes:''},
              ],
            }
          }
        }
      }
    },
  computed: {
    filteredDataArray: function() {
      return function(value) {
        // optionによってフィルター後のリストを返す
        return this.timerange.filter((option) => {
          // firestor上のtypeがnull対策
          value = value ? value : ''
          // 入力値を検索して該当するものoptionへ
          return option
                .toString()
                .toLowerCase()
                .indexOf(value.toLowerCase()) >= 0
        })
      }
    },
    page_id () {
      return this.$store.state.page_id
    },
    opening () {
      return this.objectSort(
        JSON.stringify(this.pagedata.opening) !== '{}'
        && JSON.stringify(this.pagedata.opening) !== '""'
        && JSON.stringify(this.pagedata.opening) !== undefined
        ? this.pagedata.opening
        : this.draft.opening
        )
    }
  },
  methods: {
    opt:function(day_num, key, val){

      let re = new RegExp(this.pattern)
      let result = re.exec(val)
      this.valid_message[day_num] = []

      if(val && val.length === 2) {
        val = val + ':'
      }

      if(!result) {
         //console.log('バリデーションNG')
         this.valid_message[day_num][key] = '00:00から30:00を半角数字で入力してください。'
         return val
       }
      //console.log('バリデーションOK')
      this.valid_message[day_num][key] = ''
      this.onBlur()
      return result[0]
    },

    apend: function(value) {
      value.open_hours.push({ opens:'', closes:''});
    },
    remove: function(value, key) {
      if(value.length > 1) {
        // console.log(value);
        value.splice(key, 1)
      }
      this.onBlur()
    },
    onBlur: function() {
      // 直接propsには入れられないので。
      let opening_data = this.opening ? this.opening : this.draft.opening
      this.autosave(opening_data)
    },
    // 自動保存
    autosave (d) {
      Config.COLLECTION
        .doc(this.page_id)
        .set({'opening': d, 'date': moment(moment().unix(),'X').format()}, {merge : true})
        .then(function(){
        })
    },
    objectSort(object) {
      //戻り値用新オブジェクト生成
      let sorted = {}
      //キーだけ格納し，ソートするための配列生成
      let keys = Object.keys(object)
      keys.sort()
      //キーが入った配列の長さ分だけfor文を実行
      for (let i = 0; i < keys.length; i++) {
          sorted[keys[i]] = object[keys[i]];
      }
      //戻り値にソート済みのオブジェクトを指定
      return sorted;
    },
  }
}
</script>

<style>
input[readonly='readonly']{
  background-color:#c0c0c0;
  color:#666;
}
.valid-check{
  color:red;
}

</style>
