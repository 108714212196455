<template>
  <section class="row form">
    <div class="has-text-centered">
      <div v-if="!notification" class="swal2-icon swal2-warning swal2-icon-show" style="display: flex;"><div class="swal2-icon-content">!</div></div>
      <h5 class="has-text-weight-semibold">
          ページの代理入力
      </h5>
      <p v-if="notification" class="is-size-6 has-text-weight-medium">
        下記ページで代理入力を依頼しますか？
      </p>
      <p v-else class="is-size-6 has-text-weight-medium">
        以下のページで代理入力を依頼しますか？
      </p>
      <br>
      <p>{{ pagedata['business_name'] }} {{ pagedata['branch_name']}}</p>
      https://jp.ilb.net/{{ pagedata['page_id'] }}
      <br><br>
      <div v-if="notification">
        <p class="pb=1">
          代理入力は、インターネット上で確認できる御社の公式サイト、SNS、ブログなどの情報を元に、当社で作業するので手間が一切かかりません。
        </p>
        <p class="help">
          管理画面で入力済みの情報についても変更を行う場合があります。
        </p>
      </div>
      <div v-else>
        <p v-if="pagedata['business_name']">事業所名：{{ pagedata['business_name'] }}</p>
        <p v-else>事業所名：<span class="has-text-danger">未入力</span></p>
        <p v-if="pagedata['phone']">電話番号：{{ pagedata['phone'] }}</p>
        <p v-else>電話番号：<span class="has-text-danger">未入力</span></p>
      </div>
      <br>
      <p v-if="notification" class="has-text-danger">代理入力はページごとに１回限り依頼が可能です。</p>
      <p v-else class="has-text-danger">代理入力に必要な項目が未入力のため依頼できません。管理画面から入力してください。</p>
      <br>
      <div class="col s12 place-order-button-block">
        <div v-if="notification">
          <b-button  v-if="success === false" class="btn col s12 #e91e63 pink" type="is-info" @click="agentRequest(true)">代理入力を依頼する（無料）</b-button>
          <b-loading :is-full-page="isFullPage" :active.sync="isLoading"></b-loading>
          <p class="mt-2">
            <a v-if="notification" href="#" @click="$emit('modalclose')">管理画面に戻る</a>
          </p>
        </div>
        <div v-else>
          <b-button class="btn col s12 #e91e63 pink" type="is-info" @click="moveRequiredTab()">項目を入力する</b-button>
          <p class="mt-2">
            <a href="#" @click="$emit('modalclose')">管理画面に戻る</a>
          </p>
        </div>
        <div v-if="notification">
          <hr>
          <p class="is-size-7">
            代理入力を希望しない場合は、<br>
          下記のボタンをクリックしてください。<br>
          今後、代理入力の案内メールも<br>
          配信されなくなります。<br>
          </p>
          <!--
          <a href="#" @click="agentRequest(false)">代理入力を依頼しない</a><br>
          -->
          <b-button class="btn col s12 #e91e63 pink mt-2" type="is-light" @click="agentRequest(false)">代理入力を依頼しない</b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Config from '../../config.js'
import moment from 'moment'


export default {
  props: ['pagedata'],
  computed: {
    // 代理入力依頼ができるかの分岐用
    notification () {
      if(this.pagedata
        && this.pagedata.business_name
        && this.pagedata.phone) {
        return true;
      }
      return false;
    }
  },
  data () {
    return {
      success:false,
      isLoading: false,
      isFullPage: true,
      requiredTabNo: 0,
    }
  },
  methods: {
    /**
     *
     */
    // url形式かチェック
    urlFormatCheck(str) {
      if (!str) {
        return false;
      }
      return str.match(/https?:\/\/[-_.!~*()a-zA-Z0-9;/?:&=+$,%#]+/);
    },
    /**
     * 代理入力依頼（true || false）
     */
    async agentRequest (val) {
      this.modalClose()
      this.isLoading = true

      try {
        await Config.SALES_COLLECTION
          .doc(this.pagedata["page_id"])
          .set(
            {
              date : moment(moment().unix(),'X').format(),
              page_revision : val,
              uid: this.$store.state.user.uid,
              business_name: this.pagedata['business_name'],
              branch_name: this.pagedata['branch_name'] || null,
              email: this.pagedata['email'] || this.$store.state.user.email,
              phone: this.pagedata['phone'],
              phone_contact: this.pagedata['phone_contact'] || null,
            }, { merge: true }
          )
      } catch(e) {
        this.$swal('error', '代理入力依頼のエラーです：' + e, 'error')
        return
      }

      if (val) {
        // スプレッドシートに入力
        // // メール送信処理（sendMailTarget）:管理者へ：falseだったら送らない
        // let data = {
        //   template_id : 'AGENT_REQUEST_CREATE_PAGE',
        //   uid: this.$store.state.user.uid,
        //   page_id: this.pagedata["page_id"],
        //   business_name: this.pagedata['business_name'],
        //   branch_name: this.pagedata['branch_name'] || null,
        //   phone: this.pagedata['phone'],
        //   application_route: '管理画面',
        //   status: 'sending',
        //   date: moment().format("YYYY-MM-DD HH:MM:SS"),
        //   email: this.pagedata['email'] || this.$store.state.user.email,
        //   apply:val, // template適用・不適用フラグ
        // }
        // await Config.SENDMAIL_TARGET_COLLECTION.add(data);
      }

      this.isLoading = await false

      // 確認用のswal
      const swalParam = this.getSwalParam(val)
      let result = await this.$swal(swalParam);
      if (result.isConfirmed) {
        await this.$emit('modalclose')
      }
    },
    /**
     * swal表示内容
     * @param boolean operation
     */
    getSwalParam(operation) {
      let swalParam
      switch (operation) {
        case true:
          swalParam = {
            title : '代理入力の依頼完了',
            html : '代理入力が完了し次第、メールにてご報告します。',
            icon : 'success',
            confirmButtonText : '管理画面に戻る'
          }
          break;
        case false:
          swalParam = {
            title : '代理入力不要の受付完了',
            html : '今後、代理入力の案内メールも配信停止されます。',
            icon : 'success',
            confirmButtonText : '管理画面に戻る'
          }
          break;
      }
      return swalParam
    },
    /**
     * モーダル閉じる（親要素へemit）
     */
    modalClose() {
      this.$emit('modalclose')
    },
    /**
     * 必須入力タブへ移動
     */
    moveRequiredTab () {
      this.$router.push('/?active_tab=' + this.requiredTabNo).catch(() => {})
      this.$emit('modalclose')
    }
  },
}
</script>

<style scoped>
.service-title{
  margin-bottom:6px;
}
.form {
    max-width: 400px;
    margin: 20px auto;
    border: 2px solid #ececec;
    padding: 12px;
    margin-bottom: 8px;
}
.form h5 {
    margin: 5px;
    padding: 0px;
    text-align:center;
    font-size: 1.2rem;
}
.form h6 {
    margin-bottom: 5px;
    padding: 0px;
    text-align:center;
    font-size: 1.5rem;
}

.place-order-button-block {
    margin: 5px 0;
    text-align:center;
}

button {
  background-color: #23D160;
  color: white;
  border-radius: 4px;
  font-size: 20px;
  width:100%;
  /* font-weight: 600; */
  border: 0;
  box-shadow: none;
  padding: 8px 24px;
}
.redColor{
  color:red;
  font-weight: bold;
}
</style>
