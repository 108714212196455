var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[_c('Menu',{on:{"getdoc":_vm.getPageId}})],1),_c('div',{staticClass:"column"},[_c('h1',{staticClass:"is-size-4 has-text-weight-bold"},[_vm._v("問合せ内容の一覧")]),_c('p',{staticClass:"mb-3"},[_vm._v("ページのメールフォームから送信された問合せ内容の一覧です。問合せ日時での並べ替えや問合せ内容などでの検索ができます。")]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
          enabled: true,
          skipDiacritics: true,
          placeholder: '名前、電話番号、メールアドレス、お問合せ内容で検索ができます',
        },"pagination-options":{
          enabled: true,
          setCurrentPage: 1,
          mode: 'records',
          perPage: 20,
          dropdownAllowAll: false,
          jumpFirstOrLast : true,
          firstLabel : '最初のページ',
          lastLabel : '最後のページ',
          nextLabel: '次へ',
          prevLabel: '前へ',
          rowsPerPageLabel: '1ページあたりの行数',
          ofLabel: 'of',
          pageLabel: 'page', // for 'pages' mode
          allLabel: 'All',
        },"sort-options":{
          enabled: true,
          initialSortBy: {field: 'send_time', type: 'desc'},
        },"compactMode":""}},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" データがありません。 ")])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }