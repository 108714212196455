import firebase from '@/firebase/firebaseInit'
import Util from './util'

export default class GaReport {
  static getReportData(collection, targetDate) {
    let reportData = []

    // 日ごと（当月分）------------------------------------------------
    reportData.push(firebase.firestore().collection(collection + '/ga/daily/rows')
            .where('date', '>=', Util.dateFormatter(targetDate, 'YYYY-MM-01'))
            .where('date', '<=', Util.dateFormatter(new Date(targetDate.getFullYear(), targetDate.getMonth() + 1, 0), 'YYYY-MM-DD'))
            .orderBy('date').get())

    // 統計データを取得（チャネルごとのデータ）-----------------------------
    reportData.push(firebase.firestore().collection(collection + '/ga/channel/total')
            .doc(Util.dateFormatter(targetDate, 'YYYY-MM')).get())

    return reportData
  }

  static createReportData(dailyData, channelData, targetDate) {
    let reportData = {}

    let daily = []

    let totalSessions = 0
    let totalUsers = 0
    let totalGoal1Completions = 0
    let totalGoal2Completions = 0
    let totalGoal3Completions = 0
    let totalGoal4Completions = 0
    let totalGoalCompletionsAll = 0

    let isDailyData = false
    dailyData.forEach(doc => {
      if (parseInt(doc.data()['sessions']) > 0 || parseInt(doc.data()['goalCompletionsAll']) > 0) {
        isDailyData = true
      }
    })

    if (isDailyData) {
      let lastDate = new Date(targetDate.getFullYear(), targetDate.getMonth() + 1, 0)
      for (let i = 1; i <= lastDate.getDate(); i++) {
        let docDate = Util.dateFormatter(targetDate, 'YYYY-MM-' + ('0' + i).slice(-2))
        let docData = {}

        dailyData.forEach(doc => {
          if (doc.data()['date'] == docDate) {
            docData = doc.data();
          }
        })

        // 日ごと（当月分）------------------------------------------------
        let data
        if (Object.keys(docData).length) {
          data = {
            'date': Util.dateFormatter(new Date(docDate), 'MM/DD(DAY)'),
            'sessions': parseInt(docData['sessions']),
            'users': parseInt(docData['users']),
            'sessions_organic': docData['sessions_organic'] ? parseInt(docData['sessions_organic']) : 0,
            'sessions_display': docData['sessions_display'] ? parseInt(docData['sessions_display']) : 0,
            'sessions_paid_search': docData['sessions_paid_search'] ? parseInt(docData['sessions_paid_search']) : 0,
            'sessions_gdp': docData['sessions_gdp'] ? parseInt(docData['sessions_gdp']) : 0,
            'sessions_other_advertising': docData['sessions_other_advertising'] ? parseInt(docData['sessions_other_advertising']) : 0,
            'sessions_affiliate': docData['sessions_affiliate'] ? parseInt(docData['sessions_affiliate']) : 0,
            'sessions_referral': docData['sessions_referral'] ? parseInt(docData['sessions_referral']) : 0,
            'sessions_social': docData['sessions_social'] ? parseInt(docData['sessions_social']) : 0,
            'sessions_direct': docData['sessions_direct'] ? parseInt(docData['sessions_direct']) : 0,
            'sessions_email': docData['sessions_email'] ? parseInt(docData['sessions_email']) : 0,
            'sessions_null': docData['sessions_null'] ? parseInt(docData['sessions_null']) : 0,
            'other': docData['other'] ? parseInt(docData['other']) : 0,
            'goal1Completions': docData['goal1Completions'],
            'goal1CompletionsGs': docData['goal1CompletionsGs'],
            'goal1CompletionsGd': docData['goal1CompletionsGd'],
            'goal2Completions': docData['goal2Completions'],
            'goal2CompletionsGs': docData['goal2CompletionsGs'],
            'goal2CompletionsGd': docData['goal2CompletionsGd'],
            'goal3Completions': docData['goal3Completions'],
            'goal3CompletionsGs': docData['goal3CompletionsGs'],
            'goal3CompletionsGd': docData['goal3CompletionsGd'],
            'goal4Completions': docData['goal4Completions'],
            'goal4CompletionsGs': docData['goal4CompletionsGs'],
            'goal4CompletionsGd': docData['goal4CompletionsGd'],
            'goalCompletionsAll': docData['goalCompletionsAll'],
          }
        } else {
          data = {
            'date': Util.dateFormatter(new Date(docDate), 'MM/DD(DAY)'),
            'sessions': 0,
            'users': 0,
            'sessions_organic': 0,
            'sessions_display': 0,
            'sessions_paid_search': 0,
            'sessions_gdp': 0,
            'sessions_other_advertising': 0,
            'sessions_affiliate': 0,
            'sessions_referral': 0,
            'sessions_social': 0,
            'sessions_direct': 0,
            'sessions_email': 0,
            'sessions_null': 0,
            'other': 0,
            'goal1Completions': 0,
            'goal1CompletionsGs': 0,
            'goal1CompletionsGd': 0,
            'goal2Completions': 0,
            'goal2CompletionsGs': 0,
            'goal2CompletionsGd': 0,
            'goal3Completions': 0,
            'goal3CompletionsGs': 0,
            'goal3CompletionsGd': 0,
            'goal4Completions': 0,
            'goal4CompletionsGs': 0,
            'goal4CompletionsGd': 0,
            'goalCompletionsAll': 0,
          }
        }
        daily.push(data)
        
        // 合計（当月分）--------------------------------------------------
        totalSessions += data['sessions']
        totalUsers += data['users']
        totalGoal1Completions += data['goal1Completions']
        totalGoal2Completions += data['goal2Completions']
        totalGoal3Completions += data['goal3Completions']
        totalGoal4Completions += data['goal4Completions']
        totalGoalCompletionsAll += data['goalCompletionsAll']
      }
    }
    reportData['daily'] = daily

    reportData['total'] = {}
    if (daily.length) {
      reportData['total'] = {
        'date': 'Total',
        'sessions': totalSessions,
        'users': totalUsers,
        'goal1Completions': totalGoal1Completions,
        'goal2Completions': totalGoal2Completions,
        'goal3Completions': totalGoal3Completions,
        'goal4Completions': totalGoal4Completions,
        'goalCompletionsAll': totalGoalCompletionsAll,
      }
    }

    // 統計データを取得（チャネルごとのデータ）
    reportData['channel'] = {}
    if (channelData.exists) {
      reportData['channel'] = {
        'organic_search': parseInt(channelData.data()['organic_search']),                                           // 自然検索数
        'paid_advertising': parseInt(channelData.data()['display']) + parseInt(channelData.data()['paid_search']),  // 有料広告
        'gdp': channelData.data()['gdp'] ? parseInt(channelData.data()['gdp']) : 0,                                 // 有料プランアクセス保証広告（GDP）
        'from_another_site': parseInt(channelData.data()['referral']) + parseInt(channelData.data()['social']),     // 別サイトからの流入
        'direct_or_other': parseInt(channelData.data()['direct_or_other'])                                          // メール・チラシ等
      }
    }

    return reportData
  }
}
